import { store } from "../app/store";

//DO NOT USE THIS TO DETERMINE WHETHER WEBSCHEDULER IS CURRENTLY EMBEDDED IN ANOTHER SITE. For that, use isInjected() in useIsInjected.ts.
//The "mode=embed" query parameter is intended ONLY to hide the logo, NOT to determine whether this is an embedded widget.
//isEmbedMode() will return FALSE EVEN WHEN EMBEDDED, if the cover has chosen to show the logo in the embedded widget.

const isModeOneOfThese = (...modes: string[]): boolean => {
  const lowercaseModes = modes.map(mode => mode.toLowerCase());
  const state = store.getState();
  let isMinimal: boolean = false;
  const mode = state.session.Mode?.toLowerCase();
  if(mode !== undefined && lowercaseModes.includes(mode)) {
    isMinimal = true;
  }
  return isMinimal;
}

export const isMinimalOrEmbed = (): boolean => {
  return isModeOneOfThese('minimal', 'embed');
}

export const isMinimalMode = (): boolean => {
  return isModeOneOfThese('minimal');
}

export const isEmbedMode = (): boolean => {
  return isModeOneOfThese('embed');
}

//this does not really need to be a React hook, a refactor could delete this method and move this file out of the hooks/ folder
const useIsMinimalOrEmbed = (): boolean => {
  return isMinimalOrEmbed();
}

export default useIsMinimalOrEmbed;

