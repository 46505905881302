// @mui
import { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  Button,
} from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useAppSelector } from "../../../app/hooks";
import { Event } from '../../../utils/GA'
// utils
import useStateNavigation from "../../../components/hooks/useStateNavigation";
import useStateLocation from "../../../components/hooks/useStateLocation";
import { stateRoutes } from "../../../models/Page";
import { isInjectedNonIframe } from "../../../hooks/useIsInjected";
import useIsMinimalOrEmbed, { isMinimalOrEmbed } from "../../../hooks/useIsMinimalOrEmbed";
import { mainHeaderRef } from "../MainLayout";

// ----------------------------------------------------------------------

const NAV_WIDTH = -1;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => {
  const injectedNonIframe = isInjectedNonIframe();
  const minimal = isMinimalOrEmbed();
  const style: any = {
    //   ...bgBlur({ color: theme.palette.background.default }),
    //backgroundColor: alpha(theme.palette.primary.main, 0.8),
    //2065D1
    backgroundColor: theme.palette.primary.contrastText,
    color: "#000",
    borderBottom: !minimal ? "10px solid  var(--fpc-blue)":'none',
    boxShadow: "none",
    backdropFilter: `blur(${6}px)`,
    WebkitBackdropFilter: `blur(${6}px)`,
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - ${NAV_WIDTH + 1}px)`,
    },
    position: injectedNonIframe ? "absolute" : "fixed"
  };
  if(injectedNonIframe) {
    style.left = "0";
    style.right = "auto";
    style[theme.breakpoints.up("lg")] = "calc(100% - 15px)";
    style.width = "calc(100% - 15px)";
  }
  return style;
});

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

export default function Header() {
  const navigate = useStateNavigation()
  const [locationName, setLocationName] = useState("");
  const [coverDesktopLogo, setCoverDesktopLogo] = useState<any>("");
  const [coverMobileLogo, setCoverMobileLogo] = useState<any>("");
  const location = useStateLocation();
  const state = useAppSelector(stat => stat)
  const [timeDate, setSetTimeDate] = useState<any>(new Date().getTime());
  const minimal = useIsMinimalOrEmbed();

/*   useEffect(() => {

    if (state.session.LocKey && state.session.DesktopLogo !== "")
    {
        var d = new Date()
        if (state.session.OrgName)
          setLocationName(state.session.OrgName)
        
        var logo = state.session.DesktopLogo !== "" && state.session.DesktopLogo ? state.session.DesktopLogo + "?ver=" + d.getTime() : ""

        setCoverDesktopLogo(logo)
        setCoverMobileLogo(state.session.MobileLogo)
    }
  }, [state.session]);  */


  const handleClickBack = () => {
  
  //GA-18
  Event(
    false,
    'click_back_header',
    state.session.CoverKey?.toString() ,state.session.LocKey?.toString(), state.session.AcctKey?.toString()
    ) 
    navigate(-1)  
  }

  return (
    <>
    {!minimal?
    (
    <StyledRoot ref={mainHeaderRef}>
      <StyledToolbar >
        {/* <IconButton>
          <NavigateBeforeIcon />
        </IconButton> */}
        {(location === stateRoutes.appointmentWindow || location === stateRoutes.selectAppointmentTime || 
          location === stateRoutes.patientDemographics || location === stateRoutes.visionInsurance || 
          location === stateRoutes.multipleAppointment || location === stateRoutes.appointmentVisitDetails || 
          location === stateRoutes.customQuestion) &&
        <Button
          variant="text"
          color="inherit"
          sx={{ textTransform: "none", display: "flex" }}
          startIcon={<NavigateBeforeIcon />}
          disableRipple
          onClick={()=> navigate(-1)}
        >
          Back
        </Button>
        }       
        {/* <Searchbar />*/}

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
          sx={{
            justifyContent: "right",
            marginLeft: "0.5rem",
            width: "100%",
          }}
        >
          {(state.session.DesktopLogo !== "" && state.session.DesktopLogo) && <Box
            component="img"
            sx={{
              height : "auto",
              width : "100%",
              maxHeight: 80,
              maxWidth: "280px !important",
              objectFit : "contain",
              objectPosition : "right"
            }} 
            alt={locationName}
            src={state.session.DesktopLogo !== "" && state.session.DesktopLogo ? state.session.DesktopLogo + "?ver=" + timeDate : ""}
          />}          
        </Stack>
      </StyledToolbar>
    </StyledRoot>
    ) : (
    <>
      {
        (location === "appointmentWindow" || location === "selectAppointmentTime" || 
         location === "patientDemographics" || location === "visionInsurance" ||
         location === "multipleAppointment" || location === "appointmentVisitDetails" ||
         location === "customQuestion") &&
          <Button
            id="button-back-header"
            className="button-back-header"
            variant="text"
            color="inherit"
            sx={{ textTransform: "none", display: "flex", justifyContent: "flex-start" }}
            startIcon={<NavigateBeforeIcon />}
            disableRipple
            onClick={handleClickBack}
          >
          Back
        </Button>
      }   
    </>
      )
    }
    </>
  );
}
function indexOf(arg0: string): number | undefined {
  throw new Error("Function not implemented.");
}

