import { useEffect,useContext, useState } from "react";
import { useForm } from 'react-hook-form';
import { Box, Typography, Grid, Button } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import './style.css';
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AppContext, AppContextType } from "../../App";
import { updatePatient } from '../../features/patient/patientSlice';
import  PatientService  from '../../services/PatientService';
import HashReturnObject from '../../models/HashReturnObject';
import Patient from '../../models/Patient';
import { setSessionState, updateCurrentPage } from "../../features/session/sessionSlice";
import { getBaseAPI } from "../../environments/Environments";
import Spinner from "../spinner/Spinner";
import "./SelectAppointmentTime";
import { NavigatePage, PageName, getFormattedDateTime } from "../../utils/Util";
import useApptConfirmNav from "../hooks/useApptConfirmNav";
import { Event, PageView } from '../../utils/GA';
import useStateNavigation from "../hooks/useStateNavigation";
import { stateRoutes } from "../../models/Page";
import { getDateTimeObj } from "../../utils/Util";
import { ValidationErrors, validationSchema } from "../../utils/ValidationUtil";
import { saveSession } from "../../services/SessionService";
// import { ValidationErrors, validationSchema } from "./SelectAppointmentTimeValidationSchema";

const SelectAppointmentTime = ({ }): JSX.Element => {

    const navigate = useStateNavigation();
    const [open, setOpen] = useState(false);
    const [coverExist, setCoverExist] = useState(false);
    const { formData, setFormData } = useContext(AppContext) as AppContextType
    const [locationName, setLocationName] = useState("");
    const [pageLoading, setPageLoading] = useState(false);
    const[providerAgnostic,setProviderAgnostic] = useState<boolean>(false);
    const state = useAppSelector(state => state);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    //If appointment confirmation is complete, force user back to the confirmation screen
    useApptConfirmNav();

    const {
        register,
        formState: { isDirty, errors },
        formState,
        handleSubmit,
        trigger,
        watch,
    } = useForm<any>({
        // resolver: yupResolver(validationSchema(validationErrors)),
        resolver: yupResolver(validationSchema(validationErrors)),
        mode: "onChange",
    });

    const watchDateOfBirthFields = watch(["month", "day", "year"]);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (state.availability.getInitialLocation)
            setLocationName(state.availability.getInitialLocation.Name) // + "-" + state.availability.getInitialLocation.Address)
        }, [state.availability.getInitialLocation]); 

    useEffect(() => {
        isDirty && trigger(['pNumber']); // include field name for new validation errors
    }, [validationErrors]);
    
    const getData = async () => {
        const url = await getBaseAPI() + '/api/v1/Patient/showPatientInfos';
        // const url = 'https://localhost:5001/api/v1/Patient/showPatientInfos';
        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                const newCovers = data.map((cover: any) => {
                    return cover.covers
                });
                const coverKey = state.session.CoverKey ? state.session.CoverKey.toString() : "";
                setCoverExist(newCovers.includes(coverKey));
               // dispatch(setSessionState({...state.session,CurrentPage :  PageName.SelectAppointmentTime}))
            });
    }
    useEffect(() => {
        dispatch(updateCurrentPage({CurrentPage :  PageName.SelectAppointmentTime}))  
        PageView(
            `pageview_patient_info_screen`, state.session.CoverKey?.toString() ,state.session.LocKey?.toString(), state.session.AcctKey?.toString()
        )        
        getData()
        // const provider = state.providers.providers.find((x) => x.AcctKey == state.availability?.availability.AcctKey);
        // DemographicsService.getDemographics({ ...state, session: { ...state.session, AcctKey: provider?.AcctKey } })
        //     .then((res) => {
        //         if(res.data[0].Config.ProviderAgnostic ===1)
        //         {
        //              setProviderAgnostic(true);
        //         }
        //         else{
        //             setProviderAgnostic(false);
        //         }
        //     });
    }, []);

    useEffect(() => { 
            formData.pNumber = state.patient.Home ?? ''
            formData.email = state.patient.Email ?? ''
    }, [])

    useEffect(() => {
        if ( formData.year)
        {
        let strDate = formData.month + formData.day + formData.year;
        let DOB = new Date(strDate.replace(/(\d\d)(\d\d)(\d\d\d\d)/, "$3-$1-$2"));
        DOB.setTime( DOB.getTime() + DOB.getTimezoneOffset()*60*1000 );
        let _DOB = getDateTimeObj(DOB).dateTime;

        const newVal = { ...state.patient, FirstName: formData.firstName, LastName: formData.lastName, Home: formData.pNumber, Email: formData.email, DOB: _DOB }
        dispatch(updatePatient(newVal));
        }
    }, [formData.firstName, formData.lastName, formData.pNumber, formData.email, formData.day, formData.month, formData.year])

    useEffect(() => {
        register("dateOfBirth");
    }, [register]);

    useEffect(() => {
        trigger("dateOfBirth");
    }, watchDateOfBirthFields);

    const onSubmit = (_data: any) => {
        setPageLoading(true)
        //GA-06
        Event(
            false,
            'click_continue_patient_info_screen',
            state.session.CoverKey?.toString() ,state.session.LocKey?.toString(), state.session.AcctKey?.toString()
        )

        const provider = state.providers.providers.find((x) => x.AcctKey == state.availability?.availability.AcctKey);
        PatientService.getPatient(state.patient,{ ...state.session, AcctKey: provider?.AcctKey })
            .then((response: HashReturnObject<Patient>) => {
                const updateSession = { ...state.session, AddKey: response.data[0].AddKey?.toString(), EmailAddress : formData.email }
                saveSession(updateSession).then(() => {
                    dispatch(setSessionState(updateSession))
                    const stateData = {...state}
                    stateData.session = {...updateSession}
                     NavigatePage(dispatch,"next", stateData, navigate, stateRoutes.patientDemographics)    
                    // setPageLoading(false)                
                }).catch((e) => {
                    console.error(e);
                    setPageLoading(false);
                    setOpen(true);
                });
               
            })
            .catch((e:any) => {
                console.error(e);
                setPageLoading(false);
                const hasValidationError = e?.some((error: any) =>
                    Object.values(ValidationErrors).includes(error)
                );

                if (hasValidationError) {
                    setValidationErrors(e);
                    return;
                }

                setOpen(true);
            });
    }

    const selectTime = state.availability.availability
    const dDate = new Date(selectTime.AppointmentDate).toDateString()
    const dTime = (selectTime.AppointmentDateTime)
    const TimeConvert =(date: any) => { 
        let hours = date?.getHours();
        let minutes = date?.getMinutes();
        let ap = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes?.toString().padStart(2, '0')
        let mergeTime = hours + ':' + minutes + ' ' + ap;
        return mergeTime;
    }
    TimeConvert(dTime)

    const providerName = state.appointmentData.appointmentType.Name
    const providerFirstname = state.providers.provider.FirstName
    const ProviderLastName = state.providers.provider.LastName
    const providerDegree = state.providers.provider.Degree

    const clearValidationError = (key: ValidationErrors) =>
        setValidationErrors(validationErrors => validationErrors.filter(error => error !== key));

    const filterNonNumeric = (e: any) => {
        let onlyDigits = e.target.value.replace(/\D/g, "");
        if(e.target.value.toString().startsWith("1") || e.target.value.toString().startsWith("0")){
            onlyDigits = "";
         
        }
        setFormData({ ...formData, [e.target.name]: onlyDigits });
        clearValidationError(ValidationErrors.PhoneNumber);
    };

    return <div className="p-2">
        <Box
            display={"flex"}
            alignItems={"center"}
            className="wsui-header-partical"
        >
            <Box flexGrow={1}>
                <Typography variant="h6"
                    fontWeight={"500"}
                    sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                    }} >
                    {locationName}
                </Typography>
            </Box>
            <Box sx={{}}>
                <Typography
                    variant="h6"
                    fontWeight={"500"}
                    sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                    }}
                >
                    <div>{getFormattedDateTime(dTime)}</div>
                </Typography>
            </Box>
        </Box>
        <Box borderBottom={"0.5px solid #979797 !important"} className="wsui-box-p heading-box">
        <div className="Typogrphy-pb title-name"><b>{providerName}</b>  {state.embed.ProviderAgnostic === 0 ? <span className="patient-name-title">  with <b>{providerFirstname} {ProviderLastName},{providerDegree}</b></span> : null} </div>  
        </Box>
        <form className="form-div" onSubmit={handleSubmit(onSubmit)}>
            <Box borderBottom={"0.5px solid #979797 !important"} className="wsui-box-p patient-details-wrapper">
                <Typography className="Typogrphy-p">
                    <Typography
                        variant="h6"
                        fontWeight={"500"}
                        sx={{
                            fontSize: "20px",
                            fontWeight: "600",
                            lineHeight: "27.24px",
                            margin: "15px 0px",
                        }}
                    >
                        Please enter the patient's information below:
                    </Typography>
                    {
                        coverExist ? <Typography sx={{ marginBottom: "15px" }}>If you're attempting to book for another person, please add their (not your) information below, to ensure the correct person is scheduled:</Typography> : null
                    }
                    <div className="wsui-form-control">
                        <label className="wsui-field-label">First Name*</label>
                        <div className="wsui-error">
                            <> {errors.firstName?.message}  </>
                            <input
                                id="input-firstname-patient-info"
                                className="input-firstname-patient-info"
                                {...register("firstName", {
                                    onChange: (e) =>
                                        setFormData({
                                            ...formData,
                                            [e.target.name]: e.target.value,
                                        }),
                                })}
                                value={formData.firstName}
                                name="firstName"
                                type="text"
                                placeholder="First Name"
                                maxLength={25}
                            />
                        </div>
                    </div>
                    <div className="wsui-form-control">
                        <label className="wsui-field-label">Last Name*</label>
                        <div className="wsui-error">
                            <>{errors?.lastName?.message}</>
                            <input
                                id="input-lastname-patient-info"
                                className="input-lastname-patient-info"
                                {...register("lastName", {
                                    onChange: (e) =>
                                        setFormData({
                                            ...formData,
                                            [e.target.name]: e.target.value,
                                        }),
                                })}
                                name="lastName"
                                value={formData.lastName}
                                type="text"
                                placeholder="Last Name"
                                maxLength={25}
                            />
                        </div>
                    </div>
                    <div className="wsui-form-control">
                        <label className="wsui-field-label">Email Address*</label>
                        <div className="wsui-error">
                            <>{errors?.email?.message}</>
                            <input
                                id="input-email-patient-info"
                                className="input-email-patient-info"
                                {...register("email", {
                                    onChange: (e) =>
                                        setFormData({
                                            ...formData,
                                            [e.target.name]: e.target.value,
                                        }),
                                })}
                                value={formData.email}
                                name="email"
                                type="text"
                                placeholder="Email Adress"
                                maxLength={100}
                            />
                        </div>
                    </div>
                    <div className="wsui-form-control">
                        <label className="wsui-field-label">Phone Number*</label>
                        <div className="wsui-error">
                            <>{errors?.pNumber?.message}</>
                            <input
                                id="input-phonenum-patient-info"
                                className="input-phonenum-patient-info"
                                {...register("pNumber", {
                                    onChange: (e) => filterNonNumeric(e),
                                })}
                                value={formData.pNumber}
                                name="pNumber"
                                type="text"
                                placeholder="Phone Number"
                                maxLength={10}
                            />
                        </div>
                    </div>
                    <div className="wsui-form-control wsui-date-control">    
                    <div className="wsui-field-label date-label">
                        <label className="wsui-field-label">
                            Date Of Birth*
                        </label>
                        {!errors?.month &&
                            !errors?.day &&
                            !errors?.year &&
                            !!errors?.dateOfBirth && (
                                <span className="wsui-errorMessage">
                                    {errors.dateOfBirth.message?.toString()}
                                </span>
                            )}
                    </div>            
                    
                    <div className="wsui-dob wsui-birth-date-dob">
                        <div className="wsui-field">
                            <input
                                id="input-dobmonth-patient-info"
                                className="input-dobmonth-patient-info"
                                {...register("month", {
                                    onChange: (e) =>
                                        setFormData({
                                            ...formData,
                                            [e.target.name]:
                                                e.target.value,
                                        }),
                                })}
                                value={formData.month}
                                name="month"
                                type="text"
                                placeholder="MM"
                                maxLength={2}
                            />
                            {!!errors?.month && (
                                <span className="wsui-errorMessage">
                                    {errors.month.message?.toString()}
                                </span>
                            )}
                        </div>
                        <div className="wsui-field">
                            <input
                                id="input-dobday-patient-info"
                                className="input-dobday-patient-info"
                                {...register("day", {
                                    onChange: (e) =>
                                        setFormData({
                                            ...formData,
                                            [e.target.name]:
                                                e.target.value,
                                        }),
                                })}
                                value={formData.day}
                                name="day"
                                type="text"
                                placeholder="DD"
                                maxLength={2}
                            />
                            {!!errors?.day && (
                                <span className="wsui-errorMessage">
                                    {errors.day.message?.toString()}
                                </span>
                            )}
                        </div>
                        <div className="wsui-field">
                            <input
                                id="input-dobyear-patient-info"
                                className="input-dobyear-patient-info"
                                {...register("year", {
                                    onChange: (e) =>
                                        setFormData({
                                            ...formData,
                                            [e.target.name]:
                                                e.target.value,
                                        }),
                                })}
                                value={formData.year}
                                name="year"
                                type="text"
                                placeholder="YYYY"
                                maxLength={4}
                            />
                            {!!errors?.year && (
                                <span className="wsui-errorMessage">
                                    {errors.year.message?.toString()}
                                </span>
                            )}
                        </div>
                        </div>
                    </div>
                </Typography>
            </Box>
            <Box>
                <Grid className="wsui-text-continue">
                    <span>We always ensure <b> your data is secure</b> and <b>never share </b> your personal information with any third parties</span>
                    <Button
                        id="button-continue-patient-info"
                        className="button-continue-patient-info"
                        variant="contained"
                        style={{
                            backgroundColor: "var(--fpc-blue)",
                            color: `${state.embed.fontColor}`,
                        }}
                        disabled={pageLoading}
                        type="submit"
                    >
                        Continue
                    </Button>
                </Grid>
            </Box>
        </form>
        <Snackbar
           anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            autoHideDuration={4000}
            onClose={() => setOpen(!open)}
            message="Application encountered an error. Please try again."
         />        
        {pageLoading === true &&
      <>
          <div className="wsui-spinner">
              <Spinner />
          </div>

      </>}        
    </div>
}

export { SelectAppointmentTime };

