import { useEffect, useState } from "react";
import { Box, Button, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import "./AppointmentVisitDetails.css";
import ConfirmationService from '../../services/ConfirmationService';
import MultipleAppointmentSelected from "../../models/MultipleAppointmentSelected";
import dayjs from 'dayjs';
import Spinner from '../../components/spinner/Spinner';
import { NavigatePage, getFormattedDateTime } from "../../utils/Util";
import useApptConfirmNav from "../hooks/useApptConfirmNav";
import { Event , PageView} from '../../utils/GA';
import useStateNavigation from "../hooks/useStateNavigation";
import { stateRoutes } from "../../models/Page";
import { AppointmentOneOnlyModal } from "./AppointmentOneOnlyModal";
import { AppointmentReviewPanel } from "./AppointmentReviewPanel";

//This is the currently used Appointment Visit Details screen
function AppointmentVisitDetails() {
    const Navigate = useStateNavigation()
    const dispatch = useAppDispatch();
    const state = useAppSelector(state => state)
    const [disableButton, setDisableButton] = useState(false);
    const [locationName, setLocationName] = useState("");
    const [patientName, setPatientName] = useState("");
    const [patientEmail, setPatientEmail] = useState("");

    const selectTime = state.availability.availability
    const dDate = new Date(selectTime.AppointmentDate).toDateString()
    const dTime = (selectTime.AppointmentDateTime)
    const TimeConvert =(date: any) => { 
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ap = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes.toString().padStart(2, '0')
        let mergeTime = hours + ':' + minutes + ' ' + ap;
        return mergeTime;
    }
    TimeConvert(dTime)

    const appointmentType = state.appointmentData.appointmentType.Name
    const providerFirstname = state.providers.provider.FirstName
    const ProviderLastName = state.providers.provider.LastName
    const providerDegree = state.providers.provider.Degree
    const TaskKey = state.availability.availability.TaskKey;
    const AcctKey = state.availability.availability.AcctKey;
    const LocKey = state.availability.availability.LocKey;
    const LogKey = state.session.LogKey;
    const limitOneAppt = state.embed.LimitOneAppt
    const _session = state.session;
    const [multipleSelectedAppointments,setMultipleSelectedAppointments] = useState<MultipleAppointmentSelected[]>(state.multipleAppointmentSelected.multipleAppointmentSelected);
    const [loading, setLoading] = useState(false);
    const handleClickBack = ()=>{
            //GA-15
            Event(
                false,
                'click_back_appointment_visit_details_screen',
                state.session.CoverKey?.toString() ,state.session.LocKey?.toString(), state.session.AcctKey?.toString()
            )
        Navigate(-1)
    }

    const setApptConfirmed = useApptConfirmNav();
    const confirmAppointment = () => {
        setDisableButton(true)
        setLoading(true)

          //GA-12
        Event(
            false,
            'click_confirm_appointment_visit_details_screen',
            state.session.CoverKey?.toString() ,state.session.LocKey?.toString(), state.session.AcctKey?.toString()
        )
    
        if ( multipleSelectedAppointments.length > 0)
        {
            ConfirmationService.cancelAppointment(LogKey, multipleSelectedAppointments)
            .then((response) => {
                if(response.sessionId){
                }
            });
        }

        ConfirmationService.confirmAppointment({
            ..._session,
             TaskKey:  TaskKey,
             AcctKey: AcctKey,
             LocKey: LocKey
        }).then((response: any) => {
            if (Boolean(response.data[0].isStatus) !== true) {
                // alert("Failed appointment confirmation");  
                  //GA-13
            	Event(
                    false,
                    'booking_confirmation_failure_already_exist',
                    state.session.CoverKey?.toString() ,state.session.LocKey?.toString(), state.session.AcctKey?.toString()
                )
                NavigatePage(dispatch,"next",state,Navigate,stateRoutes.appointmentNotAvailable)
                //const url =  getProperURL('/AppointmentNotAvailable', state.embed)
                //Navigate(url)              
                //Navigate('/AppointmentNotAvailable')
            } else {
                  //GA-14
            	Event(
                    false,
                    'booking_confirmation_success',
                    state.session.CoverKey?.toString() ,state.session.LocKey?.toString(), state.session.AcctKey?.toString()
                )
                setApptConfirmed(); //This will automatically navigate to the appointment confirmation screen
            }
        }).catch((e) => {
            Event(
                false,
                'booking_confirmation_failure_error',
                state.session.CoverKey?.toString() ,state.session.LocKey?.toString(), state.session.AcctKey?.toString()
            )
            //setShow('error');
            // console.error(e);
            // dispatch(AvailabilityActions.mapAvailabilityData(null));
        });
    }

    useEffect(() => {
    if (state.availability.getInitialLocation)
        setLocationName(state.availability.getInitialLocation.Name) // + "-" + state.availability.getInitialLocation.Address ?? "")
        setPatientName(state.patient.FirstName + " " + state.patient.LastName )
        setPatientEmail(state.patient.Email ?? "")
    }, [state.availability.getInitialLocation]);


    useEffect(() => {
        PageView(
            `pageview_appointment_visit_details_screen`, state.session.CoverKey?.toString() ,state.session.LocKey?.toString(), state.session.AcctKey?.toString()
        )
    },[])

    //Handlers for the buttons on the modal to be shown when LimitOneAppt option is enabled
    const handleModalBack = () => {
        //todo: track click
        Navigate(-1);
    }
    const handleModalConfirm = () => {
        //todo: track click
        confirmAppointment();
    }

    const displayDayDateTime = `${dayjs(state.availability.availability.AppointmentDateTime).format('dddd, MMMM DD')} - ${dayjs(state.availability.availability.AppointmentDateTime).format('h:mm A')}`;
    const displayLocation = state.availability.getInitialLocation.Address ?? " ";
    const displayPatient = patientName;
    return <>
        {limitOneAppt &&
            <AppointmentOneOnlyModal
                onBack={handleModalBack}
                onConfirm={handleModalConfirm}
                displayDayDateTime={displayDayDateTime}
                displayLocation={displayLocation}
                displayPatient={displayPatient}
                disableButtons={disableButton}
            />
        }
        <div>
            <Box
                display={"flex"}
                alignItems={"center"}
                className="wsui-header-partical"
            >
                <Box flexGrow={1}>
                    <Typography variant="h6"
                        fontWeight={"500"}
                        sx={{
                            fontSize: "18px",
                            fontWeight: "600",
                        }} >
                        {locationName}
                    </Typography>
                </Box>
                <Box sx={{}}>
                    <Typography
                        variant="h6"
                        fontWeight={"500"}
                        sx={{
                            fontSize: "16px",
                            fontWeight: "600",
                            marginTop: "5px"
                        }}
                    >
                        <div>{getFormattedDateTime(dTime)}</div>
                    </Typography>
                </Box>
            </Box>
            <Box borderBottom={"0.5px solid black !important"} className="wsui-box-p">
                <div className="Typogrphy-pb wsui-top-heading">
                    <div><b>{appointmentType}</b>
                        {state.embed.ProviderAgnostic === 0 ? <span className="heading-subText">   with <b>{providerFirstname} {ProviderLastName},{providerDegree} </b> </span> : null}
                    </div>
                </div>
            </Box>
            <div className="wsui-header-top">
                <div><label>Patient:</label> <b>{patientName}</b></div>
                <div><label>Email:</label> <b>{patientEmail}</b></div>
            </div>
            <div className='visited-appointment-page'>    
            <div className='wsui-box wsui-details-box'>
                <AppointmentReviewPanel
                    displayDayDateTime={displayDayDateTime}
                    displayLocation={displayLocation}
                    displayPatient={displayPatient}
                    mode="standard"
                    headerText="Please review all details below and click Confirm & Schedule to finalize your booking."
                />
            </div>  
            {loading === true &&
                <>
                    <div className="wsui-spinner-visit-details">
                        <Spinner />
                    </div>
                </>}
                <div className="wsui-confirm-button-container">
                    <Button
                        id="button-confirm-appt-sched"
                        className="button-confirm-appt-sched"
                        style={{
                            backgroundColor: "var(--fpc-blue)",
                            color: `${state.embed.fontColor}`,
                        }}
                        disabled={disableButton}
                        variant="contained"
                        size="large"
                        type="submit"
                        sx={{ borderRadius: 10 }}
                        onClick={confirmAppointment}
                    >
                        <span className="wsui-button-box">
                            Confirm & Schedule
                        </span>
                    </Button>
                </div>
            
            </div>
            {/* <Box className="wsui-footer-data wsui-footer-details" >
                <div className='wsui-footer-content'>
                    <p>if any of the information above is inaccurate please choose the <b>BACK</b> button.</p>
                </div>
                <div className="wsui-button-container">
                <Button className='back-footer' variant="contained"  sx={{borderRadius:10}} onClick={handleClickBack}>
                    <span className='wsui-button-box'>Back</span>
                </Button>
                </div>
            </Box> */}

        
        </div>
    </>
}

export default AppointmentVisitDetails