import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Patient from "../../models/Patient";

export const initialState: Patient = {
    AddKey: null
    , AcctKey: null
    , InsKey: null
    , LocKey: null
    , Lang: null
    , PatientID: null
    , Status: null
    , FirstName: null
    , LastName: null
    , Title: null
    , Address1: null
    , Address2: null
    , City: null
    , State: null
    , Zip: null
    , Home: null
    , Work: null
    , Cel: null
    , Email: null
    , Fax: null
    , Created: null
    , L4DSSN: null
    , Country: null
    , DOB: null
    , IsValid: null
    ,Gender:null
}


export const patientSlice = createSlice({
    name: 'patient',
    initialState,
    reducers: {
        updatePatient: (
            state,
            action: PayloadAction<Patient | null>
        ) => {
            if (action.payload) {
                state.FirstName = action.payload.FirstName;
                state.LastName = action.payload.LastName;
                state.Home = action.payload.Home;
                state.Email = action.payload.Email;
                state.DOB = action.payload.DOB;
            }
            else {
                state = initialState;
            }
        }
    }
})

export const { updatePatient } = patientSlice.actions
export default patientSlice.reducer