import { getInjectedParams } from '../models/InjectedParams';
import axios from 'axios';

//If the app was injected in non-iframe mode, use the script source instead of the window location
const getPath = (): string => {
  let path: string;
  const injectedParams = getInjectedParams();
  if(injectedParams && injectedParams.injected && injectedParams.scripturl) {
    path = injectedParams.scripturl;
  } else {
    path = window.location.host;
  }
  return path;
}

export const Environments =
{
  version: 0.02,
  production: false,
  useAPI: false,
  apiLocal: 'https://qa-booking-api.4patientcare.app',
  apiProd: 'https://webscheduler-crm.ditacapps.net',
  apiQA: 'https://qa-booking-api.4patientcare.app',
  apiStage: 'https://qa-booking-api.4patientcare.app',
  apiDev: 'https://dev-booking-api.4patientcare.app',
  apiConfigLocal: 'https://qa-wsc-api.4patientcare.app',
  apiConfigProd: 'https://wsc-api.4patientcare.app',
  apiConfigQA: 'https://qa-wsc-api.4patientcare.app',
  apiConfigStage: 'https://qa-wsc-api.4patientcare.app',
  apiConfigDev: 'https://dev-wsc-api.4patientcare.app'
};

type EnvironmentType = 'local' | 'dev' | 'qa' | 'stage' | 'prod'
const getEnvType = (): EnvironmentType => {
  let envType: EnvironmentType;
  const path = getPath();
  switch (true) {
    case path.includes('local'):
      envType = 'local';
      break;
    case path.includes('qa'):
    case path.includes('mango-ocean'):
      envType = 'qa';
      break;
    case path.includes('stage'):
      envType = 'stage';
      break;
    case path.includes('dev'):
    case path.includes('brave-flower'):
      envType = 'dev';
      break;
    default:
      envType = 'prod';
  }
  return envType;
}

export const getBaseAPI = async (): Promise<string> => {
  let baseConfig = await getBookingApi()
    if (baseConfig === "") {
    let api = Environments.apiLocal;
    const envType = getEnvType();
    switch (envType) {
      case 'local':
        api = Environments.apiLocal;
        break;
      case 'qa':
        api = Environments.apiQA;
        break;
      case 'stage':
        api = Environments.apiStage;
        break;
      case 'dev':
        api = Environments.apiDev;
        break;
      default:
        Environments.production = true;
        api = Environments.apiProd;
    }
    return api;
  } else {
    return baseConfig
  }
};
export const getConfigBaseAPI = async (): Promise<string> => {
  let baseConfig = await getConfigApi();
  if (baseConfig === "") {
    let api = Environments.apiConfigLocal;
    const envType = getEnvType();
    switch (envType) {
      case 'local':
        api = Environments.apiConfigLocal;
        break;
      case 'qa':
        api = Environments.apiConfigQA;
        break;
      case 'stage':
        api = Environments.apiConfigStage;
        break;
      case 'dev':
        api = Environments.apiConfigDev;
        break;
      default:
        Environments.production = true;
        api = Environments.apiConfigProd;
    }
    return api;
  } else {
    return baseConfig;
  }
  
};

export const getAppInsightsConnectionString = async (): Promise<string> => {
  const qaStrOld = 'InstrumentationKey=a908781f-f673-467a-a9ac-9a2cf2f1c78f;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/';
  const qaStr = '';
  const prodStr = 'InstrumentationKey=8914e405-76aa-4356-9ed3-efb9d1ffd666;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/';
  let strFromSettings = await getAppInsightsConnStrSetting();
  if (strFromSettings === "") {
    let connStr = qaStr;
    const envType = getEnvType();
    switch (envType) {
      case 'local':
        connStr = qaStr;
        break;
      case 'qa':
        connStr = qaStr;
        break;
      case 'stage':
        connStr = qaStr;
        break;
      case 'dev':
        connStr = qaStr;
        break;
      default:
        connStr = prodStr;
    }
    return connStr;
  } else {
    return strFromSettings;
  }

}



//Don't attempt to call the settings API using a non-absolute path if we are injected in non-iframe mode
const getConfigEndpoint = (): string => {
  let endpoint = process.env.REACT_APP_SINGLE_SETTING + "";
  const injectedParams = getInjectedParams();
  if(injectedParams && injectedParams.injected && injectedParams.scripturlbase) {
    //endpoint = injectedParams.scripturlbase + endpoint;
    endpoint = ""; //actually, CORS makes this impossible. Bypass this on injected, we cannot use dynamic settings
  }
  return endpoint;
}

//Don't attempt to call the settings API using a non-absolute path if we are injected
const getAllSettingsEndpoint = (): string => {
  let endpoint = process.env.REACT_APP_ALL_SETTINGS + "";
  const injectedParams = getInjectedParams();
  if(injectedParams && injectedParams.injected && injectedParams.scripturlbase) {
    //endpoint = injectedParams.scripturlbase + endpoint;
    endpoint = ""; //actually, CORS makes this impossible. Bypass this on injected, we cannot use dynamic settings
  }
  return endpoint;
}

export const getSetting = async (settingName: string): Promise<string> => {
  let response = "";
  let naValue = "07e3587b8036427b842c851e45f95dd4";
  //attempt to load setting first from storage
  let setting = sessionStorage.getItem(settingName) + "";
  if(setting === naValue)
  {
    response = process.env[settingName] + "";
  }
  else if(setting === "undefined" || setting === "null" || setting === "")
  {
    const configEndpoint = getConfigEndpoint() + "?settings=" + settingName;
    const path = getPath();
    if (path === "") { return response; }

    //cannot use ServiceHelper here since we don't yet have the app insights conn string
    await axios.get(configEndpoint)
      .then(res => {
        if (res.data.success && res.data.message) {
          response = res.data.message
          sessionStorage.setItem(settingName, response);
        }
        else
        {
          response = process.env[settingName] + "";
          sessionStorage.setItem(settingName, naValue);
        }
      }).catch((ex) => {
        response = "";
      })
  }
  else
  {
    response = setting;
  }
  
  return response;
}

export const getAllSettings = async (): Promise<string> => {
  const configEndpoint = getAllSettingsEndpoint();
    const path = getPath();
    if (path === "") { return ""; }

    //cannot use ServiceHelper here since we don't yet have the app insights conn string
    await axios.get(configEndpoint)
      .then(res => {
        if (res.data.success && res.data.message) {
          let settings = res.data.message
          settings.forEach((element: { key: string; value: string; }) => {
            sessionStorage.setItem(element.key, element.value);
          });
        }
      }).catch((ex) => {
        return "";
      })
  
  return "";
}

export const getBookingApi = async (): Promise<string> => {
  return await getSetting("REACT_APP_BOOKING_API");
}

export const getConfigApi = async (): Promise<string> => {
  return await getSetting("REACT_APP_CONFIG_API");
}

const getAppInsightsConnStrSetting = async (): Promise<string> => {
  return await getSetting("REACT_APP_INSIGHTS_CONN");
}
