import { kMaxLength } from 'buffer';
import { updateMultipleAppointments } from '../features/MultipleAppointments/multipleAppointmentsSlice';
import { updateCurrentPage } from '../features/session/sessionSlice';
import HashReturnObject from '../models/HashReturnObject';
import MultipleAppointmentDetails from '../models/MultipleAppointments';
import { StateRoute, stateRoutes } from '../models/Page';
import ExistingAppointmentService from '../services/ExistingAppointmentService';
import { StateModel } from '../models/StateModel';
import { updateMultipleAppointmentSelected } from '../features/MultipleAppointments/multipleAppointmentSelectedSlice';
import dayjs from 'dayjs';

export const arraySortExt = (arr: any[], arrSortOptions: any[]) => {
    let options: string | any[] = [];
    if (Array.isArray(arrSortOptions)) {
       options = arrSortOptions;
    } else {
       options = [arrSortOptions];      
    }
  
    arr.sort((_a: { [x: string]: any; }, _b: { [x: string]: any; }) => {
       let ret = 0;
       let i = 0;
       
       while(i < options.length) {
          const fieldName = options[i].fieldName;
          const ascending = options[i].ascending;
  
          const a = _a[fieldName];
          const b = _b[fieldName];
             
          let s1, s2;
  
          if (typeof a == "string") {
             s1 = (a ?? "").toString().toLowerCase();
             s2 = (b ?? "").toString().toLowerCase();
          } else if (a instanceof Date) {
             s1 = a ?? new Date("1/1/1900");
             s2 = b ?? new Date("1/1/1900");
          } else {
             s1 = a ?? 0;
             s2 = b ?? 0;
          }
          
          i++;
          if (ascending) {
             ret = s1 > s2 ? 1 : (s2 > s1 ? -1 : 0);
          } else {
             ret = s1 < s2 ? 1 : (s2 < s1 ? -1 : 0);
          }
          if (ret != 0) break;
       }
  
       return ret;      
    });
  
    return arr;
  };

  export const getDateTimeObj = (dateTime: Date): any => {
   const y = dateTime.getFullYear().toString();
   const _m = (dateTime.getMonth() + 1);
   const _d = dateTime.getDate();
   const _h = dateTime.getHours();
   const _mm = dateTime.getMinutes();
   const _s = dateTime.getSeconds();
   
   const m = (_m < 10 ? '0' : '') + _m;
   const d = (_d < 10 ? '0' : '') + _d;
   const h = (_h < 10 ? '0' : '') + (_h - (_h > 12 ? 12 : 0));
   const mm = (_mm < 10 ? '0' : '') + _mm;
   const tt = _h < 12 ? 'AM' : 'PM';
 
   const _date = `${m}/${d}/${y}`;
   const _time = `${h}:${mm} ${tt}`;
   return {
     "date": _date,
     "time": _time,
     "dateTime": `${_date} ${_time}`
   };
 };

 export const getProperURL = (origUrl: string, embed : any): string => {
 
      let url = origUrl
     // if (embed.CoverKey !== "" || embed.LockKey !== "")

      var hasQuery : any = false;
      var withParam : any = false;

      (Object.keys(embed) as (keyof typeof embed)[]).forEach((key, index) => {
         hasQuery = embed[key] !== "" ? true : false
       });

      if (hasQuery)
         url += "?"

      if (embed.GroupKey)
      {
        url += `GroupID=${embed.GroupKey}`
        withParam = true
      }         

      if (embed.CoverKey !== "")
      {
         url += `CoverKey=${embed.CoverKey}`
         withParam = true
      }

      if (embed.LocKey)
      {
         if (withParam) url += "&"
         url += `LocKey=${embed.LocKey}`
         withParam = true
      }    

      if (embed.AcctKey)
      {
         if (withParam) url += "&"
         url += `AcctKey=${embed.AcctKey}`
         withParam = true
      }          
      
      if (embed.TaskKey)
      {
         if (withParam) url += "&"
         url += `TaskKey=${embed.TaskKey}`
         withParam = true
      }            

      if (embed.Stylings)
      {
         if (withParam) url += "&"
         url += `Stylings=${embed.Stylings}`
         withParam = true
      }           

      if (embed.TrackingID)
      {
         if (withParam) url += "&"
         url += `TID=${embed.TrackingID}`
         withParam = true
      }      
      
      if (embed.ReferredBy)
      {
         if (withParam) url += "&"
         url += `ReferredBy=${embed.ReferredBy}`
         withParam = true
      }         

      if (embed.Source)
      {
         if (withParam) url += "&"
         url += `Source=${embed.Source}`
      }         

       return(url)  
 }
  export const getInputDateFormat = (date: Date | null | undefined): string => {
   if (date) {
     const day = date.getDate();
     const month = date.getMonth() + 1;
     const formatDate = day < 10 ? `0${day}`:day;
     const formatMonth = month < 10 ? `0${month}`: month;
     return [date.getFullYear(), formatMonth, formatDate].join('-');
   }
   return '';
 }
 
 export const getDateFromInputDateFormatUTC = (date: string): Date | null => {
   try {
     const [year, month, day] = date.split('-');
     return new Date(Date.UTC(parseInt(year), parseInt(month) - 1, parseInt(day), 0, 0, 0, 0));
   }
   catch {
     return null;
   }
 }
 
 export const getDateFromInputDateFormat = (date: string): Date | null => {
   try {
     const [year, month, day] = date.split('-');
     return new Date(parseInt(year), parseInt(month) - 1, parseInt(day), 0, 0, 0, 0);
   }
   catch {
     return null;
   }
 }

export const getFormattedDay = (date: string | number | Date) =>
    dayjs(date).format("ddd, MMM D");
export const getFormattedDate = (date: string | number | Date) =>
    dayjs(date).format("ddd, MMM D, YYYY");
export const getFormattedDateTime = (date: string | number | Date) =>
    dayjs(date).format("ddd, MMM D, YYYY - h:mm A");

export const genDerValidation= (gender : number): string | undefined=>{

  if(gender === 1){
    return "male"
  }
  else if(gender === 0){
    return "female"
  }
  else if(gender === 2){
    return "other"
  }

}


export const digitRepeatedTenTimes=(tmpString: string): string=>{
  // console.log(tmpString);
  // const regex = new RegExp("\\b([0-9])\\1{9}\\b");
  // let tmpFunction=false;
  // if(regex.test(tmpString))
  // {
  //   return 'Invalid Number'
  // }
  if (!tmpString || tmpString.length < 6) {
    return 'DOB *';
  } 
return 'test';
}

 export const dobValidation = (date: string): string => {
   if (!date || date.length < 6) {
     return 'DOB *';
   } else {
     try {
       const [yyyy,mm,dd] = date.split('-');
       const dob = new Date(parseInt(yyyy), parseInt(mm) - 1, parseInt(dd));
       if (isNaN(dob.getTime())) {
         return 'DOB is invalid.';
       }
       else if (yyyy.length < 4) {
         return 'DOB is invalid.';
       }
       else if (dob > new Date()) {
         return 'DOB must be in the past.';
       } 
       else if (dob.getFullYear() < 1900) {
         return 'DOB must be after 1900.';
       }
       else if (parseInt(dd) != dob.getDate()) {
         return 'DOB date invalid.';
       }
       else if (parseInt(mm) - 1 != dob.getMonth()) {
         return 'DOB month invalid.';
       }
       else if (parseInt(yyyy) != dob.getFullYear()) {
         return 'DOB year invalid.';
       }
     } catch {
       return 'DOB is invalid.';
     }
   }
   return '';
 }

 export enum PageName {
  LandingPage = 0,
  SelectAppointmentTime = 1,
  PatientDemographics = 2,
  PatientInsurance = 3,
  CustomQuestion = 4,
  AppointmentVisitDetails = 5,
  MultipleAppointment = 6,
  AppointmentWindow = 7,
  AppointmentNotAvailable = 8,
  appointmentConfirmation = 9
}

const PagePath = ["/",
                  "/SelectAppointmentTime",
                  "/PatientDemographics",
                  "/visioninsurance",
                  "/CustomQuestion", 
                  "/appointmentvisitdetails",
                  "/multipleAppointment",
                  "/appointmentwindow",
                  "/AppointmentNotAvailable",
                  "/appointmentConfirmation"
                ]

type PageNumAndRoute = {
  num: PageName,
  route: StateRoute
};
const pages: PageNumAndRoute[] = [{
  num: PageName.LandingPage,
  route: stateRoutes.landingPage
}, {
  num: PageName.SelectAppointmentTime,
  route: stateRoutes.selectAppointmentTime
}, {
  num: PageName.PatientDemographics,
  route: stateRoutes.patientDemographics
}, {
  num: PageName.PatientInsurance,
  route: stateRoutes.visionInsurance
}, {
  num: PageName.CustomQuestion,
  route: stateRoutes.customQuestion
}, {
  num: PageName.AppointmentVisitDetails,
  route: stateRoutes.appointmentVisitDetails
}, {
  num: PageName.MultipleAppointment,
  route: stateRoutes.multipleAppointment
}, {
  num: PageName.AppointmentWindow,
  route: stateRoutes.appointmentWindow
}, {
  num: PageName.AppointmentNotAvailable,
  route: stateRoutes.appointmentNotAvailable
}, {
  num: PageName.appointmentConfirmation,
  route: stateRoutes.appointmentConfirmation
}];

const stateRouteToPageNum = (route: StateRoute): PageName => {
  return pages.find(val => val.route === route)?.num ?? PageName.LandingPage;
}
const pageNumToStateRoute = (num: PageName): StateRoute => {
  return pages.find(val => val.num === num)?.route ?? stateRoutes.landingPage;
}

export const css = (...classes: (string | undefined)[]): string | undefined => {
  let str: string | undefined = "";
  for (const cls of classes) {
    if (cls !== undefined) {
      if (str !== "") {
        str += " ";
      }
      str += cls;
    }
  }
  return str === "" ? undefined : str;
}

export const NavigatePage = (dispatch : any, direction : 'next' | 'prev', state : StateModel, navigate : (dest: StateRoute | number) => void, jumpToPage? : StateRoute) => {
  const limitOneAppt = state.embed.LimitOneAppt;


  let currentPage = state.session.CurrentPage ?? PageName.LandingPage;



  if (jumpToPage !== undefined ) // Multiple Appointments
  {
      currentPage = stateRouteToPageNum(jumpToPage);
  }
  else
  {
      if (direction === "next")
        currentPage = currentPage + 1
      if (direction === "prev") 
        currentPage = currentPage - 1
  }

  // if (currentPage === PageName.PatientDemographics && !state.embed.SkipDemographics)
  //     currentPage += 1  

  if ((currentPage === PageName.PatientDemographics) && state.embed.SkipDemographics===false)
  {
    currentPage += 1  
  }
  

  // if (currentPage === PageName.PatientInsurance && !state.embed.SkipPatientInsurance)
  //     currentPage += 1  
  if ((currentPage === PageName.PatientInsurance) && state.embed.SkipPatientInsurance===false)
   {
    currentPage += 1  
   }   


  //  if (currentPage === PageName.CustomQuestion && !state.embed.SkipCustomQuestions)
  //     currentPage += 1  

  if ((currentPage === PageName.CustomQuestion) && state.embed.SkipCustomQuestions===false)
   {
    currentPage += 1 
   }    

  let newRoute = pageNumToStateRoute(currentPage);

  //var path = PagePath[currentPage]
  dispatch(updateCurrentPage({CurrentPage :  currentPage}))    
  if (currentPage === PageName.AppointmentVisitDetails && state.session.CurrentPage !== PageName.MultipleAppointment)
  {
    ExistingAppointmentService.existingAppointment({
      ...state.session,
      TaskKey: state.availability.availability.TaskKey,
      AcctKey: state.availability.availability.AcctKey,
      LocKey: state.availability.availability.LocKey
      })
      .then((mutipleAppointment: HashReturnObject<MultipleAppointmentDetails>) => {
        dispatch(updateMultipleAppointments(mutipleAppointment.data));
        if(limitOneAppt) {
          //always remove all existing appointments when Limit One Appt is enabled
          dispatch(updateMultipleAppointmentSelected(mutipleAppointment.data.map(item => item.ApptKey)));
        }
        if(mutipleAppointment.data.length > 0 && !limitOneAppt){
          dispatch(updateCurrentPage({CurrentPage :  PageName.MultipleAppointment}))  
          newRoute = stateRoutes.multipleAppointment;
          navigate(newRoute);
        }
        else{
          dispatch(updateCurrentPage({CurrentPage :  PageName.AppointmentVisitDetails}))  
          newRoute = stateRoutes.appointmentVisitDetails;
          navigate(newRoute);
        }
      })
      .catch((err) => {
        console.error(err);
      })            
  }    
  else
  { 
    //const url =  getProperURL(path, state.embed)
    //navigate(url);
    navigate(newRoute)
  }

}
 