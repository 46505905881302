import { createSlice } from "@reduxjs/toolkit";
import PageState, { StateRoute, stateRoutes } from "../../models/Page";

const initialState: PageState = {
  route: stateRoutes.landingPage,
  history: [stateRoutes.landingPage],
  alreadyLoadedLandingData: false,
  initialLocKey: undefined
}

export const pageSlice = createSlice({
  name:'page',
  initialState,
  reducers: {   
    updatePage: (state, action: { payload: StateRoute | number, type: string })=> {
      try{
        if (action.payload) {
          //If the navigation is to a negative number, pop that amount of items from the history and then
          //use the top item as the new route
          if(typeof action.payload === 'number') {
            if(action.payload < 0) {
              let newHistory = [...state.history];
              if(newHistory.length <= action.payload) {
                newHistory = [stateRoutes.landingPage];
              } else {
                newHistory.splice(newHistory.length + action.payload, -1 * action.payload);
              }
              const newRoute = newHistory[newHistory.length - 1];
              state.route = newRoute;
              state.history = newHistory;
            }

          //Else if the navigation is to a specified route, add that route to the history (if it's not already there)
          //and navigate to it
          } else {
            if(state.route === 'landingPage' && action.payload === 'selectAppointmentTime') {
              state.alreadyLoadedLandingData = true;
            }
            state.route = action.payload
            if(state.history[state.history.length - 1] !== action.payload) {
              state.history = [...state.history, action.payload];
            }
          }
        }
      }
      catch(e){
        console.log('Update page error (pageSlice)')
      }
    },
    popHistory: (state) => {
      if(state.history.length > 1) {
        const newHistory = [...state.history];
        newHistory.splice(newHistory.length - 1, 1);
        state.history = newHistory;
      }
    },
    setLandingDataLoaded: (state) => {
      state.alreadyLoadedLandingData = true;
    },
    setInitialLocKey: (state, action: { payload: string | null | undefined, type: string }) => {
      state.initialLocKey = action.payload;
    }
  }
});

export const { updatePage, popHistory, setLandingDataLoaded, setInitialLocKey } = pageSlice.actions
export default pageSlice.reducer
