import InsuranceType, { secretCode, InsuranceTypeData, mapInsuranceTypeData } from '../models/InsuranceType';
import { SessionModel } from '../models/Session';
import { getConfigBaseAPI } from "../environments/Environments";
import { apiFetch } from './ServiceHelper';

const InsuranceTypeService = {
    getInsuranceType: (session: SessionModel): Promise<InsuranceType[]> => {

        const queryString = `secretCode=${secretCode}&coverKey=${session.CoverKey}&locKey=${session.LocKey ?? 0}&acctKey=${session.AcctKey ?? 0}`;
        return new Promise( async (resolve, reject) => {
                const url = await getConfigBaseAPI() + '/api/v1/Configuration/modGetInsuranceData?' + (queryString);
                apiFetch(url, {
                    method: 'GET',
                })
                .then((result) => Promise.resolve(result))
                .then((returnObject: InsuranceTypeData[]) => {
                    const insuranceTypes: InsuranceType[] = [];
                    returnObject.forEach((data: InsuranceTypeData) => {
                        insuranceTypes.push(mapInsuranceTypeData(data));
                    });
                    const distinctInsuranceTypes = insuranceTypes.filter(
                        (thing, i, arr) => arr.findIndex(t => t.InsName === thing.InsName) === i
                    );
                    resolve(distinctInsuranceTypes);
                })
                .catch((e) => {
                    console.log('Insurance Type Error');
                    reject(e);
                });
        });
    }
}

export default InsuranceTypeService;