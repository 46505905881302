import { getDateFromInputDateFormatUTC, getInputDateFormat, dobValidation, genDerValidation,  digitRepeatedTenTimes } from "../utils/Util";
import Demographics from "./Demographics";
import UserInputField from "./UserInputField";

export interface DemographicUserInputField extends UserInputField {
    enabled: number
}

export default interface DemographicsUserInput {
    FirstName: DemographicUserInputField,
    LastName: DemographicUserInputField,
    DOB: DemographicUserInputField,
    Email: DemographicUserInputField,
    Home: DemographicUserInputField,
    Cel: DemographicUserInputField,
    Address1: DemographicUserInputField,
    Address2: DemographicUserInputField,
    City: DemographicUserInputField,
    State: DemographicUserInputField,
    Lang: DemographicUserInputField,
    Country: DemographicUserInputField,
    Zip: DemographicUserInputField,
    ReferredBy1: DemographicUserInputField,
    ReferredBy2: DemographicUserInputField,
    ReferredBy3: DemographicUserInputField,
    ReferredBy4: DemographicUserInputField,
    ReferredBy5: DemographicUserInputField,
    Gender: DemographicUserInputField,
    L4DSSN: DemographicUserInputField,
}

export function validateDemographicsUserInput(demographics: DemographicsUserInput): DemographicsUserInput {
    const dobError = dobValidation(demographics.DOB.value);
    const phoneError = digitRepeatedTenTimes(demographics.Cel.value);



 
const isIdentical=(value:any)=>{

  let pattern= new RegExp("\\b([0-9])\\1{9}\\b");
  if (value==null)
  {
      return false;
  }


  if(pattern.test(value)==true)
  {
      return true;
  }
  else
  {
      return false;
  }


}

const isValidPhone=(value:any)=>{

  let pattern= new RegExp(/^[2-9]\d{9}$/);
  if (value==null)
  {
      return false;
  }


  if(pattern.test(value)==true)
  {
      return true;
  }
  else
  {
      return false;
  }


}

const isValidEmail=(value:any)=>{

  let pattern= new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
  if (value==null)
  {
      return false;
  }


  if(pattern.test(value)==true)
  {
      return true;
  }
  else
  {
      return false;
  }


}

function EmailError(obj: any,label:string):string
    {
     
    
      if(obj.enabled === 1)
      {
        if(obj.value.length <=0)
        {
          return label + '*';
        }

         if(isValidEmail(obj.value)===false)
         {
           return 'Invalid ' + label + ' Format';
         }
          return ''
      }
      else
      {
        return '';
      }

    }

function PhoneNumberError(obj: any,label:string):string
    {
     
    
      if(obj.enabled === 1)
      {
        if(obj.value.length <=0)
        {
          return label + '*';
        }
          if(obj.value.length <10)
          {
            
            return 'Invalid ' + label;
          }
          if(isValidPhone(obj.value)===false)
          {
            return 'Invalid ' + label;
          }
          if(isIdentical(obj.value ))
          {
            return 'Invalid ' + label;
          }
         
          return ''
      }
      else
      {
        return '';
      }

    }
    
    
    function LanguageError(obj: any,label:string):string
    {
     
    
      if(obj.enabled === 1)
      {
        if(obj.value.length <=0)
        {
          return label + '*';
        }

        
        if(Number(obj.value)<0)
        {
          
          return label + '*';
        }
        if(obj.value===-1)
        {
          
          return label + '*';
        }
        if(obj.value===null)
          {
            
            return label + '*';
          }
         
          if(obj.value==="-1")
          {
            
            return label + '*';
          }
         
          return ''
      }
      else
      {
        return '';
      }

    }
    function StateError(obj: any,label:string):string
    {
     

      if(obj.enabled === 1)
      {
        if(obj.value.length <=0)
        {
          return label + '*';
        }

       
        if(obj.value===-1)
        {
          
          return label + '*';
        }

        if(obj.value==="Please choose one option")
        {
          
          return label + '*';
        }
        if(obj.value===null)
          {
            
            return label + '*';
          }
         
         
         
          return ''
      }
      else
      {
        return '';
      }

    }


    

    return {
      ...demographics,
      FirstName: {
        ...demographics.FirstName,
        error: demographics.FirstName.enabled !== 1 || demographics.FirstName.value.length > 0 
        ? ''
        : 'First Name*'
      },
      LastName: {
        ...demographics.LastName,
        error: demographics.LastName.enabled !== 1 || demographics.LastName.value.length > 0 
        ? ''
        : 'Last Name*'
      },
      Address1: {
        ...demographics.Address1,
        error:demographics.Address1.enabled !== 1 || demographics.Address1.value.length > 0 
        ? '' 
        : 'Address1*'
      },
      Address2: {
        ...demographics.Address2,
        error: demographics.Address2.enabled !== 1 || demographics.Address2.value.length > 0 
        ? ''
        : 'Address2*'
      },
      City: {
        ...demographics.City,
        error: demographics.City.enabled !== 1 || demographics.City.value.length > 0 
        ? '' 
        : 'City*'
      },
      State: {
        ...demographics.State,
        error: StateError(demographics.State,"State")
      },
      Lang: {
        ...demographics.Lang,
        error:LanguageError(demographics.Lang,"Language")
      },
      Country: {
        ...demographics.Country,
        error: demographics.Country.enabled !== 1 || demographics.Country.value.length > 0 
        ? '' 
        : 'Country*'
      },
      Zip: {
        ...demographics.Zip,
        error: demographics.Zip.enabled !== 1 || demographics.Zip.value.length > 0 
        ? (demographics.Zip.value.trim().indexOf(' ') > 0 ? 'Spaces are not allowed in Zip' : '') 
        : 'Zip*'
      },
      Gender:{
        ...demographics.Gender,
        error: demographics.Gender.enabled !== 1 || demographics.Gender.value.length > 0 
        ? ''
        : 'Gender*'
      },
      ReferredBy1: {
        ...demographics.ReferredBy1,
        error:  demographics.ReferredBy1.enabled !== 1 || demographics.ReferredBy1.value.length > 0 
        ? ''
        : 'Referred*'
      },
      L4DSSN: {
        ...demographics.L4DSSN,
        error: demographics.L4DSSN.enabled !== 1 || demographics.L4DSSN.value.length > 0 
        ? demographics.L4DSSN.value.length === 0 ? '' : (demographics.L4DSSN.value.length < 4 ? 'Enter Last 4 of SSN' : '') 
        : 'Last 4 Digits of SSN*'
      },
/*       ReferredBy2: {
        ...demographics.ReferredBy2,
        error: demographics.ReferredBy2.enabled !== 1 || demographics.ReferredBy2.value.length > 0 
        ? '' 
        : 'ReferredBy2*'
      },
      ReferredBy3: {
        ...demographics.ReferredBy3,
        error: demographics.ReferredBy3.enabled !== 1 || demographics.ReferredBy3.value.length > 0 
        ? '' 
        : 'ReferredBy3*'
      },
      ReferredBy4: {
        ...demographics.ReferredBy4,
        error: demographics.ReferredBy4.enabled !== 1 || demographics.ReferredBy4.value.length > 0 
        ? '' 
        : 'ReferredBy4*'
      },
      ReferredBy5: {
        ...demographics.ReferredBy5,
        error: demographics.ReferredBy5.enabled !== 1 || demographics.ReferredBy5.value.length > 0 
        ? '' 
        : 'ReferredBy5*'
      }, */
      DOB: {
        ...demographics.DOB,
        error: dobError
      },
      Email: {
        ...demographics.Email,
        error:EmailError(demographics.Email,"Email")
      },
      Home: {
        ...demographics.Home,
        error: PhoneNumberError(demographics.Home,"Number")
      }, 
      Cel: {
        ...demographics.Cel,
        error: PhoneNumberError(demographics.Cel,"Number")
      }, 
      // Home: {
      //   ...demographics.Home,
      //   value: demographics.Home.value.replace(/[^0-9]+/g, ''),
      //   error: (demographics.Home.value.replace(/[^0-9]+/g, '').length == 10 && (!demographics.Home.value.startsWith('1') && !demographics.Home.value.startsWith('0'))) ? '' : ((demographics.Home.value.startsWith('1') || demographics.Home.value.startsWith('0')) && demographics.Home.value.replace(/[^0-9]+/g, '').length <= 10)  ?  'Please enter a valid phone number.' : ((!demographics.Home.value.startsWith('1') || !demographics.Home.value.startsWith('0')) && demographics.Home.value.replace(/[^0-9]+/g, '').length > 0) ? 'Please enter a valid phone number.' : 'Home Phone*'
      // },
      // Cel: {
      //   ...demographics.Cel,
      //   value: demographics.Cel.value.replace(/[^0-9]+/g, ''),
      //   error: (demographics.Cel.value.replace(/[^0-9]+/g, '').length == 10 && (!demographics.Cel.value.startsWith('1') && !demographics.Cel.value.startsWith('0'))) ? '' : ((demographics.Cel.value.startsWith('1') || demographics.Cel.value.startsWith('0')) && demographics.Cel.value.replace(/[^0-9]+/g, '').length <= 10)  ?  'Please enter a valid phone number.' : ((!demographics.Cel.value.startsWith('1') || !demographics.Cel.value.startsWith('0')) && demographics.Cel.value.replace(/[^0-9]+/g, '').length > 0) ? 'Please enter a valid phone number.' : 'Cell Phone*'
      // }, 
      // Home: {
      //   ...demographics.Home,
      //   value: demographics.Home.value.replace(/[^0-9]+/g, ''),
      //   error: demographics.Home.enabled !== 1 || demographics.Home.value.length > 0 
      //   ? demographics.Home.value.length === 0 ? '' : (demographics.Home.value.length < 10 ? 'Enter a valid phone number.' : '') 
      //   : 'Home Phone*'
      // }, 
      // Cel: {
      //   ...demographics.Cel,
      //   error: demographics.Email.enabled !== 1 || demographics.Email.value.length > 0
      //   ? (demographics.Email.value.trim().indexOf(' ') > 0 ? 'Spaces are not allowed in email address' : '')
      //   : 'Email*'
      // },   
      // Cel: {
      //   ...demographics.Cel,
      //   value: demographics.Cel.value.replace(/[^0-9]+/g, ''),
      //   error: demographics.Cel.enabled !== 1 || demographics.Cel.value.length > 0 
      //   ? demographics.Cel.value.length === 0 ? '' : (demographics.Cel.value.length < 10 ? 'Enter a valid phone number.' : '') 
      //   : 'Cell Phone*'
      // },  

    }
  }


  export function validateDemographicsUserInputOLD(demographics: DemographicsUserInput): DemographicsUserInput {
    const dobError = dobValidation(demographics.DOB.value);
    const phoneError = digitRepeatedTenTimes(demographics.Cel.value);
   
    return {
      ...demographics,
      FirstName: {
        ...demographics.FirstName,
        error: demographics.FirstName.enabled !== 1 || demographics.FirstName.value.length > 0 
        ? ''
        : 'First Name is required.'
      },
      LastName: {
        ...demographics.LastName,
        error: demographics.LastName.enabled !== 1 || demographics.LastName.value.length > 0 
        ? ''
        : 'Last Name is required.'
      },
      Address1: {
        ...demographics.Address1,
        error: demographics.Address1.enabled !== 1 || demographics.Address1.value.length > 0 
        ? '' 
        : 'Address1 is required.'
      },
      Address2: {
        ...demographics.Address2,
        error: demographics.Address2.enabled !== 1 || demographics.Address2.value.length > 0 
        ? ''
        : 'Address2 is required.'
      },
      City: {
        ...demographics.City,
        error: demographics.City.enabled !== 1 || demographics.City.value.length > 0 
        ? '' 
        : 'City is required.'
      },
      State: {
        ...demographics.State,
        error: demographics.State.enabled !== 1 || demographics.State.value.length > 0 
        ? '' 
        : 'State is required.'
      },
      Lang: {
        ...demographics.Lang,
        error: demographics.Lang.enabled !== 1 ||   Number(demographics.Lang.value) === 0 ||  Number(demographics.Lang.value) === 1
        ? '' 
        : 'Language is required.'
      },
      Country: {
        ...demographics.Country,
        error: demographics.Country.enabled !== 1 || demographics.Country.value.length > 0 
        ? '' 
        : 'Country is required.'
      },
      Zip: {
        ...demographics.Zip,
        error: demographics.Zip.enabled !== 1 || demographics.Zip.value.length > 0 
        ? (demographics.Zip.value.trim().indexOf(' ') > 0 ? 'Spaces are not allowed in Zip' : '') 
        : 'Zip is required.'
      },
      Gender:{
        ...demographics.Gender,
        error: demographics.Gender.enabled !== 1 || demographics.Gender.value.length > 0 
        ? ''
        : 'Gender is required.'
      },
      ReferredBy1: {
        ...demographics.ReferredBy1,
        error: demographics.ReferredBy1.enabled !== 1 || demographics.ReferredBy1.value.length > 0 
        ? ''
        : 'Referred By is required.'
      },
      L4DSSN: {
        ...demographics.L4DSSN,
        error: demographics.L4DSSN.enabled !== 1 || demographics.L4DSSN.value.length > 0 
        ? demographics.L4DSSN.value.length === 0 ? '' : (demographics.L4DSSN.value.length < 4 ? 'Enter Last 4 of SSN' : '') 
        : 'Last 4 of SSN is required.'
      },
/*       ReferredBy2: {
        ...demographics.ReferredBy2,
        error: demographics.ReferredBy2.enabled !== 1 || demographics.ReferredBy2.value.length > 0 
        ? '' 
        : 'ReferredBy2 is required.'
      },
      ReferredBy3: {
        ...demographics.ReferredBy3,
        error: demographics.ReferredBy3.enabled !== 1 || demographics.ReferredBy3.value.length > 0 
        ? '' 
        : 'ReferredBy3 is required.'
      },
      ReferredBy4: {
        ...demographics.ReferredBy4,
        error: demographics.ReferredBy4.enabled !== 1 || demographics.ReferredBy4.value.length > 0 
        ? '' 
        : 'ReferredBy4 is required.'
      },
      ReferredBy5: {
        ...demographics.ReferredBy5,
        error: demographics.ReferredBy5.enabled !== 1 || demographics.ReferredBy5.value.length > 0 
        ? '' 
        : 'ReferredBy5 is required.'
      }, */
      DOB: {
        ...demographics.DOB,
        error: dobError
      },
      Email: {
        ...demographics.Email,
        error: demographics.Email.enabled !== 1 || demographics.Email.value.length > 0
        ? (demographics.Email.value.trim().indexOf(' ') > 0 ? 'Spaces are not allowed in email address' : '')
        : 'Email is required.'
      },
      // Home: {
      //   ...demographics.Home,
      //   value: demographics.Home.value.replace(/[^0-9]+/g, ''),
      //   error: (demographics.Home.value.replace(/[^0-9]+/g, '').length == 10 && (!demographics.Home.value.startsWith('1') && !demographics.Home.value.startsWith('0'))) ? '' : ((demographics.Home.value.startsWith('1') || demographics.Home.value.startsWith('0')) && demographics.Home.value.replace(/[^0-9]+/g, '').length <= 10)  ?  'Please enter a valid phone number.' : ((!demographics.Home.value.startsWith('1') || !demographics.Home.value.startsWith('0')) && demographics.Home.value.replace(/[^0-9]+/g, '').length > 0) ? 'Please enter a valid phone number.' : 'Home Phone is required.'
      // },
      // Cel: {
      //   ...demographics.Cel,
      //   value: demographics.Cel.value.replace(/[^0-9]+/g, ''),
      //   error: (demographics.Cel.value.replace(/[^0-9]+/g, '').length == 10 && (!demographics.Cel.value.startsWith('1') && !demographics.Cel.value.startsWith('0'))) ? '' : ((demographics.Cel.value.startsWith('1') || demographics.Cel.value.startsWith('0')) && demographics.Cel.value.replace(/[^0-9]+/g, '').length <= 10)  ?  'Please enter a valid phone number.' : ((!demographics.Cel.value.startsWith('1') || !demographics.Cel.value.startsWith('0')) && demographics.Cel.value.replace(/[^0-9]+/g, '').length > 0) ? 'Please enter a valid phone number.' : 'Cell Phone is required.'
      // }, 
      // Home: {
      //   ...demographics.Home,
      //   value: demographics.Home.value.replace(/[^0-9]+/g, ''),
      //   error: demographics.Home.enabled !== 1 || demographics.Home.value.length > 0 
      //   ? demographics.Home.value.length === 0 ? '' : (demographics.Home.value.length < 10 ? 'Enter a valid phone number.' : '') 
      //   : 'Home Phone is required.'
      // }, 
      Cel: {
        ...demographics.Cel,
        error: demographics.Email.enabled !== 1 || demographics.Email.value.length > 0
        ? (demographics.Email.value.trim().indexOf(' ') > 0 ? 'Spaces are not allowed in email address' : '')
        : 'Cel*'
      },   
      // Cel: {
      //   ...demographics.Cel,
      //   value: demographics.Cel.value.replace(/[^0-9]+/g, ''),
      //   error: demographics.Cel.enabled !== 1 || demographics.Cel.value.length > 0 
      //   ? demographics.Cel.value.length === 0 ? '' : (demographics.Cel.value.length < 10 ? 'Enter a valid phone number.' : '') 
      //   : 'Cell Phone is required.'
      // },  

    }
  }

export function emptyDemographicsUserInput(): DemographicsUserInput {
    return {
        FirstName: { value: '', error: '', enabled: 1 },
        LastName: { value: '', error: '', enabled: 1 },
        DOB: { value: '', error: '', enabled: 0 },
        Email: { value: '', error: '', enabled: 0 },
        Home: { value: '', error: '', enabled: 0 },
        Cel: { value: '', error: '', enabled: 0 },
        Address1: { value: '', error: '', enabled: 0 },
        Address2: { value: '', error: '', enabled: 0 },
        City: { value: '', error: '', enabled: 0 },
        State: { value: '', error: '', enabled: 0 },
        Lang: { value: '', error: '', enabled: 0 },
        Country: { value: '', error: '', enabled: 0 },
        Zip: { value: '', error: '', enabled: 0 },
        Gender: { value: '', error: '', enabled: 0 },
        ReferredBy1: { value: '', error: '', enabled: 0 },
        ReferredBy2: { value: '', error: '', enabled: 0 },
        ReferredBy3: { value: '', error: '', enabled: 0 },
        ReferredBy4: { value: '', error: '', enabled: 0 },
        ReferredBy5: { value: '', error: '', enabled: 0 },
        L4DSSN: { value: '', error: '', enabled: 0 },
    }
}

export function mapDemographicsToUserInput(demographics: Demographics): DemographicsUserInput {
  const genderValue = genDerValidation(demographics.Gender as any);


    return {
        FirstName: { value: demographics.FirstName ?? '', error: '', enabled: 1 },
        LastName: { value: demographics.LastName ?? '', error: '', enabled: 1 },
        DOB: { value: getInputDateFormat(demographics.DOB), error: '', enabled: demographics.Config.DDOB },
        Email: { value: demographics.Email ?? '', error: '', enabled: demographics.Config.DEmail },
        Home: { value: demographics.Home ?? '', error: '', enabled: demographics.Config.DHomePhone },
        Cel: { value: demographics.Cel ?? '', error: '', enabled: demographics.Config.DCellPhone },
        Address1: { value: demographics.Address1 ?? '', error: '', enabled: demographics.Config.DAddress1 },
        Address2: { value: demographics.Address2 ?? '', error: '', enabled: demographics.Config.DAddress2 },
        City: { value: demographics.City ?? '', error: '', enabled: demographics.Config.DCity },
        State: { value: demographics.State ?? '', error: '', enabled: demographics.Config.DState },
        Lang: { value: demographics.Lang ?? '', error: '', enabled: demographics.Config.DPrefLang },
        Country: { value: demographics.Country ?? '', error: '', enabled: demographics.Config.DState },
        Zip: { value: demographics.Zip ?? '', error: '', enabled: demographics.Config.DZip },
        ReferredBy1: { value: demographics.ReferredBy1 ?? '', error: '', enabled: demographics.Config.DReferredBy1 },
        ReferredBy2: { value: demographics.ReferredBy2 ?? '', error: '', enabled: demographics.Config.DReferredBy2 },
        ReferredBy3: { value: demographics.ReferredBy3 ?? '', error: '', enabled: demographics.Config.DReferredBy3 },
        ReferredBy4: { value: demographics.ReferredBy4 ?? '', error: '', enabled: demographics.Config.DReferredBy4 },
        ReferredBy5: { value: demographics.ReferredBy5 ?? '', error: '', enabled: demographics.Config.DReferredBy5 },
        Gender: { value: genderValue ?? '', error: '', enabled: demographics.Config.DGender },
        L4DSSN: { value: demographics.L4DSSN ?? '', error: '', enabled: demographics.Config.DL4DSSN },
    };
}

export function mapUserInputToDemographics(input: DemographicsUserInput, demographics: Demographics, isValid: boolean): Demographics {
    return {
        ...demographics,
        FirstName: input.FirstName.value,
        LastName: input.LastName.value,
        DOB: input.DOB.value ? getDateFromInputDateFormatUTC(input.DOB.value) : null,
        Email: input.Email.value,
        Home: input.Home.value,
        Cel: input.Cel.value,
        Address1: input.Address1.value,
        Address2: input.Address2.value,
        City: input.City.value,
        State: input.State.value,
        Lang:input.Lang.value,
        Country: input.Country.value,
        Zip: input.Zip.value,
        ReferredBy1: input.ReferredBy1.value,
        ReferredBy2: input.ReferredBy2.value,
        ReferredBy3: input.ReferredBy3.value,
        ReferredBy4: input.ReferredBy4.value,
        ReferredBy5: input.ReferredBy5.value,
        L4DSSN: input.L4DSSN.value,
        IsValid: isValid
    };
}