import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 600,
    innerHeight: 54,
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[10],
    fontSize: 18,
    borderRadius: "11px",
    fontWeight: 600,
    padding: "12px",
  },
}));

const CustomWidthTooltip = ({
  message,
  showTooltip,
}: {
  message: string;
  showTooltip: boolean;
}) => {
  const messageStyle = () => (
    <Box display={"flex"} alignItems={"center"}>
      <KeyboardBackspaceIcon
        sx={{ fontSize: 20, mr: 2, strokeWidth: 1.5, stroke: "black" }}
      />
      {message}
    </Box>
  );

  return (
    <StyledTooltip title={messageStyle()} open={showTooltip} sx={{}}>
      <Box component={"div"} lineHeight="25px"></Box>
    </StyledTooltip>
  );
};

export default CustomWidthTooltip;
