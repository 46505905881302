import { createContext, useEffect, useState } from "react";
import { reactContainerName } from "../utils/Constants";

export type ReactContainerSize = {
  width: number,
  height: number
}

export const ReactContainerSizeContext = createContext<ReactContainerSize>({
  width: window.innerWidth,
  height: window.innerHeight
});

interface IReactContainerSizeProviderProps {
  children?: React.ReactNode;
}
const ReactContainerSizeProvider = (props: IReactContainerSizeProviderProps) => {
  const [size, setSize] = useState<ReactContainerSize>({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const container = document.getElementById(reactContainerName)!;
    const obs = new ResizeObserver(() => {
      setSize({
        width: container.offsetWidth,
        height: container.offsetHeight
      });
    });    
    obs.observe(container);
    const cleanup = () => {
      obs.disconnect();
    };
    return cleanup;
  }, []);
  
  return <ReactContainerSizeContext.Provider value={size}>
    {props.children}
  </ReactContainerSizeContext.Provider>
}

export default ReactContainerSizeProvider;
