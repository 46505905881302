import { CustomMessagesModel } from "../models/CustomMessages";
import { getBaseAPI } from "../environments/Environments";
import { mapSessionData, SessionModel } from "../models/Session";
import { apiFetch } from "./ServiceHelper";

export default class CustomMessagesService {
	static retrieveCustomMessages = (
		session: SessionModel
	): Promise<CustomMessagesModel> => {
		return new Promise(async (resolve, reject) => {
			const url = await getBaseAPI() + "/api/v1/Patient/getCustomMessages";
			apiFetch(url, {
				method: "POST",
				body: JSON.stringify(mapSessionData(session)),
				headers: {
					"Content-Type": "application/json",
				},
			})
				.then((result) => Promise.resolve(result))
				.then((customMessagesResponse) => {
					resolve(customMessagesResponse);
				})
				.catch((e) => {
					console.log('Custom Message error');
					reject(e);
				});
		});
	};

	static retrieveEndingMessage = (
		session: SessionModel
	): Promise<CustomMessagesModel> => {
		return new Promise( async (resolve, reject) => {
			const url = await getBaseAPI() + "/api/v1/Patient/getCustomMessages";
			apiFetch(url, {
				method: "POST",
				body: JSON.stringify(mapSessionData(session)),
				headers: {
					"Content-Type": "application/json",
				},
			})
				.then((result) => Promise.resolve(result))
				.then((customMessagesResponse) => {
					resolve(customMessagesResponse);
				})
				.catch((e) => {
					console.log('Custom Message error');
					reject(e);
				});
		});
	};

	static retrieveCoverLevelCustomMessages = (
		session: SessionModel
	): Promise<CustomMessagesModel> => {
		return new Promise(async (resolve, reject) => {
			const url = await getBaseAPI() + "/api/v1/Patient/getCoverLeveleCustomMessages";
			//const url =  "https://localhost:44330/api/v1/Patient/getCoverLeveleCustomMessages";
			apiFetch(url, {
				method: "POST",
				body: JSON.stringify(mapSessionData(session)),
				headers: {
					"Content-Type": "application/json",
				},
			})
				.then((result) => Promise.resolve(result))
				.then((customMessagesResponse) => {
					
					resolve(customMessagesResponse);
				})
				.catch((e) => {
					console.log('Custom Message error');
					reject(e);
				});
		});
	};

}
