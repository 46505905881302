// CustomAlert.tsx
import React from 'react';
import { SnackbarContent, IconButton } from '@mui/material'
import Snackbar from '@mui/material/Snackbar';

// import CloseIcon from '@material-ui/icons/Close';

const customAlertStyle = {
  error: {
    backgroundColor: '#f44336', // Red color for error
    
    top: 0,
    left: 0,
    right: 0,
    width: '100%', // Span the entire width
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    color: '#ffffff', // White color for message text
  },
  closeButton: {
    padding: '6px', // Reduced padding for close button
    color: '#ffffff', // White color for close button
  },
};

interface CustomAlertProps {
  message: string;
  onClose: () => void;
}

const CustomAlert: React.FC<CustomAlertProps> = ({ message, onClose }) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={true} // Always open for a custom alert
      onClose={onClose}
    >
      <SnackbarContent
        style={customAlertStyle.error}
        message={
          <span style={customAlertStyle.message}>
            {message}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={onClose} style={customAlertStyle.closeButton}>
            {/* <CloseIcon /> */}
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

export default CustomAlert;
