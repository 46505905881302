export default interface PatientInsuranceConfig {
    DGroupNumber: number,
    DRelToPat: number,
    DSubDOB: number,
    DSubId: number,
    DSubName: number,
    DInsCarrier: number,
    DCustSrvNum: number,
    v4DVisInsOtherIns: number,
    v4DVisInsSect: number,
    v4DMedInsCarrier: number,
    v4DMedInsCarrierSvcNum: number,
    v4DMedInsGroupNum: number,
    v4DMedInsOtherIns: number,
    v4DMedInsRel2Pat: number,
    v4DMedInsSect: number,
    v4DMedInsSubDOB: number,
    v4DMedInsSubId: number,
    v4DMedInsSubName: number,
    v4DPhoneOneIsRequired: number,
  }
  export const createSamplePatientInsuranceConfig : PatientInsuranceConfig = {
    DGroupNumber: 0,
    DRelToPat: 0,
    DSubDOB: 0,
    DSubId: 0,
    DSubName: 0,
    DInsCarrier: 0,
    DCustSrvNum: 0,
    v4DVisInsOtherIns: 0,
    v4DVisInsSect: 0,
    v4DMedInsCarrier: 0,
    v4DMedInsCarrierSvcNum: 0,
    v4DMedInsGroupNum: 0,
    v4DMedInsOtherIns: 0,
    v4DMedInsRel2Pat: 0,
    v4DMedInsSect: 0,
    v4DMedInsSubDOB: 0,
    v4DMedInsSubId: 0,
    v4DMedInsSubName: 0,
    v4DPhoneOneIsRequired: 0
  };