import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { getAppInsightsConnectionString } from '../environments/Environments';

//Code to initially configure app insights

let appInsights: ApplicationInsights | undefined = undefined;
const initAppInsights = async (): Promise<void> => {
  const connStr = await getAppInsightsConnectionString();
  
  //IF env is not equal to Prod, do not load App Insights
  if(connStr==='')
  {
    appInsights=undefined;
    return;
  }
  const insights = new ApplicationInsights({
    config: {
      connectionString: connStr,

      //By default App Insights is making many calls we are not explicitly asking for, these
      //options disable this behavior
      loggingLevelTelemetry: 0,
      disableAjaxTracking: true,
      maxAjaxCallsPerView: 0
    }
  });
  insights.loadAppInsights();
  appInsights = insights;
}
initAppInsights();

const getAppInsights = async (): Promise<ApplicationInsights | undefined> => {
  // return appInsights;
  return undefined;
}

export { getAppInsights };
