import { Availability } from "./Availability";

export const FirstAvailableKey = "FIRST_AVAILABLE";

export default interface AvailabilityGrouping {
    [key: number]: { 
        [key: string]: Availability[] 
    },
    dates: {
        [key: string]: number[]
    }, 
    providers: string[]
}