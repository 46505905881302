import { useEffect, useState } from "react";

/**
 * Returns an object from BROWSER session storage (distinct from the session variable in Redux), and
 * a setter for that object. The setter will not only set the value in session storage, but will trigger
 * a state update so React will re-render with the new data.
 */
const useSessionVariable = <T>(storageKey: string, defaultValue: T, eq: (a: T, b: T) => boolean): [T, (value: T) => void] => {
  const [value, setValue] = useState<T>(defaultValue);

  //Test the equality comparator to fail fast (prevents infinite loop)
  useEffect(() => {
    const initial = defaultValue;
    const newVal = JSON.parse(JSON.stringify(defaultValue));
    if(!eq(initial, newVal)) {
      throw new Error("Bad equality comparison in useSessionVariable");
    }
  }, [eq, defaultValue]);

  useEffect(() => {
    let deserialized: T | null = null;
    try {
      const sessionVar = sessionStorage.getItem(storageKey);
      if(sessionVar !== null) {
        deserialized = JSON.parse(sessionVar) as T;
      }
    } catch {
      //We'll just return null
    }

    //This comparison controls the setValue call, so the warning on useEffect can be ignored. We want to always
    //use whatever's in session storage if it's different from what's in state, so this effect should run on
    //every render.
    if(deserialized !== null && !eq(deserialized, value)) {
      setValue(deserialized);
    }
  });
  const setter = (value: T): void => {
    sessionStorage.setItem(storageKey, JSON.stringify(value));
    setValue(value);
  }
  return [value, setter];
}

export const clearSessionVar = (storageKey: string): void => {
  sessionStorage.removeItem(storageKey);
}

export default useSessionVariable;
