
import { hotjar } from 'react-hotjar';


export const initHotJarConfig = (initializeHotJar:boolean):void => {
//    console.log('HJ Enabled: ' + initializeHotJar);
    const SiteID=initializeHotJar===true ?  3678196:0; 
    const HJSnippetVersion=initializeHotJar===true ?  6:0; 
   
    
        hotjar.initialize(SiteID,HJSnippetVersion);

        // Identify the user
        hotjar.identify('USER_ID', { userProperty: 'value' });
    
        // Add an event
        // hotjar.event('button-click');
        // hotjar.event('error_occurred');
    
        // Update SPA state
        hotjar.stateChange('/');
    
        // Check if Hotjar has been initialized before calling its methods
        if (hotjar.initialized()) {
        hotjar.identify('USER_ID', { userProperty: 'value' });
        }
    
   
   
};
