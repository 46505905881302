import { useContext, useEffect, useState } from "react";
import { Box, Button, Typography } from '@mui/material'
import "./AppointmentConfirmation.css";
import { AppContext, AppContextType } from "../../App";
import FlagIcon from '@mui/icons-material/Flag';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import useStateNavigation from "../hooks/useStateNavigation";
import { stateRoutes } from "../../models/Page";
import { NavigatePage } from "../../utils/Util";
import { setSessionState } from "../../features/session/sessionSlice";



function AppointmentNotAvailable() {

    const state = useAppSelector(state => state)
    const startTime = state?.availability.availability.AppointmentDateTime;
    const endTime = new Date(state?.availability?.availability.AppointmentDateTime?.getTime() + 30*60000).toISOString().replace(/-/g, "").replace(/:\s*/g, "");
    const { formData, setFormData } = useContext(AppContext) as AppContextType;
    const ics = require('ics');
       const patientFirstName = state.patient.FirstName;
    const patientLastName = state.patient.LastName;
    const patientEmail = state.patient.Email;
    const homeNumber = state.patient.Home;
    const [customMessage, setCustomMessage] = useState("")
    const [locationName, setLocationName] = useState("");

    let dtFormat = new Intl.DateTimeFormat('en-US',{
        day:'2-digit',
        month:'short',
        year:'numeric',
        hour:'numeric',
        minute:'numeric'
    })

    const Navigate = useStateNavigation();
    const dispatch = useAppDispatch();
   
    const onSelectNewTime = () => {
        const updateSession = {...state.session, LastPage : true}
        dispatch(setSessionState(updateSession))
        NavigatePage(dispatch,"next",state,Navigate,stateRoutes.landingPage)
        //Navigate('/')
    }

    
   
    const onAppleCalClick = () => {
        ics.createEvent(Event, (error:any, value:any) => {
            if (error) {
                console.error(error);
            }

            const element = document.createElement('a');
            element.setAttribute('href', 'data:text/calendar;charset=utf-8,' + encodeURIComponent(value));
            element.setAttribute('download', "event.ics");

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        })
    }
  
    const handleClickBack = () => {
        Navigate(-1)
    }

    useEffect(() => {
        if (state.availability.getInitialLocation) 
            setLocationName(state.availability.getInitialLocation.Name) 
        }, [state.availability.getInitialLocation]);

    return (
        <div>
            <div className='confirmation-appointment-page'>
                <div className='wsui-header-texts'>
                    <Typography variant="h6"
                        color={"red"}
                        fontWeight={"500"}
                        sx={{
                            fontSize: "18px",
                            fontWeight: "600",
                        }} >
                        Oops! The time you initially selected is no longer available. Please click &lsquo;Select a New Time&rsquo; to choose an available appointment. 
                    </Typography>
                </div>
                <div className='wsui-box wsui-details-box'>
                    <div className='confirmation-appointment-label'>
                        <div className='wsui-mainPage wsui-inner-box wsui-details-container'>
                            <div className='wsui-header-text wsui-appointment-details-header-text'>
                                {/* <Box className="wsui-icon appointment-icon" sx={{ p: 6 }}><FlagIcon/></Box> */}
                                <p className="wsui-icon appointment-icon"><FlagIcon/></p>
                                <p className='appointment-details-header wsui-header-texts'>Appointment Details.</p>
                            </div>
                            <div>
                                <p className='confirmationetials wsui-details-heading'>Time</p>
                                <p>{dtFormat.format(startTime)} </p>
                            </div>
                            <div>
                                <p className='confirmationetials wsui-details-heading'><b>Location</b></p>
                                <p className='confirmation-detail-value'> {`${state?.availability.getInitialLocation.Address}`}</p>
                            </div>
                            <div>
                                <p className='confirmationetials wsui-details-heading wsui-head-title confirmation-height'><b>Doctor</b></p>
                                <div className='confirmation-height'>
                                    <p>Physician:  {`${state?.providers.provider.FirstName} ${state?.providers.provider.LastName}`}</p>
                                    <p>Appointment Type: {`${state?.appointmentData.appointmentType.Name}`}</p>
                                </div>
                            </div>
                            <div>
                                <p className='confirmationetials wsui-head-title confirmation-height'><b>Patient</b></p>
                                <div className='confirmation-height'>
                                    <p>Name: {state.patient.FirstName + " " + state.patient.LastName}</p>
                                    <p>Email: {state.patient.Email ?? ''}</p>
                                    <p>Phone: {state.patient.Home ?? ''}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Box className="wsui-footer-data wsui-footer-details" >
                <Button variant="contained" type="submit" sx={{ borderRadius: 10 }} onClick={onSelectNewTime}>
                    <div className='wsui-button-box'>Select a New Time</div>
                </Button>
            </Box>
        </div>
    )
}

export default AppointmentNotAvailable