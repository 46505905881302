export default interface InsuranceType {
    InsKey: number | undefined | null,
    InsName: string | undefined | null,
    LocKey: number | undefined | null,
    AcctKey: number | undefined | null,
    OrderKey: number | undefined | null
}

export interface InsuranceTypeData {
    insKey: number | undefined | null,
    insName: string | undefined | null,
    locKey: number | undefined | null,
    acctKey: number | undefined | null,
    orderKey:number | undefined | null
}

export const secretCode = 'ditac-1';

export function mapInsuranceTypeData(api: InsuranceTypeData): InsuranceType {
    return {
        InsKey: api.insKey,
        InsName: api.insName,
        LocKey: api.locKey,
        AcctKey: api.acctKey,
        OrderKey: api.orderKey
    }
}

export const createEmptyInsuranceType : InsuranceType = {
    InsKey: -1,
    InsName: '',
    LocKey: -1,
    AcctKey: -1,
    OrderKey:0
}

export const createSampleInsuranceType : InsuranceType = {
    InsKey: 249,
    InsName: 'HumanaVision Vision Care Plan (VCP)',
    LocKey: 80,
    AcctKey: 68,
    OrderKey:0
}

export const createSampleInsuranceTypes = (): InsuranceType[] => {
    const InsuranceTypes: InsuranceType[] = [];
    InsuranceTypes.push(createSampleInsuranceType);
    InsuranceTypes.push({
        InsKey: 256,
        InsName: 'VSP',
        LocKey: 80,
        AcctKey: 68,
        OrderKey:0,
    });
    InsuranceTypes.push({
        InsKey: 13,
        InsName: 'National Vision Administrators (NVA)',
        LocKey: 80,
        AcctKey: 68,
        OrderKey:0
    });
    InsuranceTypes.push({
        InsKey: 240,
        InsName: 'Avesis',
        LocKey: 80,
        AcctKey: 68,
        OrderKey:0
    });
    InsuranceTypes.push({
        InsKey: 243,
        InsName: 'Aetna',
        LocKey: 80,
        AcctKey: 68,
        OrderKey:0
    });
    return InsuranceTypes;
}