import React, { lazy, ReactNode } from "react";
import { RouteObject } from "react-router";
import AppointmentWindow, {
  IAppointmentWindowProps,
} from "../components/appointment/appointmentDrawer";
import { SelectAppointmentTime } from "../components/appointment/SelectAppointmentTime";
import { PatientDemographics } from "../components/demographics/PatientDemographics";
// project imports
import MainLayout from "../layouts/main";
import LandingPage from "../pages/LandingPage";
import VisionInsurance from "../components/appointment/VisionInsurance";
import MultipleAppointments from "../components/appointment/MultipleAppointments";
import AppointmentVisitDetails from "../components/appointment/AppointmentVisitDetails";
import CustomQuestion from "../components/customQuestion.tsx/CustomQuestion";
import AppointmentConfirmation from "../components/appointment/AppointmentConfirmation";
import  AppointmentValidation  from "../components/appointment/AppointmentValidation";
import AppointmentNotAvailable from "../components/appointment/AppointmentNotAvailable"
import { StateRoute, stateRoutes } from "../models/Page";

type StateRouteObject = {
  name: StateRoute,
  element: JSX.Element
}

export type StateRoutesGroup = {
  routes: StateRouteObject[]
}


const StateRoutes: StateRoutesGroup = {
  routes: [
    {
      name: stateRoutes.landingPage,
      element: <LandingPage newLocations={[]} />,
    },
    {
      name: stateRoutes.appointmentWindow,
      element: <AppointmentWindow />,
    },
    {
      name: stateRoutes.selectAppointmentTime,
      element: <SelectAppointmentTime />
    },
    {
      name: stateRoutes.patientDemographics,
      element: <PatientDemographics />
    },
    {
      name: stateRoutes.visionInsurance,
      element: <VisionInsurance /> 
    },
    {
      name: stateRoutes.multipleAppointment,
      element: <MultipleAppointments/>
    },
    {
      name: stateRoutes.appointmentVisitDetails,
      element: <AppointmentVisitDetails/>
    },
    {
      name: stateRoutes.appointmentConfirmation,
      element: <AppointmentConfirmation/>
    },
    {
      name: stateRoutes.customQuestion,
      element: <CustomQuestion/>
    }  , 
    {
      name: stateRoutes.appointmentNotAvailable,
      element: <AppointmentNotAvailable/>
    }  
  ],
};

export default StateRoutes;
