import React from 'react';
import "./spinner.css";

function Spinner() {
  return (
    <div>
 
    </div>
  );
};

export default Spinner;