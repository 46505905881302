export type StateRoute = 
  "landingPage" |
  "appointmentWindow" |
  "selectAppointmentTime" |
  "patientDemographics" |
  "visionInsurance" |
  "multipleAppointment" |
  "appointmentVisitDetails" |
  "appointmentConfirmation" |
  "customQuestion" |
  "appointmentNotAvailable";
export type StateRoutes = {
  landingPage: StateRoute,
  appointmentWindow: StateRoute,
  selectAppointmentTime: StateRoute,
  patientDemographics: StateRoute,
  visionInsurance: StateRoute,
  multipleAppointment: StateRoute,
  appointmentVisitDetails: StateRoute,
  appointmentConfirmation: StateRoute,
  customQuestion: StateRoute,
  appointmentNotAvailable: StateRoute
}
export const stateRoutes: StateRoutes = {
  landingPage: "landingPage",
  appointmentConfirmation: "appointmentConfirmation",
  appointmentNotAvailable: "appointmentNotAvailable",
  appointmentVisitDetails: "appointmentVisitDetails",
  appointmentWindow: "appointmentWindow",
  customQuestion: "customQuestion",
  multipleAppointment: "multipleAppointment",
  patientDemographics: "patientDemographics",
  selectAppointmentTime: "selectAppointmentTime",
  visionInsurance: "visionInsurance"
}

export default interface PageState {
  route: StateRoute,
  history: StateRoute[],
  alreadyLoadedLandingData: boolean,
  initialLocKey: string | null | undefined
}
