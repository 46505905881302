//import MulipleAppointmentSelected,  { MultipleAppointmentSelectedState} from "../models/MultipleAppointmentSelected";
export interface MultipleAppointmentsState {
    multipleAppointmentDetails : MultipleAppointmentDetails[]
  }
  
export default interface MultipleAppointmentDetails {
    LogKey: string | null | undefined;
    GroupKey: string | null | undefined;
    CoverKey: string | null | undefined;
    LocKey: string | null | undefined;
    AcctKey: string | null | undefined;
    AddKey: string | null | undefined;
    TaskKey: string | null | undefined;
    ApptKey: string;
    Status: string | null | undefined;
    AppointmentDate: string | null | undefined;
    AppointmentStartTime: string | null | undefined;
    Appointment: Date | null | undefined;
    AppointName: string | null | undefined;
    StatusOfAppt: string | null | undefined;
    PatientName: string | null | undefined;
    LocationName: string | null | undefined;
    Selected: boolean |null|null;
}

export interface MultipleAppointmentDetailsData {
    logKey: string | null | undefined;
    groupKey: string | null | undefined;
    coverKey: string | null | undefined;
    locKey: string | null | undefined;
    acctKey: string | null | undefined;
    addKey: string | null | undefined;
    taskKey: string | null | undefined;
    apptKey: string;
    status: string | null | undefined;
    appointmentDate: string | null | undefined;
    appointmentStartTime: string | null | undefined;
    appointment: Date | null | undefined;
    appointName: string | null | undefined;
    statusOfAppt: string | null | undefined;
    patientName: string | null | undefined;
    locationName: string | null | undefined;
    selected: boolean| null | null
}

export function mapMultipleAppointmentDetailsData(data: MultipleAppointmentDetailsData): MultipleAppointmentDetails {
    return {
        LogKey: data.logKey,
        GroupKey: data.groupKey,
        CoverKey: data.coverKey,
        LocKey: data.locKey,
        AcctKey: data.acctKey,
        AddKey: data.addKey,
        TaskKey: data.taskKey,
        ApptKey: data.apptKey,
        Status: data.status,
        AppointmentDate: data.appointmentDate,
        AppointmentStartTime: data.appointmentStartTime,
        Appointment: data.appointment,
        AppointName: data.appointName,
        StatusOfAppt: data.statusOfAppt,
        PatientName: data.patientName,
        LocationName: data.locationName,
        Selected: false
    }
}