/*REACT IMPORTS*/
import React from "react";
//
/* MUI Imports*/
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete";
import { InputProps } from "@mui/material/Input";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
//
/* ICONS*/
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneSharpIcon from "@mui/icons-material/PhoneSharp";
import AccessTimeSharpIcon from "@mui/icons-material/AccessTimeSharp";
//
/* MOCK DATA*/

//
/* COMPONENTS */

//

/* TYPES */
import { ITimeInfo } from "../../models";
import LocationDetails from "../../models/LocationDetails";
import { useAppSelector } from "../../app/hooks";
import ColorSelect from "../select/ColorSelect";
import { isMinimalMode } from "../../hooks/useIsMinimalOrEmbed";

interface ILocationProps {
  onLocationSelection: (data: LocationDetails) => void;
  locations: LocationDetails[];
  selectedLocation?: LocationDetails | null;
}

const gridSpacing = 2;

const toMapsUrl = (streetAddress: string): string => {
  const url = `https://www.google.com/maps/place/${encodeURIComponent(streetAddress)}`;
  return url;
}

type AddressElementProps = {
  address?: string
}
const AddressElement = ({address}: AddressElementProps) => {
  if(!address) return <></>
  return <Box 
    sx={{ ml:1 }}
  >
    <Typography>{address}</Typography>
    {address !== undefined &&
      <Link href={toMapsUrl(address)}
            target="_blank"
            rel="noopener"
            className="wsui-get-directions-link">Get directions</Link>
    }
  </Box>
}

type PhoneElementProps = {
  phoneNumberFormatted?: string
}
const PhoneElement = ({phoneNumberFormatted}: PhoneElementProps) => {
  if(!phoneNumberFormatted) return <></>
  return <Box sx={{ ml: 1 }}>
  <Link
    href={`tel: ${phoneNumberFormatted}`}
    underline="none"
    color="inherit"
  >
    <Box display={"flex"}>
      <PhoneSharpIcon />
      <Typography sx={{ ml: 1, fontWeight: "700", mb: 2 }}>
        {phoneNumberFormatted}
      </Typography>
    </Box>
  </Link>
</Box>

}

export const AddressAndPhone = () => {
  const state = useAppSelector(state => state)
  const initialLocAddress = state.availability.getInitialLocation?.Address;
  const phoneNumberFormatted = state.availability.getInitialLocation?.PhoneNumberFormated;
  return <Grid container spacing={gridSpacing}>
    <Grid item xs={12} md={12} sm={12} lg={12}>
      <AddressElement address={initialLocAddress} />
    </Grid>
    <Grid item xs={12} md={12} sm={12} lg={12}>
      <PhoneElement phoneNumberFormatted={phoneNumberFormatted} />
    </Grid>
  </Grid>
}



const NewLocation = ({
  selectedLocation,
  locations,
  onLocationSelection,
}: ILocationProps) => {
  const minimal = isMinimalMode();
  let state=useAppSelector(state => state)
  selectedLocation= locations.length == 1 ? locations[0] : state.availability.getInitialLocation
  const renderLocation = (data: LocationDetails) => {
    return (
      <>
        {data.Name && <LocationOnIcon sx={{ mr: 1 }} />}
        {data.Name}
      </>
    );
  };

  const getLocationInputProps = (
    params: AutocompleteRenderInputParams
  ): InputProps => {
    if (!state.availability.getInitialLocation) return params.InputProps;
    const obj = {
      ...params.InputProps,
      startAdornment: (
        <InputAdornment position="start">
          <LocationOnIcon
            sx={{
              m: 1,
              color: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.common.black
                  : theme.palette.common.white,
            }}
          />
        </InputAdornment>
      ),
    };
    return obj;
  };

//   const getTimeInputProps = (
//     params: AutocompleteRenderInputParams
//   ): InputProps => {
//     if (!selectedTime) return params.InputProps;
//     const obj = {
//       ...params.InputProps,
//       startAdornment: (
//         <InputAdornment position="start">
//           <AccessTimeSharpIcon
//             sx={{
//               m: 1,
//               color: (theme) =>
//                 theme.palette.mode === "light"
//                   ? theme.palette.common.black
//                   : theme.palette.common.white,
//             }}
//           />
//         </InputAdornment>
//       ),
//     };
//     return obj;
//   };


  const renderTime = (data: ITimeInfo) => {
    return (
      <>
        <AccessTimeSharpIcon sx={{ mr: 1 }} />
        {data.timeName}
      </>
    );
  };

  const validateLocationSelection = (
    option: LocationDetails,
    value: LocationDetails
  ) => {
    return (
      option.LocKey === value.LocKey &&
      option.Name === value.Name
    );
  };
  const validateTimeSelection = (option: ITimeInfo, value: ITimeInfo) => {
    return option.timeName === value.timeName && option.timeId === value.timeId;
  };

  return (
    <Grid container spacing={gridSpacing} direction={"column"}>
      {
        (state.appointmentData.appointmentType?.TaskKey || state.availability.getInitialLocation) 
        && (!minimal)
        ?  
        <Grid item xs={12} md={12} sm={12} lg={12}>
          <ColorSelect
            id="select-location"
            data={locations}
            getOptionLabel={(item) => item.Name}
            renderOptions={renderLocation}
            selected={state.availability.getInitialLocation }
            onSelection={onLocationSelection}
            size={"small"}
            placeholder={"Select Location"}
            validateOptionSelected={validateLocationSelection}
          />
        </Grid>
        :
          <Grid item xs={12} md={12} sm={12} lg={12}>
            <Box display={"flex"} sx={{ ml: 1 }}>
              {state.availability.getInitialLocation?.Name  
                ?
                <>  
                  <LocationOnIcon sx={{ mr: 1}} />
                  <Typography sx={{fontWeight: "700"}}>{state.availability.getInitialLocation?.Name}</Typography>
                </>
                :
                (state.appointmentData.appointmentType?.TaskKey || state.availability.getInitialLocation) 
                &&
                  <ColorSelect
                    id="select-location"
                    data={locations}
                    getOptionLabel={(item) => item.Name}
                    renderOptions={renderLocation}
                    selected={state.availability.getInitialLocation }
                    onSelection={onLocationSelection}
                    size={"small"}
                    placeholder={"Select Location"}
                    validateOptionSelected={validateLocationSelection}
                  />            
              }
          </Box> 
        </Grid>
      }
      <Grid item xs={12} md={12} sm={12} lg={12} sx={{
        display: { //complements the <AddressAndPhone> on landing page
          xs: "block",
          sm: "none",
          md: "none",
          lg: "block",
          xl: "block",
        }
      }}>
        <AddressAndPhone />
      </Grid>
      {/* {locationTimes?.length > 0 && (
        <Grid item xs={12} md={12} sm={12} lg={12}>
          <RegularAutocomplete
            data={locationTimes}
            getOptionLabel={(item) => item.timeName}
            renderOptions={renderTime}
            selected={selectedTime}
            onSelection={onTimeSelection}
            getInputProps={getTimeInputProps}
            size={"small"}
            placeholder={"Select"}
            validateOptionSelected={validateTimeSelection}
          />
        </Grid>
      )} */}
    </Grid>
  );
};

export default NewLocation;
