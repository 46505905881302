import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useStateLocation from "../hooks/useStateLocation";
import { reactContainerName } from "../../utils/Constants";
import { isInjectedNonIframe } from "../../hooks/useIsInjected";

// ----------------------------------------------------------------------

export default function ScrollToTop() {
  const injectedNonIframe = isInjectedNonIframe();
  const { pathname } = useLocation() ;
  const route = useStateLocation();

  useEffect(() => {
    if(injectedNonIframe) {
      const el = document.getElementById(reactContainerName);
      if(el) {
        el.scrollTo(0, 0);
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname, route, injectedNonIframe]);

  return null;
}
