import { useAppSelector } from "../../app/hooks";
import AppointmentKey from "../../models/AppointmentKey";
import useSessionVariable, { clearSessionVar } from "./useSessionVariable";

const apptConfirmedKey = "apptConfirmed";

const apptKeysEqual = (key1: AppointmentKey, key2: AppointmentKey): boolean => {
  let retVal = true;
  if(key1.acctKey !== key2.acctKey ||
     key1.locKey !== key2.locKey ||
     key1.taskKey !== key2.taskKey) {
    retVal = false;
  }
  return retVal;
}

const apptKeyArraysEqual = (keys1: AppointmentKey[], keys2: AppointmentKey[]): boolean => {
  let retVal = false;
  if(keys1.length === keys2.length) {
    retVal = true;
    for(let i = 0; i < keys1.length; i++) {
      if(!apptKeysEqual(keys1[i], keys2[i])) {
        retVal = false;
        break;
      }
    }
  }
  return retVal;
}

const useApptConfirmed = (): [boolean, () => void ] => {
  const [appts, setAppts] = useSessionVariable<AppointmentKey[]>(apptConfirmedKey, [], apptKeyArraysEqual);

  const state = useAppSelector(state => state)
  const taskKey = state.availability.availability.TaskKey;
  const acctKey = state.availability.availability.AcctKey;
  const locKey = state.availability.availability.LocKey;
  const apptKey: AppointmentKey = { acctKey: acctKey, locKey: locKey, taskKey: taskKey };

  const confirmed = appts.some(appt => apptKeysEqual(appt, apptKey));
  const addAppt = (): void => {
    if(!confirmed) {
      setAppts([...appts, apptKey]);
    }
  }
  return [confirmed, addAppt];
}

export const clearApptConfirmed = (): void => {
  clearSessionVar(apptConfirmedKey);
}

export default useApptConfirmed;
