import styles from './AppointmentOneOnlyModal.module.css';
import { AppointmentReviewPanel } from './AppointmentReviewPanel';

type AppointmentOneOnlyModalProps = {
  displayDayDateTime: string,
  displayLocation: string,
  displayPatient: string,
  onBack: () => void,
  onConfirm: () => void,
  disableButtons?: boolean
}
export const AppointmentOneOnlyModal = ({ displayDayDateTime, displayLocation, displayPatient, onBack, onConfirm, disableButtons }: AppointmentOneOnlyModalProps) => {
  return <div className={styles.root}>
    <div className={styles.panel}>
      <AppointmentReviewPanel
        displayDayDateTime={displayDayDateTime}
        displayLocation={displayLocation}
        displayPatient={displayPatient}
        mode="modal"
        headerText={<>
          Please review all of the details of your upcoming scheduled appointment below:
          <span className={styles.bold}> Any other existing appointments will be removed.</span>
        </>}
      />
      <div className={styles.buttonRow}>
        <button disabled={disableButtons} onClick={onBack} type="button" className={styles.goBackButton}><Chevron /> Go Back</button>
        <button disabled={disableButtons} onClick={onConfirm} type="button" className={styles.confirmButton}> Confirm & Schedule</button>
      </div>
    </div>
  </div>
}

const Chevron = () => {
  return <svg viewBox="0 0 24 24">
    <path d="M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
  </svg>
}

