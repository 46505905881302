import PatientInsuranceConfig, { createSamplePatientInsuranceConfig } from './PatientInsuranceConfig';
import { SessionModel } from './Session';

export interface PatientInsuranceState {
  patientInsurance :PatientInsurance,
   
}


export default interface PatientInsurance {
    CoverKey: string | undefined | null,
    AddKey: string | undefined | null,
    VisionInsuranceCarrier: string | undefined | null,
    VisionInsuranceOthersName: string | undefined | null,
    VisionInsuranceCarrierServiceNum: string | undefined | null,
    VisionInsuranceGroupNumber: string | undefined | null,
    VisionInsuranceRelationToPatient: string | undefined | null,
    VisionInsuranceSubscriberDOB: string | undefined | null,
    VisionInsuranceSubscriberID: string | undefined | null,
    VisionInsuranceSubscriberName: string | undefined | null,
    MedicalInsuranceCarrier: string | undefined | null,
    MedicalInsuranceOthersName: string | undefined | null,
    MedicalInsuranceCarrierServiceNum: string | undefined | null,
    MedicalInsuranceGroupNumber: string | undefined | null,
    MedicalInsuranceRelationToPatient: string | undefined | null,
    MedicalInsuranceSubscriberDOB: string | undefined | null,
    MedicalInsuranceSubscriberID: string | undefined | null,
    MedicalInsuranceSubscriberName: string | undefined | null,
    Config: PatientInsuranceConfig,
    IsValid: boolean | undefined | null
}

export const mapPatientInsuranceFromApi = (data: any): PatientInsurance => {
    let visionInsConfig;
    let medicalInsConfig;

    if(data?.config?.v4DVisInsSect == 1){
      visionInsConfig = {
        DGroupNumber: data.config.dGroupNum,
        DRelToPat: data.config.dRelToPat,
        DSubDOB: data.config.dSubDOB,
        DSubId: data.config.dSubId,
        DSubName: data.config.dSubName,
        DInsCarrier: data.config.dinsCarrier,
        DCustSrvNum: data.config.dCustSrvNum,
        v4DVisInsOtherIns: data.config.v4DVisInsOtherIns,
        v4DVisInsSect: data.config.v4DVisInsSect,
      }
    }
    else{
      visionInsConfig = {
        DGroupNumber: -1,
        DRelToPat: -1,
        DSubDOB: -1,
        DSubId: -1,
        DSubName: -1,
        DInsCarrier: -1,
        DCustSrvNum: -1,
        v4DVisInsOtherIns: -1,
        v4DVisInsSect: -1,
      }
    }

    if(data?.config?.v4DMedInsSect == 1){
      medicalInsConfig = {
        v4DMedInsCarrier: data.config.v4DMedInsCarrier,
        v4DMedInsCarrierSvcNum: data.config.v4DMedInsCarrierSvcNum,
        v4DMedInsGroupNum: data.config.v4DMedInsGroupNum,
        v4DMedInsOtherIns: data.config.v4DMedInsOtherIns,
        v4DMedInsRel2Pat: data.config.v4DMedInsRel2Pat,
        v4DMedInsSect: data.config.v4DMedInsSect,
        v4DMedInsSubDOB: data.config.v4DMedInsSubDOB,
        v4DMedInsSubId: data.config.v4DMedInsSubId,
        v4DMedInsSubName: data.config.v4DMedInsSubName,
      }
    }
    else{
      medicalInsConfig = {
        v4DMedInsCarrier: -1,
        v4DMedInsCarrierSvcNum: -1,
        v4DMedInsGroupNum: -1,
        v4DMedInsOtherIns: -1,
        v4DMedInsRel2Pat: -1,
        v4DMedInsSect: -1,
        v4DMedInsSubDOB: -1,
        v4DMedInsSubId: -1,
        v4DMedInsSubName: -1,
      }
    }  
    
    return {
        CoverKey: data.coverKey,
        AddKey: data.addKey,
        VisionInsuranceCarrier: data.vision_Insurance_Carrier,
        VisionInsuranceOthersName: data.vision_Insurance_OthersName,
        VisionInsuranceCarrierServiceNum: data.vision_Insurance_CarrierServiceNum,
        VisionInsuranceGroupNumber: data.vision_Insurance_GroupNumber,
        VisionInsuranceRelationToPatient: data.vision_Insurance_RelationToPatient,
        VisionInsuranceSubscriberDOB: data.vision_Insurance_SubscriberDOB,
        VisionInsuranceSubscriberID: data.vision_Insurance_SubscriberID,
        VisionInsuranceSubscriberName: data.vision_Insurance_SubscriberName,
        MedicalInsuranceCarrier: data.medical_Insurance_Carrier,
        MedicalInsuranceOthersName: data.medical_Insurance_OthersName,
        MedicalInsuranceCarrierServiceNum: data.medical_Insurance_CarrierServiceNum,
        MedicalInsuranceGroupNumber: data.medical_Insurance_GroupNumber,
        MedicalInsuranceRelationToPatient: data.medical_Insurance_RelationToPatient,
        MedicalInsuranceSubscriberDOB: data.medical_Insurance_SubscriberDOB,
        MedicalInsuranceSubscriberID: data.medical_Insurance_SubscriberID,
        MedicalInsuranceSubscriberName: data.medical_Insurance_SubscriberName,
        Config: {
          ...visionInsConfig,
          ...medicalInsConfig,
          v4DPhoneOneIsRequired: data.config.v4DphoneOneIsRequired,
        },
        IsValid: false
    };
}

export const createSamplePatientInsurance : PatientInsurance = {
  CoverKey: "",
  AddKey: "",
  VisionInsuranceCarrier: "",
  VisionInsuranceOthersName: "", 
  VisionInsuranceCarrierServiceNum: "",
  VisionInsuranceGroupNumber: "",
  VisionInsuranceRelationToPatient: "",
  VisionInsuranceSubscriberDOB: "",
  VisionInsuranceSubscriberID: "",
  VisionInsuranceSubscriberName: "",
  MedicalInsuranceCarrier: "",
  MedicalInsuranceOthersName: "", 
  MedicalInsuranceCarrierServiceNum: "",
  MedicalInsuranceGroupNumber: "",
  MedicalInsuranceRelationToPatient: "",
  MedicalInsuranceSubscriberDOB: "",
  MedicalInsuranceSubscriberID: "",
  MedicalInsuranceSubscriberName: "",
  Config: createSamplePatientInsuranceConfig,
  IsValid: false
}

export const mapPatientInsuranceToApi = (data: PatientInsurance, session: SessionModel): any => {
    const apiData = {
        "logKey": session.LogKey ?? '',
        "coverKey": session.CoverKey ?? '',
        "groupKey": session.GroupKey ?? '',
        "locKey": session.LocKey ?? '',
        "acctKey": session.AcctKey ?? '',
        "addKey": session.AddKey ?? '',
        "taskKey": session.TaskKey ?? '',
        "apptKey": session.ApptKey ?? '',
        "currentPage": session.CurrentPage ?? 0,
        "data": {
          "vision_Insurance_Carrier": data.VisionInsuranceCarrier,
          "vision_Insurance_OthersName": data.VisionInsuranceOthersName,
          "vision_Insurance_CarrierServiceNum": data.VisionInsuranceCarrierServiceNum,
          "vision_Insurance_GroupNumber": data.VisionInsuranceGroupNumber,
          "vision_Insurance_RelationToPatient": data.VisionInsuranceRelationToPatient,
          "vision_Insurance_SubscriberDOB": data.VisionInsuranceSubscriberDOB,
          "vision_Insurance_SubscriberID": data.VisionInsuranceSubscriberID,
          "vision_Insurance_SubscriberName": data.VisionInsuranceSubscriberName,
          "medical_Insurance_Carrier": data.MedicalInsuranceCarrier,
          "medical_Insurance_OthersName": data.MedicalInsuranceOthersName,
          "medical_Insurance_CarrierServiceNum": data.MedicalInsuranceCarrierServiceNum,
          "medical_Insurance_GroupNumber": data.MedicalInsuranceGroupNumber,
          "medical_Insurance_RelationToPatient": data.MedicalInsuranceRelationToPatient,
          "medical_Insurance_SubscriberDOB": data.MedicalInsuranceSubscriberDOB,
          "medical_Insurance_SubscriberID": data.MedicalInsuranceSubscriberID,
          "medical_Insurance_SubscriberName": data.MedicalInsuranceSubscriberName,
        }
    };
    return apiData;
}