import React, { useContext,useCallback, useEffect, useState } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Box, useMediaQuery,Typography,  Autocomplete, TextField, AutocompleteRenderInputParams, InputProps, InputAdornment  } from "@mui/material";
import Header from "../components/provider/Header";
import { AppContext, AppContextType } from "../App";
import {
  IProviderWithAppointment,
  IAppointmentType,
  SelectedAppointmentId,
} from "../models";
import CustomWidthTooltip from "../components/tooltips/CustomTooltip";
import { onSelectProvider } from "../features/provider/providerSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import AppointmentWindow, { AppointmentDrawer, AppointmentInjectedWindow } from "../components/appointment/appointmentDrawer";
import { onAppointmentTypesChange, setLastPage, setSessionState } from "../features/session/sessionSlice";
import AppointmentType from "../models/AppointmentType";
import { setAppointmentType } from "../features/appointmentData/appointmentSlice";
import { setHashforState } from "../features/hasher/hasherSlice";
import LocationDetails from "../models/LocationDetails";
import NewProviderWithAppointment from "../components/provider/ProviderWithAppointment/NewProviderWithAppointment";
import { clearProviderData, getAvailabililties, getlocationsback } from "../features/availability/availabilitySlice";
import SimpleMap from "../components/map/SimpleMap";
import { AvailabilityService } from "../services/AvailabilityService";
import { saveSession } from '../services/SessionService'
import Spinner from "../components/spinner/Spinner";
import { SessionService } from "../services/SessionService";
import { NavigatePage, PageName } from "../utils/Util";
//import Select from "../components/select/Select";
import NewLocation, { AddressAndPhone } from "../components/location/NewLocation";

import InitServices, { GetAppointmentInformation, GetCoverKeyInformation, UpdateEmbededState } from "../services/InitServices";
import { Event, PageView } from '../utils/GA';
import useStateNavigation from "../components/hooks/useStateNavigation";
import { stateRoutes } from "../models/Page";
import ColorSelect from "../components/select/ColorSelect";
import { isInjected, isInjectedIframe, isInjectedNonIframe } from "../hooks/useIsInjected";
import Providers from "../models/Provider";
import Link from "@mui/material/Link";
import { setProviderWithAppointment } from "../features/provider/newProviderSlice";
import "./LandingPage.css";
import CustomMessagesService from "../services/CustomMessagesService";
import DOMPurify from "dompurify";
import { useLocation } from 'react-router-dom';

interface ILandingPageProps {
  newLocations: LocationDetails[]
}

const LandingPage = ({newLocations}: ILandingPageProps) => {
  const navigate = useStateNavigation();
  const [pageLoading, setPageLoading] = useState(false);
  const gridSpacing = 2;
  const { formData, setFormData } = useContext(AppContext) as AppContextType
  const [selectedAppointment, setSelectedAppointment] = useState<SelectedAppointmentId |null>(null)
  const dispatch = useAppDispatch();
  const state = useAppSelector(stat => stat)
    useState<IAppointmentType | null>(null);
  const [providerWithAppointmentsData, setproviderWithAppointmentsData] =
    useState<IProviderWithAppointment[]>([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const [selectedNewLocation, setSelectedNewLocation] = useState<LocationDetails | null>(null)
  const [hasSchedules, setHasSchedules] = useState(true);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>([]);
  const [isDisableButton,setIsDisableButton]=useState(false);
  const [datePaginationList, setDatePaginationList] = useState<any>([]);
  const [isItemClicked,setIsItemClicked]=useState(false);
  const [isTaskKeyAvail, SetTakeyAvail] = useState(false);
  const [isLocKeyAvail, SetLocKeyAvail] = useState(false);
  
  const [selectLength, setSelectLength] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [isAppointmentSelected, SetIsAppointmentSelected] = useState(false);
  const [isLocationSelected, SetIsLocationSelected] = useState(false);
  const [customMessage, setCustomMessage] = useState("");
  const [coverLevelCustomMessage, setCoverLevelCustomMessage] = useState("");
  var tmpCM :  any[] = [];
const [longMessage, setLongMessage] = useState(false);
const [useCached, setUseCached] = useState<number | null>(null); 
const location = useLocation();
//  const totalLength = state.availability.availabilities?.dates?.FIRST_AVAILABLE?.length || 0


  const initFormData = {
    ...formData,
    firstName: '',
    lastName: '',
    pNumber: '',
    email: '',
    day: '',
    month: '',
    year: '',
    address1: '',
    address2: '',
    city:'',
    state:'',
    zip: '',
    country:'',
    mobilePhone:'',
    referredBy:'',
    displayVisionInsurance: '',
    displayMedicalInsurance: '',
    visionInsuranceCarrier: '',
    visionInsuranceOthersName: '',  
    visionInsuranceCarrierServiceNum: '',
    visionInsuranceGroupNumber: '',
    visionInsuranceRelationToPatient: '',
    visionInsuranceSubscriberDOB: '',
    visionInsuranceSubscriberID: '',
    visionInsuranceSubscriberName: '',
    visionDay: '',
    visionMonth: '',
    visionYear: '',
    medicalInsuranceCarrier: '',
    medicalInsuranceOthersName: '', 
    medicalInsuranceCarrierServiceNum: '',
    medicalInsuranceGroupNumber: '',
    medicalInsuranceRelationToPatient: '',
    medicalInsuranceSubscriberDOB: '',
    medicalInsuranceSubscriberID: '',
    medicalInsuranceSubscriberName: '',
    medicalDay: '',
    medicalMonth: '',
    medicalYear: '',
  }  


  // -------------------- Hooks

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const useCacheParam = params.get('useCached');
    if (useCacheParam !== null) {
      setUseCached(useCacheParam === 'true' ? 1 : 0); 
    } else {
      setUseCached(null); 
    }
  }, [location.search]);

  useEffect(()  => {
        // dispatch(setSessionState({...state.session,CurrentPage :  PageName.LandingPage}))   
      if (state.appointmentData.appointmentTypesDistinct.length === 0)
      {
        //initData()
        setPageLoading(true)
        setHasSchedules(false)
        const fetchData = async () => {
          const data =  await  InitServices(state.session, dispatch, state)
          .then((response: boolean) => {
            var ret = response
            setIsDataLoaded(response)
            // setPageLoading(false)
          })      
        }  
        fetchData()     
      }
      else if (state.session.LastPage)
      {
        setPageLoading(true)
        formData.year = "" 
        setFormData(initFormData);    

        //let InitState : any = {...state.session, AcctKey: '0'}
        //var sState = state
        //var prevStateSession = state.session
        dispatch(setSessionState(null))
        SessionService(
          "",
          String(state?.session?.CoverKey ?? ""),
          "",
          state,
          dispatch
        ).then((session) => {
          dispatch(setSessionState(session))        
          dispatch(clearProviderData())  
          let updatedState: any = state   
          updatedState.session = {...state.session, LogKey: session.LogKey, AcctKey: null, AddKey : null }
          var locKey : any = state.session.LocKey 
          AvailabilityService(updatedState, locKey, useCached).then((res)=> {
            dispatch(setProviderWithAppointment([])) 
            setIsDisableButton(false);
            dispatch(getAvailabililties(res.data[0]))
            dispatch(setHashforState(res.hash))

            generatePagination(res.data[0])

            setPageLoading(false)

            // reset LastPage
            const updateSession = {...state.session, LastPage : false, locKey : null, AcctKey: null, CurrentPage :  PageName.LandingPage}
            dispatch(setSessionState(updateSession))
      
        }).catch((ex) =>
        {
          setPageLoading(false)
        })      
      })

      }
      else
      {

        if (state.providers.providersDistinct.length > 0 && !pageLoading) 
        {
            setHasSchedules(true)
            generatePagination(state.availability.availabilities)
        }
        setPageLoading(false)     
      }

    }, []);
    
    useEffect(() => {
      PageView(
        `pageview_landing_page_screen`, state.session.CoverKey?.toString() ,state.session.LocKey?.toString(), state.session.AcctKey?.toString()
    )
   }, []);


   const initialMessages = [
    { AcctKey: '1#', Message: '' },

 
  ];
   const [tmpCustom,setTmpCustom]=useState(initialMessages);
   const getCustomMessages = async () => {
    let customMessage = "";
    await setTmpCustom([]);
    
    state.providers.providersWithAppointment.map(async (providerInfo: any, index) => {
      var provider = providerInfo.provider;
   

      if (state.provider && state.provider !== null) {
        const response = await CustomMessagesService.retrieveCustomMessages({
          ...state.session,
          AcctKey: provider.AcctKey,
        });
    
      
          customMessage = response.messages
           .filter((x) => x.v4UnderPic?.trim())
          .map((x) => x.v4UnderPic?.trim())
          .join("<br />");
  
        setCustomMessage(customMessage);
        var arrCMessage={AcctKey:provider.AcctKey,Message:customMessage}
        setTmpCustom(tmpCustom => [...tmpCustom, arrCMessage]);
        
        if (customMessage.length > 150){
          setLongMessage(true);
        }
      }
      else {
        setCustomMessage(customMessage);
        var arrCMessage={AcctKey:provider.AcctKey,Message:customMessage}
        tmpCM.push(arrCMessage);
        
        setTmpCustom(tmpCustom => [...tmpCustom, arrCMessage]);
      }
    });
    
   
  };
  
 

   useEffect(() => {
        // dispatch(setProviderWithAppointment([])) 
          var data = state.availability.availabilities
          // Sort by date, Provider, Remove provider that has no schedule
          if (data.dates && datePaginationList.length > 0)
          {
            var tmpProvider : {firstDate : number ,provider :  Providers, hasSchedules : boolean, nextScheduleAppt : Date | null}[] = []
           
           
            var dateKeys: string[]  = [];

            if (state.embed.ProviderAgnostic === 1)
            {
              dateKeys = Object.keys(data.dates); // Provider Keys
              dateKeys = dateKeys.filter(p =>  p === "FIRST_AVAILABLE")
            }
            else
            {
              dateKeys = Object.keys(data.dates); 
            }

            dateKeys.forEach((key: any) => {
              var providerSchedule = data.dates[key]
              var pageNum = selectLength > 0 ? selectLength / 20 : 0
              let sdate =  new Date(datePaginationList[pageNum]);
              var pageStartDate = sdate
              // Display only first 20 days of the calendar
              var foundTime = false
              for (let i = 0; i < 21; i++) {  
                var epochtime : any =  sdate.getTime()
                
                // Check if date found in the data array
                // Sample Format of data
                // 1694016000000 : <-- Epoch Time
                //  {13511 : [{Array of Provider Schedules }]},
                //  {944 : [{Array of Provider Schedules }]},
                var dte = data[epochtime]
                // If date was found  
                if (dte)
                {
                  // Check if the Provider} is part of that date
                  if (dte[key])
                  {
                     // This means that the Provider has a schedule with the current date (epochtime)
                     // We only get the first date of the Provider
                     foundTime = true
                     break
                  }
                }
      
                // Increment date until it reaches a maximum of 20 days
                sdate.setDate(sdate.getDate() + 1); 
              }
      
              //if (foundTime)
              //{
                var selectedProvider =  state.providers.providersDistinct.filter(p => Number(p.AcctKey) === Number(key))
                if (selectedProvider.length > 0 || (state.embed.ProviderAgnostic && key === 'FIRST_AVAILABLE'))
                {
                    var foundFirstApptTime = false
                    if (!foundTime) // Search the nearest available date
                    {
                      for (let i = pageNum+1; i < datePaginationList.length; i++) {  
                        
                        var pageStartDate = new Date(datePaginationList[i])
                        // Display only first 20 days of the calendar
                         foundFirstApptTime = false
                        for (let j = 0; j < 21; j++) {  
                          var epochFirstTime : any =  pageStartDate.getTime()
                          
                          // Check if date found in the data array
                          // Sample Format of data
                          // 1694016000000 : <-- Epoch Time
                          //  {13511 : [{Array of Provider Schedules }]},
                          //  {944 : [{Array of Provider Schedules }]},
                          var dte = data[epochFirstTime]
                          // If date was found  
                          if (dte)
                          {
                            // Check if the Provider} is part of that date
                            if (dte[key])
                            {
                               // This means that the Provider has a schedule with the current date (epochtime)
                               // We only get the first date of the Provider
                               foundFirstApptTime = true
                               break
                            }
                          }
                
                          // Increment date until it reaches a maximum of 20 days
                          pageStartDate.setDate(pageStartDate.getDate() + 1); 
                        }
                        if (foundFirstApptTime) break;
                      }
                    }

                    var nextDate  = (!foundTime && foundFirstApptTime === true) ? new Date(epochFirstTime) : null

                   if (state.embed.ProviderAgnostic === 1)
                    {
                      var provider ={...state.providers.providersDistinct[0]}
                     // provider = {...state.providers.providersDistinct[0], AcctKey : "FIRST_AVAILABLE"}
                     if (!data.dates[provider.AcctKey]) {
                      provider.AcctKey = "FIRST_AVAILABLE";
                     }
                      selectedProvider.push(provider)
                    } 
                    
                    var object : any  = {firstDate : epochtime ,provider :  selectedProvider[0], hasSchedules : foundTime , nextScheduleAppt : nextDate}
                    tmpProvider.push(object)
                }
              //}
            })
           
            var tmpProviderFinalList :  any[] = []
            if (tmpProvider.length > 0)
            {
              const keyValueArray  = Object.entries( tmpProvider);
              var a = keyValueArray[0][1].firstDate

              if (selectLength === 0) // Sort on first page only
              {
                // Sort for the first date found
                keyValueArray.sort((a: any, b: any) => Number(a[1].firstDate) - Number(b[1].firstDate));
                
                // Get all Provider sorted by first date found
                keyValueArray.forEach((key : any) => {
                  var prov = {hasSchedule : key[1].hasSchedules ,  provider : key[1].provider , nextScheduleAppt : key[1].nextScheduleAppt}
                  tmpProviderFinalList.push(prov)
                })
             }
             else
             {
                state.providers.providersWithAppointment.map((provider) => {
                  var providerList : any = tmpProvider.find(p => p.provider.AcctKey === provider.provider.AcctKey)
                  var prov : any = {hasSchedule : providerList?.hasSchedules  ,  provider : provider.provider, nextScheduleAppt : providerList?.nextScheduleAppt}
                  tmpProviderFinalList.push(prov)
                })
             }
            }
        
        if(tmpProviderFinalList.length){
         setTimeout(()=>{
          dispatch(setProviderWithAppointment(tmpProviderFinalList));

         },1000)
        }
          
        }
  },  [selectLength, datePaginationList]);   

useEffect(()=>{
 getCustomMessages();
},[state.providers.providersWithAppointment]);

  useEffect(() => {

    (async () => {

        if (isDataLoaded)
        {
        var isLocKeySuccess = false
        if (state.embed.LocKey && state.appointmentData.appointmentTypesLocation.length > 0)
        {
          var location = state.appointmentData.appointmentTypesLocation
          var filterData = location.filter(p => Number(p.LocKey) === Number(state.embed.LocKey))
          if (filterData.length > 0)
          {

            dispatch( getlocationsback(filterData[0]))   
            setSelectedNewLocation(filterData[0])  
            isLocKeySuccess = true 
            SetLocKeyAvail(true)
          }   
        }
        var isTaskKeySuccess = false
        if (state.embed.TaskKey)
        {
          var param = {
            "name": "",
            "taskKey": state.embed.TaskKey,
            "locKey": 0,
            "acctKey": 0
          }
           var appointmentName  = await GetAppointmentInformation(param)
          if (appointmentName.name !== undefined) {
            // Get the name of the Appointment and not the ID
            if (appointmentName.name.length > 0) {
              var filterApptData = state.appointmentData.appointmentTypesDistinct.filter(p => p.Name === appointmentName.name)
              dispatch(setAppointmentType(filterApptData[0]))
              isTaskKeySuccess = true
              SetTakeyAvail(true)
            }
          }
          else {
            SetTakeyAvail(false)
          } 
    
        } 
        
        
        if (isLocKeySuccess && isTaskKeySuccess)
        {
          // setPageLoading(true)
          setHasSchedules(false) 
          const logKey:any = state.session?.LocKey
          AvailabilityService(state, logKey, useCached).then((res)=> {
            dispatch(setProviderWithAppointment([])) 
            setIsDisableButton(false);
            if (res.data[0].providers.length > 0) 
              setHasSchedules(true) 
            dispatch(getAvailabililties(res.data[0]))
            dispatch(setHashforState(res.hash))

            generatePagination(res.data[0])
            SetIsAppointmentSelected(true) 
            setPageLoading(false)
          }).catch(() => {
            setPageLoading(false)
          })
        }
        else
        setPageLoading(false)
    }
    if(state.appointmentData.appointmentTypesDistinct.length === 1)
    {
      onAppointmentTypeChange(state.appointmentData.appointmentTypesDistinct[0]);
    }
    })();
    var sessionWithAcctKey = {...state.session, AcctKey : selectedAppointment?.providerId}
    UpdateEmbededState(sessionWithAcctKey,dispatch)

   }, [isDataLoaded]);


useEffect(() => {

  if (state.embed.AcctKey !== selectedAppointment?.providerId && selectedAppointment)
  {
    var sessionWithAcctKey = {...state.session, AcctKey : selectedAppointment?.providerId}
    UpdateEmbededState(sessionWithAcctKey,dispatch)
  }
},[selectedAppointment])

useEffect(() => {
  var location = state.appointmentData.appointmentTypesLocation
  var filterData = location.filter(p => Number(p.LocKey) === Number(state.session.LocKey))
  if (filterData.length > 0)
  {
   onNewLocationSelection(filterData[0]);
  }
  
},[])

useEffect(() => {

      if (state.appointmentData.appointmentTypesLocation.length === 1 && state.appointmentData.appointmentType?.AcctKey)
      {
          dispatch( getlocationsback(state.appointmentData.appointmentTypesLocation[0]))
          setSelectedNewLocation(state.appointmentData.appointmentTypesLocation[0])


          const updateSession = {...state.session, LocKey: state.appointmentData.appointmentTypesLocation[0].LocKey}
          setPageLoading(true)
          setHasSchedules(false) 
          var locKey : any = state.appointmentData.appointmentTypesLocation[0].LocKey
          var availState = state
              availState.session = {...updateSession, AcctKey : null}
          AvailabilityService(availState, locKey, useCached).then( async (res)=> {
                dispatch(setProviderWithAppointment([])) 
                setIsDisableButton(false);
                if (res.data[0].providers.length > 0) 
                  setHasSchedules(true) 
                dispatch(getAvailabililties(res.data[0]))
                dispatch(setHashforState(res.hash))

                var coverName = "";
                var coverAlias = "";
                var mobileLogo =   "";
                var desktopLogo =  "";   
                var frontLine =  "";       
                [coverName, coverAlias, mobileLogo, desktopLogo, frontLine] = await GetCoverKeyInformation(locKey, state.session.CoverKey)  
                var currentSession = {...state.session, LocKey : locKey,   OrgName : coverName , DisplayOrgAlias : coverAlias , MobileLogo : mobileLogo , DesktopLogo : desktopLogo, FrontLine: frontLine}
                dispatch(setSessionState(currentSession))
                
                generatePagination(res.data[0])
                setPageLoading(false)      
                SetIsAppointmentSelected(true) 
                // reset LastPage
                dispatch(setLastPage(false));
          }).catch((ex) =>
          {
            setPageLoading(false)
          }) 
      }
      else if (state.appointmentData.appointmentTypesLocation.length === 1 && state.appointmentData.appointmentType?.AcctKey === 0) {
        SetIsAppointmentSelected(true) 
      }
      else if (state.appointmentData.appointmentTypesLocation.length === 1 && state.session.TaskKey) {
        onNewLocationSelection(state.appointmentData.appointmentTypesLocation[0]);
      }

      if (state.appointmentData.appointmentTypesLocation.length === 0 && state.appointmentData.appointmentType?.AcctKey)
      {
        setHasSchedules(false)
        dispatch( getlocationsback(null))
        setSelectedNewLocation(null)
      }

},[state.appointmentData.appointmentTypesLocation])


useEffect(()  => {

const getCoverLevelCustomMessages = async () => {
let coverLevelCustomMessage = "";

  const response = await CustomMessagesService.retrieveCoverLevelCustomMessages({
    ...state.session,
    AcctKey: state?.providers.provider.AcctKey,
  });

  coverLevelCustomMessage = response.coverCustomMessage ?? ''
              setCoverLevelCustomMessage(coverLevelCustomMessage);
};  
getCoverLevelCustomMessages();    
  
}, []);

// ----------------- Hooks

const RenderPhoneNumber = (phoneNumberFormated:string | null | undefined): JSX.Element => {
  return (  
      <Link
        href={`tel: ${phoneNumberFormated}`}
        underline="none"
        color="inherit"
      > {phoneNumberFormated}</Link> 
  );
};

  const generatePagination = (data : any) =>
  {
    if (data.providers.length > 0) 
    {
      var dateKeys = Object.keys(data);
      var firstTimeStamp = dateKeys[2]
      var lastTimeStamp = dateKeys[dateKeys.length - 1];
      var startDate = new Date(Number(firstTimeStamp));
      var endDate = new Date(Number(lastTimeStamp));

      // get the first day of the week      
      var firstDayofWeek = startDate.getDate() - startDate.getDay() 
      startDate.setDate(firstDayofWeek);
      
      var dateList: any =  []
      while (startDate < endDate)
      {
          var currentDate = new Date(startDate)
          dateList.push(currentDate)
          startDate.setDate(startDate.getDate()  + 21);   
      }
      setDatePaginationList(dateList)
      var totalLengthPage = dateList.length * 20;
      setTotalLength(totalLengthPage)
      setSelectLength(0)
    }

  }

  const onNewLocationSelection = async (data: LocationDetails | null)=> {
    SetIsLocationSelected(true)
    setHasSchedules(false)
    setPageLoading(true)
    setSelectedNewLocation(data)
    dispatch( getlocationsback(data))
    const updateSession = {...state.session, LocKey: data?.LocKey}

    saveSession(updateSession).then(() => {
      dispatch(setSessionState(updateSession))
    })


    if (data) {
      const locKey:any =data?.LocKey
      var availState = state
          availState.session = {...updateSession, AcctKey : null}
      AvailabilityService(availState, locKey, useCached).then( async (res)=> {
        dispatch(setProviderWithAppointment([])) 
        setIsDisableButton(false);
        if (res.data[0].providers.length > 0) 
           setHasSchedules(true) 
        dispatch(getAvailabililties(res.data[0]))
        dispatch(setHashforState(res.hash))

          var coverName = "";
          var coverAlias = "";
          var mobileLogo =   "";
          var desktopLogo =  "";
          var frontLine =  "";  
         
          [coverName, coverAlias, mobileLogo, desktopLogo, frontLine] = await GetCoverKeyInformation(locKey, state.session.CoverKey)  
          var currentSession = {...state.session, LocKey : locKey,   OrgName : coverName , DisplayOrgAlias: coverAlias , MobileLogo : mobileLogo , DesktopLogo : desktopLogo, FrontLine: frontLine}   
          dispatch(setSessionState(currentSession))
          
        generatePagination(res.data[0])
        setPageLoading(false)
      }).catch(() => {
        setPageLoading(false)
      })

/*       if (locKey > 0 && state.session.LocKey !== locKey)
      {
        var coverName = "";
		    var mobileLogo =   "";
		    var desktopLogo =  "";         
        [coverName, mobileLogo, desktopLogo] = await GetCoverKeyInformation(locKey, state.session.CoverKey)  
        var currentSession = {...state.session,  OrgName : coverName , MobileLogo : mobileLogo , DesktopLogo : desktopLogo}   
        dispatch(setSessionState(currentSession))
      } */

    }
            // GA-02
            Event(
              false,
              'click_provider_location_dropdown',
              state.session.CoverKey?.toString() ,state.session.LocKey?.toString(), state.session.AcctKey?.toString()
          )
          
  }


  const onCloseAppointment = () => {
    setOpen(false);
  };



  const onAppointmentTypeChange = async (data : AppointmentType) => {
    // setAppointmentTypes(data);  
    setSelectedNewLocation(null); 
    SetIsAppointmentSelected(true)
    const updateSession = {...state.session, TaskKey: data?.TaskKey?.toString()}
    saveSession(updateSession).then(() => {
      dispatch(setSessionState(updateSession))
    })        
    dispatch(setAppointmentType(data))
    dispatch(onAppointmentTypesChange(data)) // throwing 500 DB error
    dispatch(clearProviderData())
    
    // GA-01
    Event(
      false,
      "click_examtype_dropdown_examtype_screen",
      state.session.CoverKey?.toString() ,state.session.LocKey?.toString(), state.session.AcctKey?.toString()
  )

    if (!selectedNewLocation) return
    
    if (!state.embed.LocKey)
    {
       dispatch( getlocationsback(null))
       setSelectedNewLocation(null)
    }
    else
    {
      var location = state.appointmentData.appointmentTypesLocation
      var filterData = location.filter(p => Number(p.LocKey) === Number(state.embed.LocKey))
      
      if (filterData.length > 0)
        {

          dispatch( getlocationsback(filterData[0]))   
          setSelectedNewLocation(filterData[0])  
        }   
     
      setPageLoading(true)
      setHasSchedules(false) 
      var locKey : any = state.session.LocKey 
      var availState = state
          availState.session = {...updateSession, AcctKey : null}
      AvailabilityService(availState, locKey, useCached).then((res)=> {
         dispatch(setProviderWithAppointment([])) 
          setIsDisableButton(false);
          if (res.data[0].providers.length > 0) 
             setHasSchedules(true) 
          dispatch(getAvailabililties(res.data[0]))
          dispatch(setHashforState(res.hash))

          generatePagination(res.data[0])
          setPageLoading(false)      
          // reset LastPage
          dispatch(setLastPage(false));
      }).catch((ex) =>
      {
        setPageLoading(false)
      }) 
    }

   
  };

  // const onIdle = (m: google.maps.Map) => {
  //   setZoom(m.getZoom()!);
  //   setCenter(m.getCenter()!.toJSON());
  // };
  const onAppointmentClick = useCallback(
    //  old function
    (id: number, providerId: string) => {
      const data =
        providerWithAppointmentsData.find(
          (x) => x.provider.id === providerId
        ) ?? null;
      dispatch(onSelectProvider(data));
      // setSelectedProvider(
      //   providerWithAppointmentsData.find(
      //     (x) => x.provider.id === providerId
      //   ) ?? null
      // );
      setOpen(true);
      if (isSmallScreen) {
        NavigatePage(dispatch, "next", state, navigate, stateRoutes.appointmentWindow)
        //navigate("/appointmentwindow");
      }
    },
    [ dispatch, isSmallScreen, navigate]
  );
  const newOnAppointmentClick = useCallback(
    (providerId:string, date: number) => {
      if(isInjectedIframe()) {
        window.scrollTo(0, 0);
      }
      setIsItemClicked(false)
      setOpen(true)
      setSelectedAppointment({ providerId, date })
    },
    [state.appointmentData.appointmentTypesDistinct, dispatch, navigate, isSmallScreen,]
  )

  const onClickNext = () => {
    const len = selectLength + 20
    if (totalLength > len) {
      setSelectLength(len)
    }
    if(totalLength <= len+20){
      setIsDisableButton(true);
    }
  };
  const onClickPrev = () => {
    if (selectLength) {
      setSelectLength(selectLength - 20)
      setIsDisableButton(false);
    }

  };

  const onClickItem = () => {
    setOpen(false)
    setPageLoading(true)
    setIsItemClicked(true)
  };

  const renderAppointment = (data: AppointmentType) => {
    return (
      <>
        {data.Name}
      </>
    );
  };
  
  const getAppointmentInputProps = (
    params: AutocompleteRenderInputParams
  ): InputProps => {
    if (!state.availability.getInitialLocation) return params.InputProps;
    const obj = {
      ...params.InputProps,
      startAdornment: (
        <InputAdornment position="start">
        </InputAdornment>
      ),
    };
    return obj;
  };

  const validateAppointmentSelection = (
    option: AppointmentType,
    value: AppointmentType
  ) => {
    return (
      option.TaskKey === value.TaskKey &&
      option.Name === value.Name
    );
  };

  //Since we want the header to stretch across the screen on tablet size, but we also have
  //24px gutters on this size, stretch the header to override this. For some reason right
  //margin does not work here but setting the minWidth and a left margin does.
  const tabletHeaderSx: any = {
    minWidth: {
      xs: "0%",
      sm: "calc(100% + 48px)",
      md: "calc(100% + 48px)",
      lg: "0%",
      xl: "0%",
    },
    marginLeft: {
      xs: "0",
      sm: "-24px",
      md: "-24px",
      lg: "0",
      xl: "0",
    }
  }

  const showHeader = !(isInjected() && open);
  return (
    <>  
    <Container maxWidth={false}>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item flexGrow={1}></Grid>        
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={8}
              display={{ xs: "none", sm: "none", md: "none", lg: "block" }}
            >
              {showHeader &&
                <Header
                  providerCount={0}
                  calendarInfo={``}
                  onClickPrev={onClickPrev}
                  onClickNext={onClickNext}
                  selectLength={selectLength}
                  totalLength={totalLength}
                  dates={datePaginationList}
                />              
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container spacing={gridSpacing} className="main-container-address">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={8}
              display={{ xs: "block", sm: "block", md: "block", lg: "none" }}
              sx={tabletHeaderSx}
            >
              {showHeader &&
                <Header
                  providerCount={0}
                  calendarInfo={``}
                  onClickPrev={onClickPrev}
                  onClickNext={onClickNext}
                  selectLength={selectLength}
                  totalLength={totalLength}
                  dates={datePaginationList}
                />
              }
            </Grid>
            <Grid item xs={12} sm={7} md={4} lg={3} xl={3} className="main-container-select">
{/*                  <Practice
                    newAppointmentTypes={state.appointmentData.appointmentTypesDistinct}
                    newLocations={state.appointmentData.appointmentTypesLocation}
                    apoointmentTypes={appointmentData}
                    practiceName={locationName}
                    locations={locationData}
                    onAppointmentTypeChange={onAppointmentTypeChange}
                    selectedAppointmentType={state.appointmentData.appointmentType}
                    loadingPages={availabilityLoading}
                    onSelectedNewLocationData={selectedNewLocation}
                  />  */}            
                <Grid container className="wsui-grid-flex address-position">     
                  <Grid container item xs={12} md={6} sm={6} lg={12} spacing={gridSpacing} direction={"column"} className="wsui-grid-width" sx={{ flexWrap: "nowrap" }}> 
                    <Grid item xs={12} md={12} sm={12} lg={12} sx={{ paddingTop: "0px !important" }} 
                      className="wsui-content-width">
                      <Typography
                        variant="h6"
                        fontWeight={"700"}
                        sx={{ 
                          // fontSize: "22px", 
                          lineHeight: "25px", 
                          textAlign: "center",
                          marginTop: "15px"
                        }}
                        className="wsui-cover-title"
                      >
                        {state.session.DisplayOrgAlias ?? state.session.OrgName}
                      </Typography>
                    </Grid>                             
                      {
                      <> 
                        <Grid item xs={12} md={12} sm={12} lg={12} 
                          className="wsui-content-width">
                          <Typography
                            variant="subtitle1"
                            fontWeight={"500"}
                            sx={{ 
                              fontSize: "18px", 
                              lineHeight: "24px" , 
                              textAlign: "center" 
                            }}
                          >
                            Appointment Type
                          </Typography>        
                        </Grid>        
                        <Grid item xs={12} md={12} sm={12} lg={12}
                          sx={{ 
                            paddingBottom: "21px", 
                            borderBottom: "1px solid #999999", 
                            paddingLeft: "0 !important", 
                            marginLeft: "16px" 
                          }}
                          className="wsui-content-width"
                        >             
                          <ColorSelect
                            id="select-appttype"
                            data={!pageLoading ? state.appointmentData.appointmentTypesDistinct : []}
                            getOptionLabel={(item) => item.Name}
                            renderOptions={renderAppointment}
                            // selected={state.appointmentData.appointmentType ? state.appointmentData.appointmentType : null }
                            selected={state.appointmentData.appointmentTypesDistinct.length === 1 ? state.appointmentData.appointmentTypesDistinct[0] : state.appointmentData.appointmentType ? state.appointmentData.appointmentType : null} 
                            onSelection={onAppointmentTypeChange}
                            size={"small"}
                            placeholder={"Select Appointment"}
                            validateOptionSelected={validateAppointmentSelection}
                          

                          />
                      </Grid>
                    </> } 
                    <Grid item xs={12} md={12} sm={12} lg={12} 
                      className="wsui-content-width appointment-address"
                    >
                    <NewLocation 
                        locations={state.appointmentData.appointmentTypesLocation}
                        selectedLocation= {selectedNewLocation}
                        onLocationSelection = {onNewLocationSelection}      
                      />                     
                    </Grid>   
                    {state.appointmentData.appointmentType?.Name && 
                      <Grid item xs={12} md={12} sm={12} lg={12} 
                        display={{ xs: "none", sm: "block", md: "block", lg: "block" }}
                        className="wsui-content-width wsui-map-container appointmentHeader"
                      >
                        <SimpleMap 
                            locationCords={selectedNewLocation}
                            totalLocations={state.appointmentData.appointmentTypesLocation}
                            onLocationSelect={onNewLocationSelection}
                            />
                      </Grid>
                    }                     
                 </Grid>
                 <Grid item xs={0} md={6} sm={6} lg={0} sx={{
                  display: {
                    xs: "none",
                    sm: "block",
                    md: "block",
                    lg: "none",
                    xl: "none",
                  }
                 }}>
                    <AddressAndPhone />
                 </Grid>
               </Grid> 
            </Grid>
         <Grid item xs={12} sm={6} md={8} lg={9} xl={9} className="main-container-select">                        
         {coverLevelCustomMessage.length > 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
              >
              <Box sx={{ ml: { xs: 0, sm: 0, md: 0, lg: 16, xl: 20 } }}>
                <Grid container spacing={gridSpacing}>
                  <div
                    style={{
                      borderRadius: 10,
                      padding: 10,
                      verticalAlign: "top",
                      border: "solid 1px #adb4b7",
                      margin: "0px 0px 20px 20px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(coverLevelCustomMessage),
                    }}
                  />
                </Grid>
              </Box>
            </div>
          )}
              {
              !isSmallScreen && !pageLoading
              && !state.appointmentData.appointmentType?.TaskKey
              && state.appointmentData.appointmentTypesLocation.length > 0
              && state.appointmentData.appointmentTypesDistinct.length > 0
              && !isAppointmentSelected
              && !isInjected()
              &&
              (
                <Box sx={{ position: "fixed",  left: "70%" }}>
                  <CustomWidthTooltip
                    message="Please choose your appointment type."
                    showTooltip
                  />
                </Box>
              )
          }
          {!isSmallScreen && !pageLoading 
                          && state.appointmentData.appointmentType?.TaskKey 
                          && !state.availability.getInitialLocation
                          && state.appointmentData.appointmentTypesLocation.length > 0  
                          && state.appointmentData.appointmentTypesDistinct.length > 0
                          && !isInjected()
                          &&  (
            <Box sx={{ position: "fixed", left: "70%" }}>
              <CustomWidthTooltip
                message="Please choose your Location"
                showTooltip
              />
            
            </Box>
          )} 

         {   
                   
            hasSchedules && errorMessage.length === 0 && (!pageLoading || isItemClicked) && state ?            
              <Grid item xs={12}>
                  <Box sx={{ ml: { xs: 0, sm: 0, md: 0, lg: 5, xl: 20 } }}>
                    <Grid container spacing={gridSpacing}>
                      {

                        
                        state.providers.providersWithAppointment.map((providerInfo: any, index) => {
                          var provider = providerInfo.provider;
                     
                          if (!state.availability.availabilities?.providers?.includes(provider.AcctKey) && state.embed.ProviderAgnostic !== 1) {
                            return
                          }
                                          
                           const cusMessage = tmpCustom.find(j => j.AcctKey === provider.AcctKey)?.Message;
                          
                          return (
                            <Grid item xs={12} key={index}>
                            
                             {selectedNewLocation===null ? "":<NewProviderWithAppointment
                                customMessageTemp={cusMessage}
                                data={provider}
                                dates={datePaginationList}
                                onAppointmentClick={newOnAppointmentClick}
                                key={`appointment_header_:${index}_provider_${Math.random()}`}
                                totalProviderCount={
                                  state.providers.providersDistinct.length
                                }
                                selectLength={selectLength}
                                currentProviderIndex={index}
                                appointmentType={state.appointmentData.appointmentType}
                                hasAppointment = {providerInfo.hasSchedule}
                                nextAvailableAppt = {providerInfo.nextScheduleAppt}
                              />} 
                           
                              {/* <NewProviderWithAppointment
                                customMessageTemp={cusMessage}
                                data={provider}
                                dates={datePaginationList}
                                onAppointmentClick={newOnAppointmentClick}
                                key={`appointment_header_:${index}_provider_${Math.random()}`}
                                totalProviderCount={
                                  state.providers.providersDistinct.length
                                }
                                selectLength={selectLength}
                                currentProviderIndex={index}
                                appointmentType={state.appointmentData.appointmentType}
                                hasAppointment = {providerInfo.hasSchedule}
                                nextAvailableAppt = {providerInfo.nextScheduleAppt}
                              /> */}
                            </Grid>
                          )
                        })
                      }
                    </Grid>
                  </Box>
              </Grid>    
             : 
             <>
                 {((!pageLoading && state.availability.getInitialLocation
                 && errorMessage.length === 0    && isAppointmentSelected === true ) ||
                 (!pageLoading && state.appointmentData.appointmentTypesLocation.length === 0 && isAppointmentSelected === true  ) 
                 || (!pageLoading && state.appointmentData.appointmentTypesDistinct.length === 0  && isAppointmentSelected === true   )
                 || (!pageLoading && state.appointmentData.appointmentTypesDistinct.length === 0  && isAppointmentSelected === false   )
                  ) && 
                 
                  
                  <Box sx={{ ml: { xs: 0, sm: 0, md: 0, lg: 16, xl: 20 } }}>
                     <div style={{ textAlign : "center",
                                  padding: "0 20px 20px"}}> 
                          {
                                <Typography variant="h4"
                                  fontWeight={"500"}
                                  sx={{
                                    fontSize: "18px",
                                    fontWeight: "600",
                                }} >{state.session?.OrgName === undefined ? 
                                     'Unexpected error. Please call the office to book an appointment.' : 
                                     (state.appointmentData.appointmentTypesLocation.length === 0 || state.appointmentData.appointmentTypesDistinct.length === 0)
                                       ? state.session.LocKey !== null 
                                          ? state.appointmentData.appointmentTypesLocation.length === 0 
                                             ? 'Please call the office to schedule an appointment : ' + state.session.FrontLine 
                                             :'Please call the office to schedule an appointment : ' + state.availability.getInitialLocation.PhoneNumberFormated 
                                          :'Please call the office to schedule an appointment : ' + state.session.FrontLine  
                                        : 'Please call the office to schedule an appointment : ' + state.availability.getInitialLocation.PhoneNumberFormated}
                                {/* }} >{state.session?.OrgName === undefined ? 'Unexpected error. Please call the office to book an appointment.':state.appointmentData.appointmentTypesLocation.length === 0 || state.appointmentData.appointmentTypesDistinct.length === 0 ? 'Please call the office to schedule an appointment : ' + state.session.FrontLine : 'Please call the office to schedule an appointment : ' + state.availability.getInitialLocation.PhoneNumberFormated}  */}
                                </Typography>
                          } 
                      </div>
                      </Box>
                    
                 }             
              </>  
          }        
         </Grid>
            
            {state.appointmentData.appointmentType?.Name &&
              <Grid item xs={12} sm={7} md={4} lg={3} xl={3} display={{ xs: "none", sm: "none", md: "none", lg: "none" }}>
                <SimpleMap
                  locationCords={selectedNewLocation}
                  totalLocations={state.appointmentData.appointmentTypesLocation}
                  onLocationSelect={onNewLocationSelection}
                />
              </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
  
      {isInjected() ?
        <AppointmentInjectedWindow
          open={open}
          onToggle={onCloseAppointment}
          onClickPrev={onClickPrev}
          onClickNext={onClickNext}
          selectedAppointment={selectedAppointment}
          selectLength={selectLength}
          datePaginationList={datePaginationList}
        /> :
        <>
          {!isSmallScreen && (
            <AppointmentDrawer
              open={open}
              onToggle={onCloseAppointment}
              onClickPrev={onClickPrev}
              onClickNext={onClickNext}
              selectedAppointment={selectedAppointment}
              selectLength={selectLength}
              datePaginationList={datePaginationList}
            />
          )}
          {isSmallScreen && (
            <AppointmentWindow
              open={open}
              onToggle={onCloseAppointment}
              onClickPrev={onClickPrev}
              onClickNext={onClickNext}
              selectedAppointment={selectedAppointment}
              selectLength={selectLength}
              datePaginationList={datePaginationList}
            />
          )}
        </>
      }
      {/* {!isSmallScreen && (
       <AppointmentDrawer
         open={open}
         onToggle={onCloseAppointment}
         onClickPrev={onClickPrev}
         onClickNext={onClickNext}
         onClickItem={onClickItem}
         selectedAppointment={selectedAppointment}
         selectLength={selectLength}
         datePaginationList={datePaginationList}
       />
     )}
     {isSmallScreen && (
       <AppointmentWindow
         open={open}
         onToggle={onCloseAppointment}
         onClickPrev={onClickPrev}
         onClickNext={onClickNext}
         onClickItem={onClickItem}
         selectedAppointment={selectedAppointment}
         selectLength={selectLength}
         datePaginationList={datePaginationList}
       />
     )} */}
        {pageLoading === true &&
      <>
          <div className="wsui-spinner">
              <Spinner />
          </div>

      </>}
    </Container>
    </>
  );
};

export default LandingPage;
