import { reject } from "q";
import { getBaseAPI } from "../environments/Environments";
import { UrlHashingState } from "../models/UrlHashingState";
import { apiFetch } from "./ServiceHelper";

export const getStateFromHash = (hash: string): Promise<UrlHashingState> => {
  const queryString = `hashKey=${hash.substring(1)}`;
  return new Promise(async (resolve) => {
      const url = await getBaseAPI() + '/api/v1/Patient/unHashKey?' + (queryString);
      apiFetch(url, {
        method: 'GET'
      })
        .then((result) => Promise.resolve(result))
        .then((state) => {
          const hashState: UrlHashingState = {
            CoverKey: state.CoverKey,
            LocKey: state.LocKey,
            AcctKey: state.AcctKey,
            AddKey: state.AddKey,
            TaskKey: state.TaskKey,
            ApptKey: state.ApptKey,            
            Hash: hash.substring(1)
          };
          resolve(hashState);
        })
        .catch((e) => {
          console.log('URL Hash Error');
          reject(e);
        });
  });
}

