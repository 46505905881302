import AppointmentType, { AppointmentTypeData, mapAppointmentTypeData } from "../models/AppointmentType";
import HashReturnObject from "../models/HashReturnObject";
import { mapServiceProviderData, mapSessionData, SessionModel } from "../models/Session";
import { setHashforState } from "../features/hasher/hasherSlice";

import Provider, { ProviderData, mapProviderData } from '../models/Provider';
import { getProvidersData } from "../features/provider/newProviderSlice";

import LocationDetails, { LocationDetailsData, mapLocationData } from "../models/LocationDetails";
import { getAppointmentData, getLocationsData } from "../features/appointmentData/appointmentSlice";
import { EcpConfigurationAction } from "../models/EcpPatientConfiguration";
import { setEcpConfiguration } from "../features/embed/embedSlice";
import { bookingApiPost, configApiGet } from "./ServiceHelper";
import ErrorService from "./ErrorService";



const  InitServices = async (session: SessionModel, dispatch : any, state : any)  => {
       //const dispatch = useAppDispatch();
        //Provider
        var providerState = {...state.session, LocKey : null, TaskKey : null, GroupKey : null}
        await bookingApiPost('Patient/getProvider', mapServiceProviderData(providerState))
		.then(res => {
			var response = res.data

            if (response && response.hasOwnProperty('error')) {
                ErrorService.showErrorAlert('Oops! Something went wrong. Please try again later. If the issue persists, please contact our support team for assistance.', response);
            }
            const providers: Provider[] = [];
            response.data.forEach((provider: ProviderData) => {
                providers.push(mapProviderData(provider));
            });            

            const responseObject: HashReturnObject<Provider> = {
                data: providers,
                hash: response.hash,
                sessionId: response.sessionId
            }

            dispatch(getProvidersData(responseObject))
            //dispatch(setHashforState(responseObject.hash))
		})
		.catch((e) => {
			console.log("Init Service Error");
            ErrorService.showErrorAlert('Oops! Something went wrong. Please try again later. If the issue persists, please contact our support team for assistance.', e);
            return(false);
		});     

        // Appointment
        var appointmentState = {...state.session, LocKey : null}
        await bookingApiPost('Patient/getWSAppointmentType', mapSessionData(appointmentState))
		.then(res => {
			var response = res.data
           
            if (response && response.hasOwnProperty('error')) {
                ErrorService.showErrorAlert('Oops! Something went wrong. Please try again later. If the issue persists, please contact our support team for assistance.', response);
            }
            const appointmentTypes: AppointmentType[] = [];
            response.data.forEach((data: AppointmentTypeData) => {
                appointmentTypes.push(mapAppointmentTypeData(data));
            });
            const responseObject: HashReturnObject<AppointmentType> = {
                data: appointmentTypes,
                hash: response.hash,
                sessionId: response.sessionId
            }

            dispatch(getAppointmentData(responseObject))
            //dispatch(setHashforState(responseObject.hash))
		})
		.catch((e) => {
            ErrorService.showErrorAlert('Oops! Something went wrong. Please try again later. If the issue persists, please contact our support team for assistance.', e);
			//console.log('Init Service error');
			return(false);
		}); 

        // Location
        var locationState  = {...state.session}
        locationState = {...state.session, TaskKey : null , LocKey : null}
        await bookingApiPost('Patient/getWSLocation', mapServiceProviderData(locationState))
		.then(res => {
			var response = res.data

            if (response && response.hasOwnProperty('error')) {
                ErrorService.showErrorAlert('Oops! Something went wrong. Please try again later. If the issue persists,\n please contact our support team for assistance.', response);
            }
            
            const locations: LocationDetails[] = [];
            response.data.forEach((data: LocationDetailsData) => {
                locations.push(mapLocationData(data));
            });
            
            const responseObject: HashReturnObject<LocationDetails> = {
                data: locations,
                hash: response.hash,
                sessionId: response.sessionId
            }            

            dispatch(getLocationsData(responseObject.data))
            dispatch(setHashforState(responseObject.hash))
		})
		.catch((e) => {
			//console.log('Init Service error');
            ErrorService.showErrorAlert('Oops! Something went wrong. Please try again later. If the issue persists,\n please contact our support team for assistance.', e);
            return(false);
		});     
        
  


        return(true)     
}

export const UpdateEmbededState = async (session: SessionModel, dispatch : any) =>
{
		// ECP Configuration
        //  const url = 'https://localhost:5001/api/v1/Patient/getECPPatientConfiguration';
        const sessionData = mapSessionData(session);
        await bookingApiPost('Patient/getECPPatientConfiguration', sessionData)
        .then((res) => {
            var ecpPatientConfiguration = res.data
            
            if (ecpPatientConfiguration.hasOwnProperty('error')) {
                ErrorService.showErrorAlert('Oops! Something went wrong. Please try again later. If the issue persists, please contact our support team for assistance.', ecpPatientConfiguration);
            }
			const ecpObj: EcpConfigurationAction = {
				CoverKey: null,
				LocKey: null,
				GroupKey: null,
				AcctKey: null,
				TaskKey: null,
				ReferredBy: null,                            
				SkipDemographics: ecpPatientConfiguration.isDemographicsEnabled,
				SkipPatientInsurance: ecpPatientConfiguration.isInsuranceEnabled,
				SkipCustomQuestions: ecpPatientConfiguration.isCutomQEnabled,
                SkipPatientInformation : ecpPatientConfiguration.isPatientEnabled,
				TrackingID: null,    
                Stylings : null,     
				Embed: null,
                ProviderAgnostic: ecpPatientConfiguration.providerAgnostic,
                LimitOneAppt: ecpPatientConfiguration.limitOneAppt
			}    

			dispatch(setEcpConfiguration(ecpObj))	  
        })
		.catch((e) => {
			console.log('Init Service error');
            return(false);
		});                    
}

export const GetCoverKeyInformation = async (locKey : any, coverKey : any) =>
{
    //var defaultLogo = `${process.env.REACT_APP_DEFAULT_LOGO as string}`;
    var defaultLogo = "";
    var coverName = "";
    var coverAlias = "";
    var mobileLogo =   "";
    var desktopLogo =  "";
    var frontLine =  "";      
		
    await configApiGet('ECPDashboard/GetCoverProviderInfo', { coverKey: coverKey, locKey: locKey })
    .then(res => {
        var response = res.data
        if (response)
        {
            coverName = response.orgName
            coverAlias = response.displayOrgAlias
            mobileLogo =  response.mobileLogo ? response.mobileLogo : defaultLogo
            desktopLogo = response.desktopLogo  ? response.desktopLogo : defaultLogo
    }		frontLine = response.frontLine 
    })
    .catch((e) => {
        console.log('Init Service error');
    });	

    return [coverName, coverAlias, mobileLogo, desktopLogo, frontLine] as const;
}

export const GetAppointmentInformation = async (appointment : any) =>
{
        var param = {
            "name": appointment.name,
            "taskKey": appointment.taskKey,
            "locKey": appointment.lockKey,
            "acctKey": 0
          }

          var returnObject = {
            "name": "",
            "taskKey": 0,
            "locKey": 0,
            "acctKey": 0            
          }          

        await bookingApiPost('Patient/getWSAppointmentInfo', param)
        .then(res => {
            var response = res.data
            returnObject = {
                "name": response.name,
                "taskKey": response.taskKey,
                "locKey": response.lockKey,
                "acctKey": 0
              }

        })
        .catch((e) => {
            console.log('Init Service error');
            return({});
        }); 
return returnObject;
}

export default  InitServices;