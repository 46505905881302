import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import appointmentSlice from "../features/appointmentData/appointmentSlice";
import availabilitySlice from "../features/availability/availabilitySlice";
import counterReducer from "../features/counter/counterSlice";
import embedSlice from "../features/embed/embedSlice";
import hasherSlice from "../features/hasher/hasherSlice";
import newProviderSlice from "../features/provider/newProviderSlice";
import providerReducer from "../features/provider/providerSlice";
import sessionSlice from "../features/session/sessionSlice";
import demographicsSlice from "../features/demographics/demographicsSlice";
import patientInsuranceSlice from "../features/patientInsurance/patientInsuranceSlice";
import patientSlice from "../features/patient/patientSlice";
import multipleAppointmentSlice from "../features/MultipleAppointments/multipleAppointmentsSlice";
import multipleAppointmentSelectedSlice from "../features/MultipleAppointments/multipleAppointmentSelectedSlice";
import pageSlice from "../features/page/pageSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    provider: providerReducer,
    session: sessionSlice,
    appointmentData: appointmentSlice,
    hash: hasherSlice,
    embed: embedSlice,
    providers: newProviderSlice,
    availability: availabilitySlice,
    demographics: demographicsSlice,
    patientInsurance: patientInsuranceSlice,
    patient: patientSlice,
    multipleAppointments: multipleAppointmentSlice,
    multipleAppointmentSelected: multipleAppointmentSelectedSlice,
    page: pageSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
