import React, { useState, useEffect } from "react";
import { Container, Divider  } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import AppointmentSelectionTile from "../../appointment/AppointmentSelectionTile";
import { useAppSelector } from "../../../app/hooks";
import Providers from "../../../models/Provider";
import AppointmentType from "../../../models/AppointmentType";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import AccountBoxSharpIcon from "@mui/icons-material/AccountBoxSharp";
import NewAppointmentSelectionTile from "../../appointment/NewAppointmentSelectionTile";
import NewProvider from "./NewProvider";
import "./NewProviderWithAppointmentStyle.css";
import CustomMessagesService from "../../../services/CustomMessagesService";
import { useTheme } from "@mui/material/styles";
import DOMPurify from "dompurify";
import { CustomMessagesModel } from "../../../models/CustomMessages";

interface IProviderWithAppointmentProps {
    data: Providers;
    onAppointmentClick: (providerId: string, date: number) => void;
    totalProviderCount: number;
    currentProviderIndex: number;
    appointmentType: AppointmentType | null;
    dates: any[]
    selectLength: number;
    hasAppointment : boolean;
    nextAvailableAppt : Date | null;
    customMessageTemp: any | null

}

const NewProviderWithAppointment = ({
    data,
    dates,
    onAppointmentClick,
    totalProviderCount,
    currentProviderIndex,
    appointmentType,
    selectLength,
    hasAppointment,
    nextAvailableAppt,
    customMessageTemp
 
}: IProviderWithAppointmentProps) => {
    const availabilities = useAppSelector(st => st.availability.availabilities)
    const onClickNext = () => { };
    const onClickPrev = () => { };
    const [viewAll, setViewAll] = useState(false)
    const state = { embed: useAppSelector(st => st.embed) };
    const theme = useTheme();
    const [longMessage, setLongMessage] = useState(customMessageTemp?.length > 150);
    const customMessage = customMessageTemp || '';

    useEffect(()=>{
        setLongMessage(customMessage.length > 150);
    }, [customMessage]);

//OLD Custom Message
	// useEffect(()  => {

    //     var test = state
	// 	const getCustomMessages = async () => {
	// 		let customMessage = "";
	// 		if (state.provider && state.provider !== null) {
	// 			const response = await CustomMessagesService.retrieveCustomMessages({
	// 				...state.session,
	// 				AcctKey: data.AcctKey,
	// 			});

	// 			customMessage = response.messages
 	// 				.filter((x) => x.v4UnderPic?.trim())
	// 				.map((x) => x.v4UnderPic?.trim())
	// 				.join("<br />");
    //     console.log(customMessage);
    //     setCustomMessage(customMessage);
	// 			if (customMessage.length > 150) 
    //             {
    //               setLongMessage(true);
    //             }
	// 		}
	// 		else
    //         console.log(customMessage);
    //         setCustomMessage(customMessage);
	// 	};  
    //      getCustomMessages();    
    //   }, []);    

    var pageNum = selectLength > 0 ? selectLength / 20 : 0

    let sdate =  new Date(dates[pageNum]);
    const strtDate = sdate.getTime()
    // Display only first 20 days of the calendar
    let newArr : number[] = []
    for (let i = 0; i < 21; i++) {     
       newArr.push(sdate.getTime())
       sdate.setDate(sdate.getDate() + 1); 
    }

    var firstAvailableDate  = (!hasAppointment && nextAvailableAppt) ? " Next available is on " + nextAvailableAppt?.toLocaleDateString("en-US").slice(0, 10) : ""
    
     return (
        <Grid item xs={12}>
            <Grid container justifyContent="center">
                {state.embed.ProviderAgnostic !== 1 &&
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{ marginBottom: "18px" }}>
                        {/* Provider details */}
                        <NewProvider data={data} />
                    
                    {customMessage !== "" && <div style={{paddingRight : "10px" , paddingTop : "10px"}}>
                        <Divider
                            sx={{
                            opacity: 1,
                            borderColor: theme.palette.primary.light,
                            }}
                        />       
                        
                    
                        <div style={{paddingLeft : "20px", marginTop : "10px", height : longMessage ? "100px" : "", overflow : longMessage ? "hidden" : "visible",
                                    }} dangerouslySetInnerHTML={{__html:  DOMPurify.sanitize(customMessage)}}/>

                        {customMessage.length > 150 && (
                            <div style={{paddingTop : "20px" , cursor : "pointer"}}>
                                <button style={{ cursor : "pointer"}}
                                    onClick={() => setLongMessage(!longMessage)}
                                    id={`button-show-provider-message-${data.AcctKey}`}
                                    className="button-show-provider-message">
                                        {longMessage ? "Show more" : "Show less"}
                                </button>
                            </div>
                        )}     
                        </div>}
                    </Grid>
                }  

                <Grid item xs={12} sm={12} md={12} lg={8} xl={8} sx={{
                    color: "green",
                    marginTop: {
                        xs: "0",
                        sm: state.embed.ProviderAgnostic === 1 ? "20px" : "0",
                        md: state.embed.ProviderAgnostic === 1 ? "20px" : "0",
                        lg: "0",
                        xl: "0",
                    }
                }}>
                    {(newArr.length && hasAppointment) ?
                        <Grid container justifyContent={"space-between"}>
                            {newArr.map((date, index) => {
                                return <Grid className="appointment-list" item key={`appointment_tile_:${index}_id_${date}`} width={"14%"}  sx={{ mr: { lg: 0, xl: 0, sm: 0, xs:0.13 } }}>
                                    <Box className="appointment-box" sx={{ mr: { lg: 0.25, xl: 2 }, mb: { xs: 1, sm: 1, md: 1, lg: 0.25, xl: 2 } }}>
                                        <NewAppointmentSelectionTile
                                            id={`button-apptdate-${data.AcctKey}-${index}`}
                                            appointmentDate={date}
                                            disabled={false}
                                            onAppointmentClick={onAppointmentClick}
                                            providerId={data.AcctKey}
                                            data={data}
                                        />
                                    </Box>
                                </Grid>
                            })}
                    </Grid> : 
                    <Grid item xs={12} sm={6} md={8} lg={12} xl={12}>
                        <div style={{ textAlign : "center",paddingTop: "100px"}}>  
                        <Typography variant="h6"
                        fontWeight={"400"} 
                        sx={{ fontSize: "16px", lineHeight: "21px" }} >
                            {`There are no appointments for this time period. ${firstAvailableDate}` }
                        </Typography>
                        </div>
                    </Grid>}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            mt: 4,
                            mb: 1,
                        }}
                        >
                        <Box flexGrow={1}></Box>
                        <Link
                            sx={{
                                ml: "auto",
                                color: !appointmentType ? "#999" : "primary.main",
                                fontSize: "16px",
                                lineHeight: "21px",
                                mr: { xl: 2 }
                            }}
                            onClick= {()=> onAppointmentClick(data.AcctKey, strtDate)}
                            component={"button"}
                            >
                            {viewAll ? 'Hide' : 'View'} all availability   
                        </Link>
                        <Box></Box>
                    </Box>
                </Grid>
                {totalProviderCount - 1 === currentProviderIndex ? null : (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box
                            sx={{
                                mt: 5,
                                mb: 2,
                            }}
                        >
                            <hr />
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Grid> 
            );
};

export default NewProviderWithAppointment;
