import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Provider, {NewProviderState} from "../../models/Provider";



const initialState: NewProviderState = {
    provider:{} as Provider,
    providers: [],
    providersDistinct: [],
    providersWithAppointment : []
}

export const newProviderSlice = createSlice({
    name:'providers',
    initialState,
    reducers: {
        getProvidersData: (state,action)=> {
            if (action.payload) {
                const distinctProviders = action.payload.data.filter(
                    (thing:any, i:number, arr: any) => arr.findIndex((t: { AcctKey: { toString: () => any; }; }) => t.AcctKey.toString() === thing.AcctKey.toString()) === i
                  );
                state.providers = action.payload.data
                state.providersDistinct = distinctProviders
            }
        },
        setProvider: (state,action)=> {
            if (action.payload) {
                state.provider = action.payload
            }
        },
        setProviderWithAppointment: (state,action)=> {
            if (action.payload) {
                state.providersWithAppointment = action.payload
            }
        }        
    }
})
export const {getProvidersData, setProvider, setProviderWithAppointment} = newProviderSlice.actions
export default newProviderSlice.reducer