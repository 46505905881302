import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Availability, AvailabilityState } from "../../models/Availability";
import AvailabilityGrouping from "../../models/AvailabilityGrouping";

const initialState: AvailabilityState = {
    availabilities : {} as AvailabilityGrouping,
    availability: {} as Availability,
    getInitialLocation: null as any
}

export const availabilitySlice = createSlice({
    name:'availability',
    initialState,
    reducers: {
        getAvailabililties: (state,action)=> {
            if (action.payload) {
                state.availabilities = action.payload
            }
        },
        getAvailability : (state,action)=> {
            if(action.payload) {
                state.availability = action.payload
            }
        }, 
        clearProviderData: (state)=> {
            state.availabilities = {} as AvailabilityGrouping
        },
        getlocationsback: (state,action)=>{
            state.getInitialLocation = action.payload
        }
    }
})

export const {getAvailabililties, getAvailability, clearProviderData,getlocationsback} = availabilitySlice.actions
export default availabilitySlice.reducer