import { getInjectedParams } from "../models/InjectedParams";

//Contains methods to determine whether WebScheduler is currently embedded in another site, and optionally to test if
// this embedding is or is not in iframe mode.
//Currently this is all simple functions rather than React hooks and can probably be moved out of the hooks/ folder.


// const useIsInjected = (): boolean => {
//   return isInjected();
// }

const isInjectedBase = (testIsIframe: boolean | undefined): boolean => {
  const webscheduler = getInjectedParams();
  let isInjected: boolean = false;
  if(webscheduler && webscheduler.injected) {
    const isIframe = !webscheduler.scripturlbase;
    if(testIsIframe === undefined || testIsIframe === isIframe) {
      isInjected = true;
    }
  }
  return isInjected;
}

export const isInjected = (): boolean => {
  return isInjectedBase(undefined);
}
export const isInjectedNonIframe = (): boolean => {
  return isInjectedBase(false);
}
export const isInjectedIframe = (): boolean => {
  return isInjectedBase(true);
}

//export default useIsInjected;

