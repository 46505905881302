import AvailabilityGrouping from "./AvailabilityGrouping";
import LocationDetails from "./LocationDetails";

export interface Availability {
    LocKey: string,
    AcctKey: string,
    TaskKey: string,
    ApptKey: string,
    InternalKey: string,
    AppointmentDate: string,
    AppointmentStartTime: string,
    AppointmentDateTime: Date,
    AppointmentDateTimeUTC: Date,
    T: number
}
export interface AvailabilityState {
    availabilities : AvailabilityGrouping,
    availability: Availability,
    getInitialLocation:any
   
}

export interface AvailabilityData {
    logKey: string,
    groupKey: string,
    coverKey: string,
    locKey: string,
    acctKey: string,
    addKey: string,
    taskKey: string,
    apptKey: string,
    appointmentDate: string,
    appointmentStartTime: string
}

export function mapAvailabilityData(data: AvailabilityData): Availability {
    const date = new Date(data.appointmentDate);
    const hourmin = data.appointmentStartTime.split(':');
    date.setHours(parseInt(hourmin[0]));
    date.setMinutes(parseInt(hourmin[1]));
    const utc = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    return {
        LocKey: data.locKey,
        AcctKey: data.acctKey,
        TaskKey: data.taskKey,
        ApptKey: data.apptKey,
        InternalKey: `${data.acctKey}${date.getTime()}`,
        AppointmentDate: data.appointmentDate,
        AppointmentStartTime: data.appointmentStartTime,
        AppointmentDateTime: date,
        AppointmentDateTimeUTC: utc,
        T:0
    }
}