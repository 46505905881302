import {createSlice, PayloadAction } from '@reduxjs/toolkit'
import AppointmentType, { AppointmentTypesDistinct, AppointmentAction, AppointmentDataState } from '../../models/AppointmentType';
import LocationDetails from '../../models/LocationDetails';
import { arraySortExt } from "../../utils/Util";

const initialState: AppointmentDataState = {
    appointmentType:{} as AppointmentType,
    appointmentTypes:[],
    appointmentTypesDistinct:[],
    appointmentTypesLocation: [],
    locations: []
}

export const appointmentSlice = createSlice({
    name:'appointmentData',
    initialState,
    reducers:{
        getAppointmentData: ( 
            state,
            action: PayloadAction<AppointmentAction>
            ) => {
            const distinctAppointmentTypes = action.payload.data.filter(
                (thing: { Name: any; }, i: any, arr: any[]) => arr.findIndex((t: { Name: any; }) => t.Name === thing.Name) === i
              );
             var distinctAppointmentTypesSort = arraySortExt(distinctAppointmentTypes, [{ fieldName: "Name", ascending: true }]);  
            if(action.payload) {
                state.appointmentTypes = action.payload.data;
                state.appointmentTypesDistinct = distinctAppointmentTypesSort
            }
        },
        setAppointmentType: (
            state,
            action: PayloadAction<AppointmentType | null>
            )=> {
                const LocationName = JSON.parse(JSON.stringify(state.appointmentTypes)).filter(
                    (idx : any) => (idx.Name == action.payload?.Name)
                )                
                const LocKeyValue = [...new Set(LocationName.map((val: any) => val.LocKey))]  
                
                var test = JSON.parse(JSON.stringify(state.locations))
                const LocKeyLocation = JSON.parse(JSON.stringify(state.locations)).filter((val: any) => LocKeyValue.includes(val.LocKey))
                const distinctLocKeyLocation = LocKeyLocation.filter(
                    (thing: { Name: any; }, i: any, arr: any[]) => arr.findIndex((t: { Name: any; }) => t.Name === thing.Name) === i
                );
            if (action.payload) {
                state.appointmentType = action.payload
            state.appointmentTypesLocation = distinctLocKeyLocation

            } else state.appointmentType = {} as AppointmentType
        },
        getLocationsData : (
            state,
            action: PayloadAction<LocationDetails[]>
        )=> {
            if (action.payload) {
                state.locations = action.payload
                const distinctLocKeyLocation = state.locations.filter(
                    (thing: { Name: any; }, i: any, arr: any[]) => arr.findIndex((t: { Name: any; }) => t.Name === thing.Name) === i
                );
                state.appointmentTypesLocation = distinctLocKeyLocation     
            }
        },
        setLocationDetails : (
            state,
            action: PayloadAction<LocationDetails[]>
        )=> {
            if (action.payload) {
                state.locations = action.payload
            }
        },        
    }

})
export const {getAppointmentData, setAppointmentType, getLocationsData, setLocationDetails } = appointmentSlice.actions
export default  appointmentSlice.reducer