import { mapSessionData } from "../models/Session";
import HashReturnObject from "../models/HashReturnObject";
import AvailabilityGrouping, {FirstAvailableKey} from "../models/AvailabilityGrouping";
import { AvailabilityData, Availability, mapAvailabilityData } from "../models/Availability";
import { arraySortExt } from "../utils/Util";
import { StateModel } from "../models/StateModel";
import { bookingApiPost } from "./ServiceHelper";

export const  AvailabilityService = (state: StateModel, LocKey:number, useCached:number|null): Promise<HashReturnObject<AvailabilityGrouping>> => {
    return new Promise(async (resolve, reject) => {
            let apiUrl = 'Availability/getAvailability';    
            if (useCached !== null) {
              let useCache = useCached === 1? 'true':'false'
              apiUrl += `?useEnhancedGetAvailability=${useCache}`;
            }
            await bookingApiPost(apiUrl, mapSessionData({...state.session, LocKey}))
			.then((res : any)  => {
                var hashReturnObject = res.data
                const availability: Availability[] = [];

                hashReturnObject.data.forEach((data: AvailabilityData) => {
                    const av = mapAvailabilityData(data);
                    av.T = new Date(av.AppointmentDate + ' ' + av.AppointmentStartTime).getTime();
                    availability.push(av);
                });

                const responseObject: HashReturnObject<AvailabilityGrouping> = {
                    data: [groupAvailabilitiesbyDate(availability, state)],
                    // data: availability,
                    hash: hashReturnObject.hash,
                    sessionId: hashReturnObject.sessionId
                }
                resolve(responseObject);
			})
			.catch((e) => {
				console.log("Availability Service error");
				reject(e);
			});		     
                                
/*             fetch(url, {
                method: 'POST',
                body: JSON.stringify(mapSessionData({...state.session, LocKey})),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((result) => Promise.resolve(result))
                .then((hashReturnObject: HashReturnObject<AvailabilityData>) => {
                    const availability: Availability[] = [];
                    console.log('Availibility APi::', hashReturnObject)
                    hashReturnObject.data.forEach((data: AvailabilityData) => {
                        const av = mapAvailabilityData(data);
                        av.T = new Date(av.AppointmentDate + ' ' + av.AppointmentStartTime).getTime();
                        availability.push(av);
                    });

                    const responseObject: HashReturnObject<AvailabilityGrouping> = {
                        data: [groupAvailabilitiesbyDate(availability, state)],
                        // data: availability,
                        hash: hashReturnObject.hash,
                        sessionId: hashReturnObject.sessionId
                    }
                    resolve(responseObject);
                })
                .catch((e) => {
                    console.log('request error', e);
                    reject(e);
                }); */
        });
}



const filterAv_3hourGap = (av: Availability): boolean => {
    const dtString = `${av.AppointmentDate} ${av.AppointmentStartTime}`;
    const appDateTm = new Date(Date.parse(dtString));
    const cDate = new Date();
    const ThreHoursLater = new Date(cDate.getTime() + (3 * 1000 * 60 * 60));        
    return ThreHoursLater.getTime() < appDateTm.getTime();
};

const groupAvailabilitiesbyDate = (availabilities: Availability[], state: StateModel): AvailabilityGrouping => {
    const expectedProviders = state.providers.providers.map((provider) => provider?.AcctKey.toString());

    const groups: AvailabilityGrouping = { dates: {}, providers: [] };
    if (!availabilities || availabilities.length === 0) {
        return groups;
    }

    const _av = arraySortExt(availabilities, [{ fieldName: "T", ascending: true }]);
    _av.filter(x => filterAv_3hourGap(x)).forEach((value) => {
        if (expectedProviders.findIndex((acctKey) => acctKey === value.AcctKey.toString()) < 0) {
            return;
        }

        const date = new Date(value.AppointmentDate);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        const dateIndex = date.getTime();
        //Check if day exists
        if (groups[dateIndex]) {
            //Check if provider exists in day group
            if (groups[dateIndex][value.AcctKey.toString()]) {
                groups[dateIndex][value.AcctKey.toString()].push(value);
            } else {
                //Create provider in day group
                if (!groups.providers.find(x => x === value.AcctKey)) {
                    groups.providers.push(value.AcctKey);
                }
                groups[dateIndex][value.AcctKey.toString()] = [value];
            }
            //Check if "first available" provider group exists
            if (groups[dateIndex][FirstAvailableKey]) {
                const exists = groups[dateIndex][FirstAvailableKey]
                    .find(x => x.AppointmentDateTime.getTime() === value.AppointmentDateTime.getTime());
                if (!exists) {
                    groups[dateIndex][FirstAvailableKey].push(value);
                }
            } else {
                groups[dateIndex][FirstAvailableKey] = [value];
            }
        } else {
            //Create day group
            if (!groups.providers.find(x => x === value.AcctKey)) {
                groups.providers.push(value.AcctKey);
            }
            groups[dateIndex] = {};
            groups[dateIndex][value.AcctKey.toString()] = [value];
            groups[dateIndex][FirstAvailableKey] = [value];
        }

        //Track dates
        if (groups.dates[value.AcctKey.toString()]) {
            if (!groups.dates[value.AcctKey.toString()].find(x => x === dateIndex)) {
                groups.dates[value.AcctKey.toString()].push(dateIndex);
            }
        } else {
            groups.dates[value.AcctKey.toString()] = [dateIndex];
        }
        if (groups.dates[FirstAvailableKey]) {
            if (!groups.dates[FirstAvailableKey].find(x => x === dateIndex)) {
                groups.dates[FirstAvailableKey].push(dateIndex);
            }
        } else {
            groups.dates[FirstAvailableKey] = [dateIndex];
        }
    });

    for (const key in groups.dates) {
        //Sort availabilities
        for (let iDate = 0; iDate < groups.dates[key].length; iDate++) {
            const date1 = groups.dates[key][iDate];
            const availabilities = groups[date1][key];
            if (availabilities) {
                groups[date1][key] = arraySortExt(availabilities, [{ fieldName: "T", ascending: true }]);
            }
        }

        //Build up dates from min to max

        const minDate = Math.min(...groups.dates[key]);
        const maxDate = Math.max(...groups.dates[key]);
        groups.dates[key] = [minDate];
        const date2 = new Date(minDate);
        while (date2.getTime() < maxDate) {
            date2.setDate(date2.getDate() + 1);
            groups.dates[key].push(date2.getTime());
        }
    }

    // for (const key in groups.dates) {
    //     //Sort availabilities
    //     for (let iDate = 0; iDate < groups.dates[key].length; iDate++) {
    //         const dt = groups.dates[key][iDate];
    //         const av = [...groups[dt][key]];
    //         if (av) {
    //             groups[dt][key] = arraySortExt(av, [{ fieldName: "AppointmentDateTime", ascending: true }])
    //         }
    //     }

    //     //Build up dates from min to max

    //     const minDate = Math.min(...groups.dates[key]);
    //     const maxDate = Math.max(...groups.dates[key]);
    //     groups.dates[key] = [minDate];
    //     const dt2 = new Date(minDate);
    //     while (dt2.getTime() < maxDate) {
    //         dt2 = new Date(dt2.toDateString());
    //         dt2.setDate(dt2.getDate() + 1)
    //         groups.dates[key].push(dt2.getTime());
    //     }
    // }


    return groups;
};