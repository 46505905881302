// @mui
import { GlobalStyles as MUIGlobalStyles } from "@mui/material";
import { reactContainerName, reactScrollParentName } from "../utils/Constants";
import { isInjectedNonIframe } from "../hooks/useIsInjected";

export default function GlobalStyles() {
  const styles: any = {
    [`#${reactContainerName} *`]: {
      boxSizing: "border-box",
    },
    [`#${reactContainerName} input`]: {
      "&[type=number]": {
        MozAppearance: "textfield",
        "&::-webkit-outer-spin-button": {
          margin: 0,
          WebkitAppearance: "none",
        },
        "&::-webkit-inner-spin-button": {
          margin: 0,
          WebkitAppearance: "none",
        },
      },
    },
    [`#${reactContainerName} img`]: {
      display: "block",
      maxWidth: "100%",
    },
    [`#${reactContainerName} ul`]: {
      margin: 0,
      padding: 0,
    },
    [`#${reactContainerName}>.MuiScopedCssBaseline-root`]: {
      width: "100%",
      height: "100%",
    },
    [`#${reactContainerName}`]: {
      width: "100%",
      height: "100%",
    },
    [`#${reactScrollParentName}`]: {
      width: "100%",
      height: "100%",
    }
  };

  if(!isInjectedNonIframe()) {
    styles.html = {
      margin: 0,
      padding: 0,
      width: "100%",
      height: "100%",
      WebkitOverflowScrolling: "touch",
    };
    styles.body = {
      margin: 0,
      padding: 0,
      width: "100%",
      height: "100%",
    }
  }

  const inputGlobalStyles = (
    <MUIGlobalStyles
      styles={styles}
    />
  );

  return inputGlobalStyles;
}
