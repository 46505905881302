import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IProviderWithAppointment,
  IProvider,
  IAppointment,
  ProviderState
} from "../../models";



const initialState: ProviderState = {
  provider: {} as IProvider,
  appointments: [],
};

export const providerSlice = createSlice({
  name: "provider",
  initialState,
  reducers: {
    onSelectProvider: (
      state,
      action: PayloadAction<IProviderWithAppointment | null>
    ) => {
      if (action.payload) {
        state.provider = action.payload?.provider;
        state.appointments = action.payload?.appointments;
      } else state = initialState;
    },
    onResetProvider: (state) => {
      state = initialState;
    },
  },
});

export const { onSelectProvider, onResetProvider } = providerSlice.actions;

export default providerSlice.reducer;
