import Box from "@mui/material/Box";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import IconButton from "@mui/material/IconButton/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { SxProps, useTheme } from "@mui/material/styles";
import { AppBar, Toolbar, useMediaQuery } from "@mui/material";
import { useAppSelector } from "../../../app/hooks";
import { isInjectedNonIframe } from "../../../hooks/useIsInjected";
import { isMinimalOrEmbed } from "../../../hooks/useIsMinimalOrEmbed";
import "./Header.css";
import { getFormattedDay } from "../../../utils/Util";
import { mainHeaderRef, subHeaderRef } from "../../../layouts/main/MainLayout";
import { useCallback } from "react";

interface IHeaderProps {
  providerCount: number;
  calendarInfo: string;
  onClickNext: () => void;
  onClickPrev: () => void;
  selectLength: number;
  totalLength: number,
  dates : any[],
  isDisableButton?: boolean,
}

const Header = ({
  providerCount,
  calendarInfo,
  onClickNext,
  onClickPrev,
  totalLength,
  selectLength,
  dates,
  isDisableButton: rawIsDisableButton
}: IHeaderProps) => {
  const isDisableButton: boolean = rawIsDisableButton === undefined ? false : rawIsDisableButton;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const availabilities = useAppSelector(st => st.availability.availabilities)
  const minimal = isMinimalOrEmbed();
  let strtDate = new Date().toDateString()
  let endDate = '---'
  let year = ''
  const state = useAppSelector(state => state)

if ( availabilities?.dates?.FIRST_AVAILABLE) {
  // strtDate =  getFormattedDate(availabilities?.dates?.FIRST_AVAILABLE[selectLength])
  // const eDate = availabilities?.dates?.FIRST_AVAILABLE[selectLength+20] ? availabilities?.dates?.FIRST_AVAILABLE[selectLength+20] : availabilities?.dates?.FIRST_AVAILABLE[availabilities?.dates?.FIRST_AVAILABLE.length -1]
  // endDate = getFormattedDate(eDate)
  var pageNum = selectLength > 0 ? selectLength / 20 : 0
/*   var d = new Date()

  d.setDate(d.getDate() + (pageNum * 20))
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0); 
  var day = d.getDay()
  // Get the first day of the week with the current date
  var diff = d.getDate() - day + (day == 0 ? -6:0);  
    let sdate =  new Date(d.setDate(diff));
  */
  let sdate =  new Date(dates[pageNum]);
   strtDate = getFormattedDay(sdate.getTime())
   sdate.setDate(sdate.getDate() + 20); 
   endDate = getFormattedDay(sdate.getTime())

  year = sdate.toDateString().slice(10,15)  
  providerCount = availabilities.providers.length
  calendarInfo = !isNaN(sdate.getTime()) ? `${strtDate} - ${endDate}, ${year} ` : ""
}

  const BackwardButton = (): JSX.Element => {
    return (
      <IconButton
        id="button-prev-calendar-landing-page"
        className="button-prev-calendar-landing-page"
        aria-label="navigate calendar prev"
        sx={{
          strokeWidth: 2,
          stroke: selectLength <= 0 ? "gray" :"black",
        }}
        onClick={onClickPrev}
        disabled={selectLength <= 0}
      >
        <NavigateBeforeIcon />
      </IconButton>
    );
  };
  const ForwardButton = (): JSX.Element => {
    return (
      <IconButton
        id="button-next-calendar-landing-page"
        className="button-next-calendar-landing-page"
        aria-label="navigate calendar next"
        sx={{
          strokeWidth: 2,
          // stroke: isDisableButton ? "gray" :"black",
          stroke: (selectLength + 20 ==totalLength) ? "gray" :"black",
        }}
        onClick={onClickNext}
        disabled={(selectLength + 20 ==totalLength)}
      >
        <NavigateNextIcon />
      </IconButton>
    );
  };
  const ProviderStatusSmall = (): JSX.Element => {
    //const scrollPosition = useScrollPosition();

    return (
      <Box
        sx={{
          boxShadow: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderBottomLeftRadius: { md: "40px", xs: "40px" },
          borderBottomRightRadius: { md: "40px", xs: "40px" },
          p: 0.75,
          //margin: scrollPosition < whenToScroll ? "0 -16px" : "0",
          margin: { xs: "0", sm: "0 -24px", md: "0 -24px", lg: "0", xl: "0" },
        }}
      >
      {state.embed.ProviderAgnostic === 0 && <>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: "500",
          }}
        >
          {providerCount > 0 && <>
          {`${providerCount} Provider(s) `}
          <Box component={"span"} fontWeight="500">
            at this location 
          </Box>
          </>}
        </Typography>
      </>}
 
      </Box>
    );
  };
  const ProviderStatusBig = (): JSX.Element => {
    return (
      
      <Typography
        variant="h5"
        sx={{
          fontWeight: "500",
          ml: { xl: 3 },
        }}
      >
        {providerCount > 0 && <>
        {`${providerCount} Provider(s) `}
        <Box component={"span"} fontWeight="500">
          at this location
        </Box>
        </>}
      </Typography>
    );
  };

  const CalendarInfo = (): JSX.Element => {
    return (
      <Box>
        <span className="providerDate">
          <Typography
            variant="h6"
            fontWeight={"600"}
            sx={{ fontSize: { xs: "16px", xl: "25px" } }}
          >
            {calendarInfo}
          </Typography>
        </span>
      </Box>
    );
  };
  const CalendarNavigationSmall = (): JSX.Element => {
    //const scrollPosition = useScrollPosition();
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderTop: "1px solid black",
          borderBottom: "0.5px solid black !important",
          //margin: scrollPosition < whenToScroll ? "0 -16px" : "0",
          margin: { xs: "0", sm: "0 -24px", md: "0 -24px", lg: "0", xl: "0" },
        }} 
      >
        <BackwardButton />
        <CalendarInfo />
        <ForwardButton />
      </Box>
    );
  };
  const ProviderInfoSmall = (): JSX.Element => {
    const injectedNonIframe = isInjectedNonIframe();

    const createStyle = useCallback(() => {
      const mainHeaderHeight = mainHeaderRef.current ? mainHeaderRef.current.clientHeight + 9 : 0;
      const style: SxProps = {
        bgcolor: "inherit",
        color: "inherit",
        background: "white",
        position: injectedNonIframe ? "absolute" : "fixed",
        width: "100%",
        top: mainHeaderHeight,
      };

      if (injectedNonIframe) {
        style.left = "0";
        style.right = "auto";
        style.width = "calc(100% - 15px)";
      }

      return style;
    }, [mainHeaderRef.current?.clientHeight]);
    const appBarStyle = createStyle();

    return (
      <>
        <AppBar
          elevation={0}
          sx={appBarStyle}
          ref={subHeaderRef}
        >
          <Toolbar
            sx={{
              display: "block",
              border: "none",
              background: "white",
              position: "relative",
              width: "100%",
              padding: 0
            }}
          >
            <CalendarNavigationSmall />
            <ProviderStatusSmall />
          </Toolbar>
        </AppBar>
      </>
    );
  };
  const ProviderInfoBig = (): JSX.Element => {
    return (
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            boxShadow: 3,
            borderBottomLeftRadius: "40px",
            borderBottomRightRadius: "40px",
            p: 1.5,
            transform: "translate(25px, -25px)",
          }}
        >
          {state.embed.ProviderAgnostic === 0 && <>
            <span className="providerText"><ProviderStatusBig /></span>
          </>} 
          <CalendarInfo />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <BackwardButton />
            <ForwardButton />
          </Box>
        </Box>
      </Container>
    );
  };
  return isSmallScreen ? <ProviderInfoSmall /> : <ProviderInfoBig />;
};
export default Header;
