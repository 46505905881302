// ErrorService.ts
import React from "react";
import ReactDOM from "react-dom";
import CustomAlert from "../components/errorpage/CustomAlert";
import { getInjectedParams } from "../models/InjectedParams";

class ErrorService {
  static showErrorAlert(message: string, error: any) {
    const getPath = (): string => {
      let path: string;
      
      const injectedParams = getInjectedParams();
      if (
        injectedParams &&
        injectedParams.injected &&
        injectedParams.scripturl
      ) {
        path = injectedParams.scripturl;
      } else {
        path = window.location.host;
      }
      return path;
    };

    type EnvironmentType = "local" | "dev" | "qa" | "stage" | "prod";
    const getEnvType = (): EnvironmentType => {
      let envType: EnvironmentType;
      const path = getPath();
      switch (true) {
        case path.includes("local"):
          envType = "local";
          break;
        case path.includes("qa"):
        case path.includes("mango-ocean"):
          envType = "qa";
          break;
        case path.includes("stage"):
          envType = "stage";
          break;
        case path.includes("dev"):
        case path.includes("brave-flower"):
          envType = "dev";
          break;
        default:
          envType = "prod";
      }
      return envType;
    };
    const envType = getEnvType();
    if (envType !== "prod") {
      console.log(JSON.stringify(error));
    }

    const div = document.createElement("div");
    document.body.appendChild(div);

    const closeModal = () => {
      ReactDOM.unmountComponentAtNode(div);
      div.remove();
    };

    ReactDOM.render(
      <CustomAlert message={message} onClose={closeModal} />,
      div
    );
  }
}

export default ErrorService;
