import { SessionModel } from "./Session"

export interface SaveCustomQuestion {
  sessionObject: SessionModel,
  notes: string | number | undefined | null,
  pRefB: string | number | undefined | null,
  pRefB1: string | number | undefined | null,
  pRefB2: string | number | undefined | null,
  pRefB3: string | number | undefined | null,
  pRefB4: string | number | undefined | null,
  pRefB5: string | number | undefined | null
}

export function mapSessionDataForSaveCustomQuestion(session: SessionModel): any {
  return {
    "logKey": session.LogKey ?? 0,
    "coverKey": session.CoverKey ?? 0,
    "groupKey": session.GroupKey ?? 0,
    "locKey": session.LocKey ?? 0,
    "acctKey": session.AcctKey ?? 0,
    "addKey": session.AddKey ?? 0,
    "taskKey": session.TaskKey ?? 0,
    "apptKey": session.ApptKey ?? 0,
    "apptDateTime": session.ApptDateTime ?? 0,
    "currentPage": session.CurrentPage ?? 0
  };
}
