import Demographics, { mapDemographicsFromApi, mapDemographicsToApi } from '../models/Demographics'
import HashReturnObject from '../models/HashReturnObject';
import { getBaseAPI } from "../environments/Environments";
import { StateModel } from '../models/StateModel';
import { mapSessionData, SessionModel } from '../models/Session';
import { apiFetch } from './ServiceHelper';
import ErrorService from "./ErrorService";

const DemographicsService = {
    getDemographics: (state: StateModel): Promise<HashReturnObject<Demographics>> => {
        return new Promise(async (resolve, reject) => {
            const url = await getBaseAPI() + '/api/v1/Patient/getPatient_Demographics/';
            // const url = 'https://localhost:5001/api/v1/Patient/getPatient_Demographics/';
            apiFetch(url, {
                method: 'POST',
                body: JSON.stringify(mapSessionData(state.session)),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((result) => Promise.resolve(result))
                .then((hashReturnObject: HashReturnObject<Demographics>) => {
                    if (hashReturnObject.hasOwnProperty('error')) {
                        ErrorService.showErrorAlert('Oops! Something went wrong. Please try again later. If the issue persists, please contact our support team for assistance.', hashReturnObject);
                    }
                        resolve({
                            data: [ mapDemographicsFromApi(hashReturnObject.data[0]) ],
                            hash: hashReturnObject.hash,
                            sessionId: hashReturnObject.sessionId
                        });
                })
                .catch((e) => {
                    console.log('Demographics Service error');
                    reject(e);
                });
        });
    },
    saveDemographics: (demographicsNew: Demographics, session: SessionModel): Promise<Demographics> => {
        return new Promise( async (resolve, reject) => {
            const url = await getBaseAPI() + '/api/v1/Patient/savePatient_Demographics';
            //  const url = 'https://localhost:5001/api/v1/Patient/savePatient_Demographics';
            apiFetch(url, {
              method: 'POST',
              body: JSON.stringify(mapDemographicsToApi(demographicsNew, session)),
              headers: {
                'Content-Type': 'application/json'
            }
            })
                .then((result) => Promise.resolve(result))
                .then((demographics) => {
               
                    if (demographics.hasOwnProperty('error')) {
                        ErrorService.showErrorAlert('Oops! Something went wrong. Please try again later. If the issue persists, please contact our support team for assistance.', demographics);
                    }
                    resolve(demographics)
            })
                .catch((e) => {
                    console.log('Demographics Service error');
                    reject(e);
                });
        });
    }
}
export default DemographicsService;