// import { useMemo } from "react";
// @mui
import { CssBaseline, ScopedCssBaseline } from "@mui/material";
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
  responsiveFontSizes,
} from "@mui/material/styles";

//
// import palette from "./palette";
// import shadows from "./shadows";
// import typography from "./typography";
import GlobalStyles from "./globalStyles";
import { useContext } from "react";
import { ReactContainerSizeContext } from "./reactContainerSizeProvider";
import { isInjectedNonIframe } from "../hooks/useIsInjected";
// import customShadows from "./customShadows";

//ordinal size categories
const sizeCats = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1024,
  xl: 1536
}

interface IThemeProviderProps {
  children?: React.ReactNode;
}

const ThemeProvider = ({ children }: IThemeProviderProps) => {
  const containerSize = useContext(ReactContainerSizeContext);

  const sizeCategory: number = containerSize.width < sizeCats.sm ? sizeCats.xs :
                               (containerSize.width < sizeCats.md ? sizeCats.sm :
                               (containerSize.width < sizeCats.lg ? sizeCats.md :
                               (containerSize.width < sizeCats.xl ? sizeCats.lg :
                               sizeCats.xl)));


  const defaultTheme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: sizeCategory <= sizeCats.xs ? 100000 : sizeCats.sm,
        md: sizeCategory <= sizeCats.sm ? 100001 : sizeCats.md,
        lg: sizeCategory <= sizeCats.md ? 100002 : sizeCats.lg,
        xl: sizeCategory <= sizeCats.lg ? 100003 : sizeCats.xl
      }
    }
  });

  // const defaultTheme = createTheme({
  //   breakpoints: {
  //     values: {
  //       xs: 0,
  //       sm: 600,
  //       md: 900,
  //       lg: 1024,
  //       xl: 1536
  //     }
  //   }
  // });

  const theme = responsiveFontSizes(defaultTheme);
  return (
    <ConditionalBaseline>
      <StyledEngineProvider injectFirst>
        <MUIThemeProvider theme={theme}>
          {!isInjectedNonIframe() &&
            <CssBaseline />
          }
          <GlobalStyles />
          {children}
        </MUIThemeProvider>
      </StyledEngineProvider>
    </ConditionalBaseline>
  );
};

const ConditionalBaseline = ({ children }: IThemeProviderProps) => {
  return isInjectedNonIframe() ? <ScopedCssBaseline>{children}</ScopedCssBaseline> : <>{children}</>
}

export const useMediaClasses = (): string => {
  const { width } = useContext(ReactContainerSizeContext);
  const classes: string[] = [];
  if(width < sizeCats.sm) {
    classes.push("media-xs");
    classes.push("media-sm-down");
    classes.push("media-md-down");
    classes.push("media-lg-down");
    classes.push("media-xl-down");
    classes.push("media-xs-up");
  } else if(width < sizeCats.md) {
    classes.push("media-sm");
    classes.push("media-sm-down");
    classes.push("media-md-down");
    classes.push("media-lg-down");
    classes.push("media-xl-down");
    classes.push("media-xs-up");
    classes.push("media-sm-up");
  } else if(width < sizeCats.lg) {
    classes.push("media-md");
    classes.push("media-md-down");
    classes.push("media-lg-down");
    classes.push("media-xl-down");
    classes.push("media-xs-up");
    classes.push("media-sm-up");
    classes.push("media-md-up");
  } else if(width < sizeCats.xl) {
    classes.push("media-lg");
    classes.push("media-lg-down");
    classes.push("media-xl-down");
    classes.push("media-xs-up");
    classes.push("media-sm-up");
    classes.push("media-md-up");
    classes.push("media-lg-up");
  } else {
    classes.push("media-xl");
    classes.push("media-xl-down");
    classes.push("media-xs-up");
    classes.push("media-sm-up");
    classes.push("media-md-up");
    classes.push("media-lg-up");
  }

  //special
  if(width >= 600 && width <= 820) {
    classes.push("media-600-820");
  }
  if(width >= 600 && width <= 880) {
    classes.push("media-600-880");
  }
  if(width >= 600 && width <= 1023) {
    classes.push("media-600-1023");
  }
  if(width >= 621 && width <= 900) {
    classes.push("media-621-900");
  }
  if(width >= 768 && width <= 900) {
    classes.push("media-768-900");
  }
  if(width >= 881 && width <= 1350) {
    classes.push("media-881-1350");
  }
  if(width >= 720 && width <= 1024) {
    classes.push("media-720-1024");
  }
  if(width >= 901 && width <= 1024) {
    classes.push("media-901-1024");
  }

  if(width <= 324) {
    classes.push("media-324-down");
  }
  if(width <= 392) {
    classes.push("media-392-down");
  }
  if(width <= 580) {
    classes.push("media-580-down");
  }
  if(width <= 620) {
    classes.push("media-620-down");
  }
  if(width <= 667) {
    classes.push("media-667-down");
  }
  if(width <= 768) {
    classes.push("media-768-down");
  }

  if(width >= 621) {
    classes.push("media-621-up");
  }
  if(width >= 668) {
    classes.push("media-668-up");
  }
  if(width >= 720) {
    classes.push("media-720-up");
  }
  if(width >= 1400) {
    classes.push("media-1400-up");
  }
  

  return classes.join(" ");
}

export default ThemeProvider;
