import { createSlice,PayloadAction } from "@reduxjs/toolkit";


const initialState = {
    AcctKey:'',
    AddKey:'',
    ApptKey:'',
    CoverKey:'',
    Hash:'',
    LocKey:'',
    TaskKey:''
}

export const hasherSlice = createSlice({
    name:'hash',
    initialState,
    reducers: {
        setHashforState : (state, action)=> {
            if (action.payload) {
                state.Hash = action.payload
            }
        },
        setHashProperties : (state,action)=> {
            if (action.payload) {
                state.AcctKey = action.payload.AcctKey ? action.payload.AcctKey : state.AcctKey;
                state.AddKey = action.payload.AddKey ? action.payload.AddKey : state.AddKey;
                state.ApptKey = action.payload.ApptKey ? action.payload.ApptKey : state.ApptKey;
                state.CoverKey = action.payload.CoverKey ? action.payload.CoverKey : state.CoverKey;
                state.Hash = action.payload.Hash ? action.payload.Hash : state.Hash;
                state.LocKey = action.payload.LocKey ? action.payload.LocKey : state.LocKey;
                state.TaskKey = action.payload.TaskKey ? action.payload.TaskKey : state.TaskKey;
            }
        }
    }

})
export const {setHashforState, setHashProperties} = hasherSlice.actions
export default hasherSlice.reducer