import Patient, { mapPatientDataForRetrieve, mapPatientDataForSave, mapPatientDetailsData } from '../models/Patient';
import HashReturnObject from '../models/HashReturnObject';
import { getBaseAPI } from '../environments/Environments';
import { SessionModel } from '../models/Session';
import { apiFetch, apiFetchRaw } from './ServiceHelper';
import ErrorService from "./ErrorService";

const PatientService = {
    getPatient: (patient: Patient, session: SessionModel): Promise<HashReturnObject<Patient>> => {
        return new Promise( async (resolve, reject) => {
            const url = await getBaseAPI() + '/api/v1/Patient/getPatient_Info';
            // const url = 'https://localhost:5001/api/v1/Patient/getPatient_Info';
            const patientData = mapPatientDataForRetrieve(patient, session);
            const payload = JSON.stringify(patientData);
            const response = await apiFetchRaw(url, {
                method: "POST",
                body: payload,
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await response.json();

            if (!response.ok) {
                reject(data);
                return;
            }

            if (response && response.hasOwnProperty('error')) {
                ErrorService.showErrorAlert('Oops! Something went wrong. Please try again later. If the issue persists, please contact our support team for assistance.', response);
            }

            const patients: Patient[] = [];
            data?.data?.forEach((data: any) => {
                patients.push(mapPatientDetailsData(data));
            });
            const responseObject: HashReturnObject<Patient> = {
                data: patients,
                hash: data.hash,
                sessionId: data.sessionId,
            };
            resolve(responseObject);
        });
    },
    savePatient: (patient: Patient, session: SessionModel): Promise<HashReturnObject<Patient>> => {
        return new Promise( async (resolve, reject) => {
            const url = await getBaseAPI() + '/api/v1/Patient/savePatient_Info';
            const patientData = mapPatientDataForSave(patient, session);
            const payload = JSON.stringify(patientData);
            apiFetch(url, {
                method: 'POST',
                body: payload,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((result) => Promise.resolve(result))
                .then((hashReturnObject: any) => {
                    const patients: Patient[] = [];
                    patients.push(mapPatientDetailsData(hashReturnObject.data));
                    
                    const responseObject: HashReturnObject<Patient> = {
                        data: patients,
                        hash: hashReturnObject.hash,
                        sessionId: hashReturnObject.sessionId
                    }
                    resolve(responseObject);
                })
                .catch((e) => {
                    console.log('Patient Service error');
                    reject(e);
                });
        });
    }
}
export const persistPatient = (session: SessionModel, currentPatient:  any): Promise<HashReturnObject<Patient>> => {
    return new Promise((resolve) => {
        PatientService.getPatient(currentPatient, session).then((hashReturnObject: HashReturnObject<Patient>) => {
            // const sessionPatient = (hashReturnObject.data[0] === undefined || hashReturnObject.data[0] === null)
            //     ? PatientService.savePatient(currentPatient, session)
            //     : Promise.resolve(hashReturnObject);
            resolve(Promise.resolve(hashReturnObject));
        });
    });
}
export default PatientService;