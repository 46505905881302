import { useAppDispatch } from "../../app/hooks";
import { updatePage } from "../../features/page/pageSlice";
import { StateRoute } from "../../models/Page";

const useStateNavigation = (): ((dest: StateRoute | number) => void) => {
  const dispatch = useAppDispatch();
  const navigate = (dest: StateRoute | number): void => {
    dispatch(updatePage(dest))
  };
  return navigate;
}

export default useStateNavigation;
