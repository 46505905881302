import { useAppSelector } from "../../app/hooks";
import MainLayout from "../../layouts/main/MainLayout";
import { StateRoutesGroup } from "../../routes/StateRoutes";

const useStateRoutes = (routes: StateRoutesGroup): JSX.Element => {
  const state = useAppSelector(state => state);
  const page = state.page;
  const routeComponent: JSX.Element = routes.routes.find(route => route.name === page.route)?.element ?? <></>
  return <MainLayout route={page.route}>
    {routeComponent}
  </MainLayout>
}

export default useStateRoutes;
