export default interface LocationDetails {
    LocKey: string | null | undefined;
    GroupKey: string | null | undefined;
    Name: string ;
    Address: string | null | undefined;
    Proximity: string | null | undefined;
    Lat: string;
    Long: string;
    PhoneNumberFormated: string;
    Logo: string;
    Email: string;
}

export interface LocationDetailsData {
    locKey: string | null | undefined;
    groupKey: string | null | undefined;
    name: string;
    address: string | null | undefined;
    proximity: string | null | undefined;
    lat: string;
    long: string;
    phoneNumberFormated: string;
    logo: string;
    email: string;
}

export function mapLocationData(data: LocationDetailsData): LocationDetails {
    return {
        LocKey: data.locKey,
        GroupKey: data.groupKey,
        Name: data.name,
        Address: data.address,
        Proximity: data.proximity,
        Lat: data.lat,
        Long: data.long,
        PhoneNumberFormated: data.phoneNumberFormated,
        Logo: data.logo,
        Email: data.email
    }
}