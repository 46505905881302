import { useEffect, useState } from "react";
import { Box, Typography, Grid, Button, Select, MenuItem } from "@mui/material";
import Spinner from '../../components/spinner/Spinner';
import './patientDemographicsStyle.css';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import DemographicsService from '../../services/DemographicsService';
import { updateProvider } from "../../features/demographics/demographicsSlice";
import Demographics from "../../models/Demographics";
import DemographicsUserInput, { emptyDemographicsUserInput, mapDemographicsToUserInput, validateDemographicsUserInput } from "../../models/DemographicsUserInput";
import { genDerValidation, PageName, NavigatePage, getFormattedDateTime } from "../../utils/Util";
import useApptConfirmNav from "../hooks/useApptConfirmNav";
import { Event , PageView} from '../../utils/GA';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { updateCurrentPage } from "../../features/session/sessionSlice";
import { updatePatient } from '../../features/patient/patientSlice';  

import useStateNavigation from "../hooks/useStateNavigation";
import { stateRoutes } from "../../models/Page";

export const PatientDemographics = () => {
    const [demographics, setDemographics] = useState<DemographicsUserInput>(emptyDemographicsUserInput());
    const [isReady, setIsReady] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);
    const[isLanguage,setIsLanguage]= useState(false);
    const [readOnlyLanguage, setReadOnlyLanguage] = useState<boolean>(false);
    const [isDemographicsValid, setIsDemographicsValid] = useState<boolean>(false);
    const[languageValue,setLanguageValue]= useState("");
    const [selectedSize, setSelectedSize] = useState();
    const [readOnlyAddress1, setReadOnlyAddress1] = useState<boolean>(false);
    const [readOnlyAddress2, setReadOnlyAddress2] = useState<boolean>(false);
    const [readOnlyCity, setReadOnlyCity] = useState<boolean>(false);
    const [readOnlyState, setReadOnlyState] = useState<boolean>(false);
    const [readOnlyZip, setReadOnlyZip] = useState<boolean>(false);
    const [readOnlyCountry, setReadOnlyCountry] = useState<boolean>(false);
    const [readOnlyCel, setReadOnlyCel] = useState<boolean>(false);
    const [newDemographics, setNewDemographics] = useState<Demographics>({} as any)
    const[genderValue,setGenderValue]= useState("");
    const state = useAppSelector(state => state)
    const[providerAgnostic,setProviderAgnostic]= useState<boolean>(false);
    const patientFirstName = state.patient.FirstName;
    const selectTime = state.availability.availability
    const dDate = new Date(selectTime.AppointmentDate).toDateString()
    const dTime = (selectTime.AppointmentDateTime)
    const [capName, setCapName] = useState("");
    const [locationName, setLocationName] = useState("");
    const[selectderGender,setSelectedGender] =useState("");
    const [skipVisionInsurance, setSkipVisionInsurance] = useState<boolean>(false);
    const appointmentType = state.appointmentData.appointmentType.Name
    const providerFirstname = state.providers.provider.FirstName
    const ProviderLastName = state.providers.provider.LastName
    const providerDegree = state.providers.provider.Degree
    const patientLastName = state.patient.LastName;
    const patientEmail = state.patient.Email || state.session.EmailAddress || demographics.Email.value;
    // TODO: Fix validation checking. Validation check does not happen on input, only on submit.
    // const patientEmail =
    //     (!demographics.Email.error && demographics.Email.value) ||
    //     state.patient.Email;
    const navigate = useStateNavigation();

    //If appointment confirmation is complete, force user back to the confirmation screen
    useApptConfirmNav();

    let disAbledInput = 'disabled-input';

    const stateAmerica = ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida",
        "Georgia",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming"]

    const dispatch = useAppDispatch();
    const Language =["English","Spanish"]
    useEffect(() => {
        dispatch(updateCurrentPage({CurrentPage :  PageName.PatientDemographics}))  
        PageView(
            `pageview_patient_demographics_screen`, state.session.CoverKey?.toString() ,state.session.LocKey?.toString(), state.session.AcctKey?.toString()
        )
    },[])

    useEffect(() => {
        const provider = state.providers.providers.find((x) => x.AcctKey == state.availability?.availability.AcctKey);
        DemographicsService.getDemographics({ ...state, session: { ...state.session, AcctKey: provider?.AcctKey } })
            .then((res) => {
                dispatch(updateProvider(res.data[0]));
                setLoading(false)
                setNewDemographics(res.data[0]);
                const mapped = mapDemographicsToUserInput(res.data[0]);
                if(res.data[0].Config.ProviderAgnostic ===0)
                {
                     setProviderAgnostic(true);
                }
                else{
                 setProviderAgnostic(false);
                }

                if (String(mapped.Lang.value) === "0") {

                    setIsLanguage(true);
                }
                if (String(mapped.Lang.value) === "1") {
                    setIsLanguage(true);
                }

                // Retain email from select appointment form.
                mapped.Email.value = state.patient.Email || state.session.EmailAddress || res.data[0]?.Email || "";

                // Check email if blank from the DB. If true then fill it from with email from the Patient Information Page
                // if (mapped.Email.value.trim().length === 0)
                //     mapped.Email.value = state.session.EmailAddress ?? ""

                if (state.embed.ReferredBy)
                    mapped.ReferredBy1.value =  state.embed.ReferredBy ?? ""

                setDemographics(mapped);
                setIsReady(true);
                const genderVal = genDerValidation(Number(res.data[0].Gender));
                setGenderValue(String(genderVal));
                // if (mapped.Address1.enabled !== -1 && mapped.Address1.value.trim().length !== 0) setReadOnlyAddress1(true);
                // if (mapped.Address2.enabled !== -1 && mapped.Address2.value.trim().length !== 0) setReadOnlyAddress2(true);
                // if (mapped.City.enabled !== -1 && mapped.City.value.trim().length !== 0) setReadOnlyCity(true);
                // if (mapped.State.enabled !== -1 && mapped.State.value.trim().length !== 0) setReadOnlyState(true);
                // if (mapped.Lang.enabled !== -1 && String(mapped.Lang.value)  !== "-1") setReadOnlyLanguage(true);
                // if (mapped.Zip.enabled !== -1 && mapped.Zip.value.trim().length !== 0) setReadOnlyZip(true);
                if (mapped.Country.enabled !== -1 && mapped.Country.value.trim().length !== 0) setReadOnlyCountry(true);
                // if (mapped.Cel.enabled !== -1 && mapped.Cel.value.trim().length !== 0) setReadOnlyCel(true);
                //dispatch(setSessionState({...state.session,CurrentPage :  PageName.PatientDemographics}))
            }).catch((e) => {
                // console.log('request error', e);
                console.log('Patient Demographics error');
            });
        capitalize(patientFirstName);
        // alert(demographics.ReferredBy1.enabled === -1)
   


    }, [state.session.AddKey])

    // useEffect(() => {
    //     setLoading(true)
    //     PatientInsuranceService.getPatientInsurance({ ...state })
    //         .then((response) => {
    //             const patientInsuranceData = response.data[0];
    //             setLoading(false)
    //             if (patientInsuranceData.Config.v4DMedInsSect === -1 && patientInsuranceData.Config.v4DVisInsSect === -1 ) {
    //                 setSkipVisionInsurance(true)
    //             }
    //         });
    // }, []);
/*     useEffect(() => {
        const validatedDemographics = validateDemographicsUserInput(demographics);
        const isValid = state.demographics != null &&
            (!validatedDemographics.FirstName.error && !validatedDemographics.LastName.error &&
                !validatedDemographics.Home.error && !validatedDemographics.Email.error && !validatedDemographics.DOB.error
                && !validatedDemographics.Cel.error && !validatedDemographics.Address1.error && !validatedDemographics.Address2.error
                && !validatedDemographics.City.error && !validatedDemographics.State.error && !validatedDemographics.Country.error
                && !validatedDemographics.Zip.error && !validatedDemographics.ReferredBy1.error && !validatedDemographics.ReferredBy2.error
                && !validatedDemographics.ReferredBy3.error && !validatedDemographics.ReferredBy4.error && !validatedDemographics.ReferredBy5.error
                && !validatedDemographics.L4DSSN.error);
        setIsDemographicsValid(isValid);
    }, [
        demographics.FirstName, demographics.LastName, demographics.DOB, demographics.Email, demographics.Home, demographics.Cel,
        demographics.Address1, demographics.Address2, demographics.State, demographics.Zip, demographics.City, demographics.Country,
        demographics.ReferredBy1, demographics.ReferredBy2, demographics.ReferredBy3, demographics.ReferredBy4, demographics.ReferredBy5,demographics.L4DSSN
    ]); */

    const updateDemographics = (currentDemographics: any, key: string, value: string): any => {
        return {
            ...currentDemographics,
            [key]: {
                ...currentDemographics[key],
                value: value
            }
        };
    }

    useEffect(() => {
        if (state.availability.getInitialLocation)
            setLocationName(state.availability.getInitialLocation.Name) 
    }, [state.availability.getInitialLocation]);

    const onChangeSetForm = (e: any, key: string) => {
       
        
        let onlyDigits = e.target.value.replace(/\D/g, "");
        let onlyNonSpecialChar = e.target.value.replace(/[^\w\s]/gi, "");

        // ONLY DIGITS ARE ALLOWED 
        if(key==="Cel" ||  key === "Home" || key==="Zip" ||  key === "L4DSSN")
        {
            
            if(key==="Cel" ||  key === "Home") // invalidate prefix of 0 and 1 FOR CELL and HOME
            {
                if(e.target.value.toString().startsWith("1") || e.target.value.toString().startsWith("0")){
                    onlyDigits = "";
                 
                }
            }
            
            const updatedDemographics = updateDemographics(demographics, key, onlyDigits);
            setDemographics(updatedDemographics);
        }
        // ONLY NON-SPECIAL CHARACTERS
        else if(key==="Address1" ||  key === "Address2" ||  key === "City" || key==="ReferredBy1")
        {
           
            const updatedDemographics = updateDemographics(demographics, key, onlyNonSpecialChar);
            setDemographics(updatedDemographics);
        }
        //NO VALIDATION
        else
        {
            const updatedDemographics = updateDemographics(demographics, key, e.target.value);
            setDemographics(updatedDemographics);
        }
        
    }

    const onChangeState = (e: any, value: any) => {
        const updatedDemographics = updateDemographics(demographics, "State", e.target.value);
        setDemographics(updatedDemographics);
    }
    const onChangeLanguage = (e: any, value: any) => {
        
        
        if(e.target.value === "0"){
            setLanguageValue("0")
        }
        else if( e.target.value ==="1"){
            setLanguageValue("1")
        }
        else{
            setLanguageValue("0")
        }
        const updatedDemographics = updateDemographics(demographics, "Lang", e.target.value);
        setDemographics(updatedDemographics);
    }
    const capitalize = (data: any) => {
        
        let capitalizeName = data.charAt(0).toUpperCase() + data.slice(1);
        setCapName(capitalizeName);
    }

    const hideAddressDetails =
        demographics.Address1.enabled === -1 &&
        demographics.Address2.enabled === -1 &&
        demographics.City.enabled === -1 &&
        demographics.State.enabled === -1 &&
        demographics.Zip.enabled === -1 &&
        demographics.Country.enabled === -1;

    const hideContactDetails =
        demographics.Email.enabled === -1 &&
        demographics.Home.enabled === -1 &&
        demographics.Cel.enabled === -1 &&
        demographics.DOB.enabled === -1;

    const hideRefferals =
        demographics.ReferredBy1.enabled === -1 &&
        demographics.ReferredBy2.enabled === -1 &&
        demographics.ReferredBy3.enabled === -1 &&
        demographics.ReferredBy4.enabled === -1 &&
        demographics.ReferredBy5.enabled === -1;


    const CustomFormLabel = (props: any) => {
        return (
            <>
                <Grid container spacing={1}>
                    <Grid item xs={6} md={6} lg={6}>
                        <label className="wsui-field-label">
                            {props.formlabel}
                        </label>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} className="errormessage">
                        <>{props.message}</>
                    </Grid>
                </Grid>
            </>
        );
    };
    const renderFirstName = () => {
        return (
            demographics.FirstName.enabled >= 0 && (
                <>
                    <div className="wsui-form-control">
                        <label className="wsui-field-label">
                            {demographics.FirstName.enabled == 1
                                ? "FirstName*"
                                : "FirstName"}
                        </label>
                        <div className="wsui-error">
                            <div className="validation-error">
                                {" "}
                                {demographics.FirstName.error}{" "}
                            </div>
                            <input
                                id="input-firstname-patient-demo"
                                className="wsui-disabled-input input-firstname-patient-demo"
                                placeholder={"FirstName "}
                                onChange={(e) =>
                                    onChangeSetForm(e, "FirstName")
                                }
                                value={demographics.FirstName.value}
                                type="text"
                                maxLength={80}
                                readOnly={true}
                            />
                        </div>
                    </div>
                </>
            )
        );
    };

    const renderLastName = () => {
        return (
            demographics.LastName.enabled >= 0 &&
            <>
            <div className="wsui-form-control">
                <label className="wsui-field-label">{demographics.LastName.enabled == 1 ? "LastName*" : "LastName"}</label>
                <div className="wsui-error">
                    <div className="validation-error">  {demographics.LastName.error} </div>
                    <input
                        id="input-lastname-patient-demo"
                        className="wsui-disabled-input input-lastname-patient-demo"
                        placeholder={"LastName "}
                        onChange={(e) => onChangeSetForm(e, "LastName")}
                        value={demographics.LastName.value}
                        type="text"
                        maxLength={80}
                        readOnly={true}
                    />
                </div>
            </div>
            </>
        )
    }

    const renderAddress1 = () => {
        return (
            demographics.Address1.enabled >= 0 && (
                <div className="wsui-form-control ">
                    <CustomFormLabel
                        message={demographics.Address1.error}
                        formlabel={
                            demographics.Address1.enabled === 1
                                ? "Address 1*"
                                : "Address 1"
                        }
                    />
                    <input
                        id="input-address1-patient-demo"
                        className="input-address1-patient-demo"
                        onChange={(e) => onChangeSetForm(e, "Address1")}
                        value={demographics.Address1.value}
                        name="Address1"
                        type="text"
                        placeholder="Address1"
                        maxLength={50}
                        readOnly={readOnlyAddress1}
                    />
                </div>
            )
        );
    };

    const renderAddress2 = () => {
        return (
            demographics.Address2.enabled >= 0 && (
                <div className="wsui-form-control">
                    <CustomFormLabel
                        message={demographics.Address2.error}
                        formlabel={
                            demographics.Address2.enabled == 1
                                ? "Address 2*"
                                : "Address 2"
                        }
                    />
                    <input
                        id="input-address2-patient-demo"
                        className="input-address2-patient-demo"
                        onChange={(e) => onChangeSetForm(e, "Address2")}
                        value={demographics.Address2.value}
                        name="Address2"
                        type="text"
                        placeholder="Address1"
                        maxLength={50}
                        readOnly={readOnlyAddress2}
                    />
                </div>
            )
        );
    };

    const renderCity = () => {
        return (
            demographics.City.enabled >= 0 && (
                <Grid item xs={12} md={8}>
                    <div className="wsui-form-control">
                        <CustomFormLabel
                            message={demographics.City.error}
                            formlabel={
                                demographics.City.enabled == 1
                                    ? "City*"
                                    : "City"
                            }
                        />
                        <input
                            id="input-city-patient-demo"
                            className="input-city-patient-demo"
                            onChange={(e) => onChangeSetForm(e, "City")}
                            value={demographics.City.value}
                            name="City"
                            type="text"
                            placeholder="City"
                            maxLength={25}
                            readOnly={readOnlyCity}
                        />
                    </div>
                </Grid>
            )
        );
    };

    const renderState = () => {
        return (
            demographics.State.enabled >= 0 && (
                <Grid item xs={12} md={4}>
                    <div className="wsui-form-control">
                        <CustomFormLabel
                            message={demographics.State.error}
                            formlabel={
                                demographics.State.enabled == 1
                                    ? "State*"
                                    : "State"
                            }
                        />
                        <Select
                            id="select-state-patient-demo"
                            className={`${
                                readOnlyState ? "wsui-disabled-input" : ""
                            } select-state-patient-demo`}
                            defaultValue={demographics.State.value || "Please choose one option"}
                            onChange={(e) => onChangeState(e, "State")}
                            sx={{
                                fontWeight: "400",
                                width: "100%",
                                borderRadius: "10px",
                                "& .MuiSelect-select": {
                                    lineHeight: "normal",
                                    padding: "14px",
                                },
                            }}
                            disabled={readOnlyState}
                        >
                            <MenuItem disabled value="Please choose one option">Please choose one option</MenuItem>
                            <MenuItem value="AL">Alabama</MenuItem>
                            <MenuItem value="AK">Alaska</MenuItem>
                            <MenuItem value="AZ">Arizona</MenuItem>
                            <MenuItem value="AR">Arkansas</MenuItem>
                            <MenuItem value="CA">California</MenuItem>
                            <MenuItem value="CO">Colorado</MenuItem>
                            <MenuItem value="CT">Connecticut</MenuItem>
                            <MenuItem value="DE">Delaware</MenuItem>
                            <MenuItem value="DC">District of Columbia</MenuItem>
                            <MenuItem value="FL">Florida</MenuItem>
                            <MenuItem value="GA">Georgia</MenuItem>
                            <MenuItem value="HI">Hawaii</MenuItem>
                            <MenuItem value="ID">Idaho</MenuItem>
                            <MenuItem value="IL">Illinois</MenuItem>
                            <MenuItem value="IN">Indiana</MenuItem>
                            <MenuItem value="IA">Iowa</MenuItem>
                            <MenuItem value="KS">Kansas</MenuItem>
                            <MenuItem value="KY">Kentucky</MenuItem>
                            <MenuItem value="LA">Louisiana</MenuItem>
                            <MenuItem value="ME">Maine</MenuItem>
                            <MenuItem value="MD">Maryland</MenuItem>
                            <MenuItem value="MA">Massachusetts</MenuItem>
                            <MenuItem value="MI">Michigan</MenuItem>
                            <MenuItem value="MN">Minnesota</MenuItem>
                            <MenuItem value="MS">Mississippi</MenuItem>
                            <MenuItem value="MO">Missouri</MenuItem>
                            <MenuItem value="MT">Montana</MenuItem>
                            <MenuItem value="NE">Nebraska</MenuItem>
                            <MenuItem value="NV">Nevada</MenuItem>
                            <MenuItem value="NH">New Hampshire</MenuItem>
                            <MenuItem value="NJ">New Jersey</MenuItem>
                            <MenuItem value="NM">New Mexico</MenuItem>
                            <MenuItem value="NY">New York</MenuItem>
                            <MenuItem value="NC">North Carolina</MenuItem>
                            <MenuItem value="ND">North Dakota</MenuItem>
                            <MenuItem value="OH">Ohio</MenuItem>
                            <MenuItem value="OK">Oklahoma</MenuItem>
                            <MenuItem value="OR">Oregon</MenuItem>
                            <MenuItem value="PA">Pennsylvania</MenuItem>
                            <MenuItem value="PR">Puerto Rico</MenuItem>
                            <MenuItem value="RI">Rhode Island</MenuItem>
                            <MenuItem value="SC">South Carolina</MenuItem>
                            <MenuItem value="SD">South Dakota</MenuItem>
                            <MenuItem value="TN">Tennessee</MenuItem>
                            <MenuItem value="TX">Texas</MenuItem>
                            <MenuItem value="UT">Utah</MenuItem>
                            <MenuItem value="VT">Vermont</MenuItem>
                            <MenuItem value="VI">Virgin Islands</MenuItem>
                            <MenuItem value="VA">Virginia</MenuItem>
                            <MenuItem value="WA">Washington</MenuItem>
                            <MenuItem value="WV">West Virginia</MenuItem>
                            <MenuItem value="WI">Wisconsin</MenuItem>
                            <MenuItem value="WY">Wyoming</MenuItem>
                        </Select>
                    </div>
                </Grid>
            )
        );
    };

    const renderLanguage = () => {
        return (
             demographics.Lang.enabled >= 0 && (
                <div className="wsui-form-control">
                    <CustomFormLabel
                        message={demographics.Lang.error}
                        formlabel={
                            demographics.Lang.enabled == 1
                                ? "Language*"
                                : "Language"
                        }
                    />
                    <div className="wsui-error state-details">
                        <Select
                            id="select-language-patient-demo"
                            className="select-language-patient-demo"
                            value={selectedSize}
                            defaultValue={demographics.Lang.value}
                            onChange={(e) => onChangeLanguage(e, "Lang")}
                            sx={{
                                fontWeight: "400",
                                width: "100%",
                                borderRadius: "10px",
                                "& .MuiSelect-select": {
                                    lineHeight: "normal",
                                    padding: "14px",
                                },
                            }}
                        >
                            <MenuItem disabled value={"-1"}>
                                Please choose a Language
                            </MenuItem>
                            <MenuItem value={"0"}>English</MenuItem>
                            <MenuItem value={"1"}>Spanish</MenuItem>
                        </Select>
                    </div>
                </div>
            )
        );
    };

    const renderZip = () => {
        return (
            demographics.Zip.enabled >= 0 && (
                <Grid item xs={12} md={4}>
                    <div className="wsui-form-control">
                        <CustomFormLabel
                            message={demographics.Zip.error}
                            formlabel={
                                demographics.Zip.enabled == 1 ? "Zip*" : "Zip"
                            }
                        />
                        <input
                            id="input-zip-patient-demo"
                            className="input-zip-patient-demo"
                            onChange={(e) => onChangeSetForm(e, "Zip")}
                            value={demographics.Zip.value}
                            name="Zip"
                            type="text"
                            placeholder=""
                            maxLength={12}
                            readOnly={readOnlyZip}
                        />
                    </div>
                </Grid>
            )
        );
    };

    const renderCountry = () => {
        return (
            demographics.Country.enabled >= 0 && (
                <Grid item xs={12} md={8}>
                    <div className="wsui-form-control">
                        <label className="wsui-field-label">
                            {demographics.Country.enabled == 1
                                ? "Country*"
                                : "Country"}
                        </label>
                        <div className="wsui-error">
                            <div className="validation-error">
                                {" "}
                                {demographics.Country.error}{" "}
                            </div>
                            <input
                                id="input-country-patient-demo"
                                className={`${
                                    readOnlyCountry ? "wsui-disabled-input" : ""
                                } input-country-patient-demo`}
                                placeholder={"Country"}
                                onChange={(e) => onChangeSetForm(e, "Country")}
                                value={demographics.Country.value}
                                type="text"
                                maxLength={25}
                                readOnly={readOnlyCountry}
                            />
                        </div>
                    </div>
                </Grid>
            )
        );
    };

    const renderCel = () => {
        return (
            demographics.Cel.enabled >= 0 && (
                <Grid item xs={12} md={6}>
                    <div className="wsui-form-control">
                        <CustomFormLabel
                            message={demographics.Cel.error}
                            formlabel={
                                demographics.Cel.enabled == 1
                                    ? "Mobile Phone*"
                                    : "Mobile Phone"
                            }
                        />
                        <input
                            id="input-mobilephonenum-patient-demo"
                            className="input-mobilephonenum-patient-demo"
                            onChange={(e) => onChangeSetForm(e, "Cel")}
                            value={demographics.Cel.value}
                            name="Cel"
                            type="text"
                            placeholder=" "
                            maxLength={10}
                            readOnly={readOnlyCel}
                        />
                    </div>
                </Grid>
            )
        );
    };

    const renderHome = () => {
        return (
            demographics.Home.enabled >= 0 && (
                <Grid item xs={12} md={6}>
                    <div className="wsui-form-control">
                        <CustomFormLabel
                            message={demographics.Home.error}
                            formlabel={
                                demographics.Home.enabled == 1
                                    ? "Phone(home)*"
                                    : "Phone(home)"
                            }
                        />
                        <input
                            id="input-homephonenum-patient-demo"
                            className="input-homephonenum-patient-demo"
                            onChange={(e) => onChangeSetForm(e, "Home")}
                            value={demographics.Home.value}
                            name="Home"
                            type="text"
                            placeholder=" "
                            maxLength={10}
                        />
                    </div>
                </Grid>
            )
        );
    };

    const renderEmail = () => {
        return (
            demographics.Email.enabled >= 0 && (
            <div className="wsui-form-control">
                <CustomFormLabel
                message={demographics.Email.error}
                formlabel={
                    demographics.Email.enabled == 1
                    ? "Email Address*"
                    : "Email Address"
                }
                />
                <div className="wsui-error">
                <input
                    id="input-email-patient-demo"
                    className="input-email-patient-demo"
                    onChange={(e) => onChangeSetForm(e, "Email")}
                    value={demographics.Email.value}
                    name="Email"
                    type="text"
                    placeholder="Email Adress"
                    maxLength={100}
                />
                </div>
            </div>
            )
        );
    };

    const renderSSN = () => {
        return (
            demographics.L4DSSN.enabled >= 0 && (
                <div className="wsui-form-control">
                    <CustomFormLabel
                        message={demographics.L4DSSN.error}
                        formlabel={
                            demographics.L4DSSN.enabled == 1
                                ? "Last 4 Digits of Social Security Number*"
                                : "Last 4 Digits of Social Security Number"
                        }
                    />

                    <div className="wsui-error">
                        <input
                            id="input-l4dssn-patient-demo"
                            className="input-l4dssn-patient-demo"
                            onChange={(e) => onChangeSetForm(e, "L4DSSN")}
                            value={demographics.L4DSSN.value}
                            name="L4DSSN"
                            type="text"
                            placeholder="Last 4 Digits of Social Security Number"
                            maxLength={4}
                        />
                    </div>
                </div>
            )
        );
    };

    const renderReferredBy = () => {
        return (
            demographics.ReferredBy1.enabled >= 0 &&
            <div className="wsui-form-control ">
                <CustomFormLabel
                message={demographics.ReferredBy1.error} 
                formlabel={demographics.ReferredBy1.enabled == 1 ? "Referred By*" : "Referred By"}
                />
                <input
                    id="input-referredby-patient-demo"
                    className="input-referredby-patient-demo"
                    onChange={(e) => onChangeSetForm(e, "ReferredBy1")}
                    value={demographics.ReferredBy1.value}
                    name="ReferredBy1"
                    type="text"
                    placeholder=" "
                    maxLength={50}
                />
            </div>
        )
    }

    const renderGender = () => {
        return(
            demographics.Gender.enabled >= 0 &&
            <Grid item xs={12} md={12}>
                <div className="wsui-form-control">
                    <CustomFormLabel
                        message={demographics.Gender.error} 
                        formlabel={demographics.Gender.enabled == 1 ? "Gender*" : "Gender"} />
                    <RadioGroup
                        row
                        id="radio-gender-patient-demo"
                        className="radio-gender-patient-demo"
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={genderValue}
                        onChange={handleGender}
                    >
                        <FormControlLabel  value="male" control={<Radio />} label="Male" />
                        <FormControlLabel  value="female" control={<Radio />} label="Female" />
                        <FormControlLabel  value="other" control={<Radio />} label="Prefer not to answer" />
                    </RadioGroup>
                </div>
            </Grid>
        );
    };

    const handleGender =(event:any) =>{
        setGenderValue(event.target.value)
        const updatedDemographics = updateDemographics(demographics, "Gender", "1");
        setDemographics(updatedDemographics);
    }

    const checkGender = () => {
        if(genderValue === "male"){
            return  "1"
        }
        else if(genderValue === "female"){
            return  "0"
        }
        else if(genderValue === "other"){
            return  "2"
        }
        else{
            return null
        }
    }

    const handleClick = (data: any) => { 
    
        setIsDemographicsValid(false);
        if(languageValue === ""){
            setLanguageValue("-1");
        }
        const valGender = checkGender();
        let newVal = {
            ...newDemographics,
            FirstName: demographics.FirstName.value,
            Address1: demographics.Address1.value,
            Address2: demographics.Address2.value,
            City: demographics.City.value,
            State: demographics.State.value,
            Zip: demographics.Zip.value,
            Home: demographics.Home.value,
            Cel:demographics.Cel.value,
            Email: demographics.Email.value,
            Country: demographics.Country.value,
            ReferredBy1: demographics.ReferredBy1.value,
            Gender: valGender,
            L4DSSN: demographics.L4DSSN.value,  
            Lang:languageValue,         
        }

        const newPatientVal = { ...state.patient, Home:demographics.Home.value, Email: demographics.Email.value  }
       
        if (state.demographics) {
            //GA-07
            Event(
                false,
                'click_continue_patient_demo_screen',
                state.session.CoverKey?.toString() ,state.session.LocKey?.toString(), state.session.AcctKey?.toString()
            )

            const validatedDemographics = validateDemographicsUserInput(demographics);
            const isValid = state.demographics != null &&
                (!validatedDemographics.FirstName.error && !validatedDemographics.LastName.error &&
                    !validatedDemographics.Home.error && !validatedDemographics.Email.error && !validatedDemographics.DOB.error
                    && !validatedDemographics.Cel.error && !validatedDemographics.Address1.error && !validatedDemographics.Address2.error
                    && !validatedDemographics.City.error && !validatedDemographics.State.error && !validatedDemographics.Country.error
                    && !validatedDemographics.Zip.error && !validatedDemographics.ReferredBy1.error && !validatedDemographics.ReferredBy2.error
                    && !validatedDemographics.ReferredBy3.error && !validatedDemographics.ReferredBy4.error && !validatedDemographics.ReferredBy5.error
                    && !validatedDemographics.L4DSSN.error && !validatedDemographics.Gender.error);
            // //setIsDemographicsValid(isValid);            
            
           
            if (isValid)
            {        
                setLoading(true)
                dispatch(updateProvider(newVal));
                const provider = state.providers.providers.find((x) => x.AcctKey == state.availability?.availability.AcctKey);
                DemographicsService.saveDemographics(newVal,{ ...state.session, AcctKey: provider?.AcctKey })
                    .then(() => {
                        dispatch(updatePatient(newPatientVal));
                        NavigatePage(dispatch,"next", state, navigate, stateRoutes.visionInsurance)
                        // if (skipVisionInsurance===true)
                        // {
                        //     NavigatePage(dispatch, "next", state, navigate, stateRoutes.customQuestion)
                        // }else
                        // {
                        //     NavigatePage(dispatch, "next", state, navigate, stateRoutes.visionInsurance)
                        // }
                    })
                    .catch((e) => {
                        setLoading(false)
                        console.error(e);
                    });
            }
            else
            {
                setDemographics(validatedDemographics)
                setLoading(false)
            }
        }
    }


    return (
        <div className="p-2">
            <Box
                display={"flex"}
                alignItems={"center"}
                className="wsui-header-partical"
            >
                <Box flexGrow={1}>
                    <Typography variant="h6"
                        fontWeight={"500"}
                        sx={{
                            fontSize: "16px",
                            fontWeight: "600",
                        }} >
                        {locationName}
                    </Typography>
                </Box>
                <Box sx={{}}>
                    <Typography
                        variant="h6"
                        fontWeight={"500"}
                        sx={{
                            fontSize: "16px",
                            fontWeight: "600",
                        }}
                    >
                        <div>{getFormattedDateTime(dTime)}</div>
                     </Typography>
                </Box>
            </Box>
            <Box borderBottom={"0.5px solid #979797 !important"} className="wsui-box-p">
            <div className="Typogrphy-pb title-name"><b>{appointmentType}</b>     {providerAgnostic ? <span className="patient-name-title"> with <b>{providerFirstname} {ProviderLastName},{providerDegree}</b></span>  : null}</div>
            </Box>
            <Box borderBottom={"0.5px solid #979797 !important"} className="wsui-box-p">
                <div className="Typogrphy-pb title-name">Patient:<b>{" " + capName + " " + patientLastName}</b>  <span className="patient-name-title"> &nbsp;&nbsp;&nbsp;Email: <b>{patientEmail}</b></span></div>
            </Box>
            {!loading ? 
            <><form className="form-div demographic-form-container" 
            // onSubmit={handleSubmit(handleClick)}
            >
                <Box borderBottom={"0.5px solid #979797 !important"} className="wsui-box-p patient-details-wrapper">
                    <Typography className="Typogrphy-p">
                        <Typography
                            variant="h5"
                            fontWeight={"500"}
                            sx={{
                                fontSize: "20px",
                                fontWeight: "600",
                                lineHeight: "27.24px",
                                margin: "0px 0px",
                            }}
                            className="wsui-main-heading-title"
                        >
                            Please enter additional patient information below:
                        </Typography>
                        <br />

                        {renderFirstName()}
                        {renderLastName()}
                        {renderEmail()}
                        {renderSSN()}
                        {renderGender()}
                        {renderLanguage()}
                        
                        {!hideAddressDetails && <>
                            {renderAddress1()}
                            {renderAddress2()}
                        </>}

                        {!hideAddressDetails && <div className="wsui-parent-control">
                            <Grid container columnSpacing={2}>
                                {renderCity()}
                                {renderState()}
                                {renderZip()}
                                {renderCountry()}
                            </Grid>     
                        </div>}

                        {!hideContactDetails && <div className="wsui-form-control wsui-parent-control">
                            <Grid container columnSpacing={2}>
                                {renderHome()}
                                {renderCel()}
                            </Grid>
                        </div>}
                        
                        {renderReferredBy()}
                </Typography>
            </Box>
            <Box>
                <Grid className="wsui-text-continue">
                    <span>We always ensure <b> your data is secure</b> and <b>never share </b> your personal information with any third parties</span>
                    { 
                        <Button
                        id="button-continue-patient-demo"
                        className="button-continue-patient-demo"
                        variant="contained"
                        style={{
                            backgroundColor: "var(--fpc-blue)",
                            color: `${state.embed.fontColor}`,
                        }}
                        // type="submit"
                        // onClick={handleSubmit(handleClick)}
                        onClick={handleClick}
                    >
                        Continue
                    </Button>
                    }
                </Grid>
            </Box>
        </form></>
        :
            <>
                <div className="wsui-spinner">
                    <Spinner />
                </div>
            </>}
        </div >
    )
}