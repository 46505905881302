import CustomQuestionsService from "../../services/CustomQuestionService";
import React, { useEffect, useContext, useState } from 'react';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Grid, Box, Button, Typography } from '@mui/material';
import { CustomQuestionsModel } from '../../models/CustomQuestion';
import './CustomQuestion.css';
import { mapSessionDataForSaveCustomQuestion } from '../../models/SaveCustomQuestion';
import { AppContext, AppContextType } from "../../App";
import Spinner from "../spinner/Spinner";
import { NavigatePage, PageName, getFormattedDateTime } from "../../utils/Util";
import { Event , PageView} from '../../utils/GA';
import { updateCurrentPage } from "../../features/session/sessionSlice";
import useStateNavigation from "../hooks/useStateNavigation";
import { stateRoutes } from "../../models/Page";

const CustomQuestion: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const state = useAppSelector(state => state)
    const selectTime = state.availability.availability
    const dDate = new Date(selectTime.AppointmentDate).toDateString()
    const dTime = (selectTime.AppointmentDateTime)
    const TimeConvert =(date: any) => { 
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ap = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes.toString().padStart(2, '0')
        let mergeTime = hours + ':' + minutes + ' ' + ap;
        return mergeTime;
    }
    TimeConvert(dTime)

    const providerName = state.appointmentData.appointmentType.Name
    const providerFirstname = state.providers.provider.FirstName
    const ProviderLastName = state.providers.provider.LastName
    const providerDegree = state.providers.provider.Degree
    const [capName, setCapName] = useState("");
    const [locationName, setLocationName] = useState("");
    const { formData, setFormData } = useContext(AppContext) as AppContextType
    const patientFirstName = state.patient.FirstName;
    const patientLastName = state.patient.LastName;
    const patientEmail = state.patient.Email;
    const [showPage, setShowPage] = useState(false);
    const dispatch = useAppDispatch();

    const [customQuestions, setCustomQuestions] = useState<CustomQuestionsModel[]>([]);
    const [canContinue, setCanContinue] = useState<boolean>(false);
    useEffect(() => {

        const provider = state.providers.providers.find((x) => x.AcctKey == state.availability?.availability.AcctKey);
        CustomQuestionsService.retrieveCustomQuestions({ ...state.session, AcctKey: provider?.AcctKey })
            .then((response) => {
                response.filter(r => r.setting !== '-1')
                const appended = response.map((r, k) => {
                    return {
                        ...r,
                        setting: r.setting?.toString(),
                        answer: '',
                        error: ''
                    }
                })
                if (response.length > 0)
                {
                    setShowPage(true)
                    setCustomQuestions(appended)
                }
                else
                {
                    ValidateAppointment();
                }             
            });
            capitalize(patientFirstName); 
    }, []);
    useEffect(() => {
        // Validate
        let arr: any = []
        customQuestions.forEach((c) => {
            if (c.answer === '' && c.setting === '1') {
                arr.push(false)
            } else {
                arr.push(true)
            }
        })
        const isAllValid = arr.every((e: any) => e === true);
        setCanContinue(isAllValid)
    }, [customQuestions])


    useEffect(() => {
        dispatch(updateCurrentPage({CurrentPage :  PageName.CustomQuestion}))  
        if (state.availability.getInitialLocation)
            setLocationName(state.availability.getInitialLocation.Name) //+ "-" + state.availability.getInitialLocation.Address)
        }, [state.availability.getInitialLocation]); 

        useEffect(() => {
            PageView(
                `pageview_custom_question_screen`, state.session.CoverKey?.toString() ,state.session.LocKey?.toString(), state.session.AcctKey?.toString()
            )
        },[])


    const handleInputChange = (e: any, index: number) => {
        const { value } = e.target
        let formVal = [...customQuestions]
        formVal[index]['answer'] = value

        // Validate
        if (formVal[index].setting === '1' && value === '') {
            formVal[index]['error'] = 'Required'
        } else {
            formVal[index]['error'] = ''
        }

        setCustomQuestions(formVal)

    }

    const validateForm = () => {
        setCustomQuestions((questions) =>
            questions.map((q) => ({
                ...q,
                error:
                    q.setting === "1" && (q.answer === "" || q.answer == null)
                        ? "Required"
                        : "",
            }))
        );
    };

    const capitalize = (data: any) => {
        let capitalizeName = data.charAt(0).toUpperCase() + data.slice(1);
        setCapName(capitalizeName);
    }
    const renderQuestions = () => {
        const jsx = customQuestions?.map((c: any, k: any) => {
            return (
                <div className="parent-questions wsui-form-control" key={k}>
                    <div className="parent-question">
                        <div className="num-question"><strong>{`${k + 1}. `}</strong></div>
                        <div className="multi-question"><strong>{`${c.question} ${c.setting === '1' ? '*' : ''}`}</strong></div>
                        {c.error && <p className="wsui-error">{c.error}</p>}
                    </div>
                    <input
                        id={`input-custom-question-${k}`}
                        className="input-question input-custom-question"
                        maxLength={50}
                        value={c.answer}
                        readOnly={false}
                        style={{ width: "100%" }}
                        onChange={(e) => handleInputChange(e, k)}       
                        
                    />
                </div>
            )
        })

        return jsx
    }
    const navigate = useStateNavigation()
    const handleClick = () => {
        setLoading(true)
        let stubapi = {
            sessionObject: mapSessionDataForSaveCustomQuestion(state.session),
            notes: '',
            pRefB: state.demographics.demographics.ReferredBy1,
            pRefB1: customQuestions[0] ? customQuestions[0].answer : '',
            pRefB2: customQuestions[1] ? customQuestions[1].answer : '',
            pRefB3: customQuestions[2] ? customQuestions[2].answer : '',
            pRefB4: customQuestions[3] ? customQuestions[3].answer : '',
            pRefB5: customQuestions[4] ? customQuestions[4].answer : '',
        }

        CustomQuestionsService.saveCustomQuestion(stubapi).then((response) => {
//            console.log(response);
            ValidateAppointment();
//            ValidateAppointment();
        });

        // Navigate('/AppointmentValidation')

    //GA-09
	Event(
        false,
        'click_custom_question_screen',
        state.session.CoverKey?.toString() ,state.session.LocKey?.toString(), state.session.AcctKey?.toString()
    )
    }

    const ValidateAppointment = () => 
    {
        NavigatePage(dispatch,"next", state, navigate, stateRoutes.appointmentVisitDetails)
    }
    
    return (
        <>
        { showPage && 
            <div className="p-2">
                <Box display={"flex"}
                    alignItems={"center"}
                    className="wsui-header-partical">
                    <Box flexGrow={1}>
                        <Typography variant="h6"
                            fontWeight={"500"}
                            sx={{
                                fontSize: "16px",
                                fontWeight: "600",
                            }} >
                            {locationName}
                        </Typography>
                    </Box>
                    <Box sx={{}}>
                        <Typography
                            variant="h6"
                            fontWeight={"500"}
                            sx={{
                                fontSize: "16px",
                                fontWeight: "600",
                            }}
                        >
                            <div>{getFormattedDateTime(dTime)}</div>
                        </Typography>
                    </Box>
                </Box>
                <Box borderBottom={"0.5px solid black !important"} className="wsui-box-p">
                <div className="Typogrphy-pb title-name"><b>{providerName}</b>
                           {state.embed.ProviderAgnostic === 0 ? <span className="patient-name-title"> with <b>{providerFirstname} {ProviderLastName},{providerDegree}</b></span> : null} 
                        </div>
                </Box>
                <Box borderBottom={"0.5px solid #979797 !important"} className="wsui-box-p">
                    <div className="Typogrphy-pb title-name">Patient:<b>{" " + capName + " " + patientLastName}</b>  <span className="patient-name-title"> &nbsp;&nbsp;&nbsp;Email: <b>{patientEmail}</b></span></div>
                </Box>
                <Box className="wsui-box-p">
                    <form className="form-div">
                        <Box borderBottom={"!important"} >
                            <Typography className="Typogrphy-p">
                                <Typography
                                    variant="h4"
                                    fontWeight={"500"}
                                    sx={{
                                        fontSize: "24px",
                                        fontWeight: "630",
                                        lineHeight: "1.2",
                                    }}
                                >
                                    We'd like to learn more about you!
                                </Typography>
                                <br />

                                <Typography
                                    variant="h6"
                                    fontWeight={"500"}
                                    sx={{
                                        fontSize: "16px",
                                        fontWeight: "420",
                                    }}
                                >
                                    Please answer the following questions to help us prepare for your upcoming appointment.
                                </Typography>


                            </Typography>
                        </Box>
                        <br />
                        <Box>

                            {renderQuestions()}

                        </Box>
                        <Box className="footerdata">
                            <span className="footer-form"></span>
                            <Grid className="wsui-text-continue">
                                <Button
                                    id="button-continue-custom-question"
                                    className="button-continue-custom-question"
                                    style={{
                                        backgroundColor: "var(--fpc-blue)",
                                        color: `${state.embed.fontColor}`,
                                    }}
                                    variant="contained"
                                    onClick={
                                        canContinue
                                            ? handleClick
                                            : validateForm
                                    }
                                >
                                    Continue
                                </Button>
                            </Grid>
                        </Box>
                    </form>
                </Box>
            </div>}
            
            { loading === true && 
            <>
                <div className='wsui-spinner'>
                    <Spinner />
                </div>

            </> }
        </>
    )
}

export default CustomQuestion