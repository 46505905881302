import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EmbedState, EcpConfigurationAction } from "../../models/EcpPatientConfiguration";

const initialState: EmbedState = {
    CoverKey: null,
    LocKey: null,
    GroupKey: null,
    AcctKey: null,
    TaskKey: null,
    ReferredBy : null,
    Stylings: null,
    SkipDemographics: false,
    StandAlone: false,
    SkipPatientInsurance: false,
    SkipCustomQuestions: false,
    SkipPatientInformation : false,
    TrackingID: null,
    Embed : null,
    Source : null,
    fontColor :'white',
    ProviderAgnostic: 0,
    LimitOneAppt: false
}

export const embedSlice = createSlice({
    name: 'embed',
    initialState,
    reducers: {
        setEmbedState: (
            state,
            action
        ) => {
            if (action.payload) {
                state = action.payload
            }
        },
        setEcpConfiguration: (
            state,
            action: PayloadAction<EcpConfigurationAction>
        ) => {
            if (action.payload) {
                state.GroupKey = action.payload.GroupKey ? action.payload.GroupKey : state.GroupKey;
                state.CoverKey = action.payload.CoverKey ? action.payload.CoverKey : state.CoverKey;
                state.LocKey = action.payload.LocKey ? action.payload.LocKey : state.LocKey;
                state.AcctKey = action.payload.AcctKey ? action.payload.AcctKey : state.AcctKey;
                state.TaskKey = action.payload.TaskKey ? action.payload.TaskKey : state.TaskKey;
                state.Stylings = action.payload.Stylings ? action.payload.Stylings : state.Stylings;
                state.SkipCustomQuestions = !!action.payload.SkipCustomQuestions;
                state.SkipDemographics = !!action.payload.SkipDemographics;
                state.SkipPatientInsurance = !!action.payload.SkipPatientInsurance;
                state.SkipPatientInformation = !!action.payload.SkipPatientInformation;
                state.TrackingID = action.payload.TrackingID ? action.payload.TrackingID : state.TrackingID;
                state.ReferredBy  = action.payload.ReferredBy ? action.payload.ReferredBy : state.ReferredBy;
                state.Source  = action.payload.Source ? action.payload.Source : state.Source;
                state.Mode  = action.payload.Mode ? action.payload.Mode : state.Mode;
                state.ProviderAgnostic = action.payload.ProviderAgnostic ? action.payload.ProviderAgnostic : state.ProviderAgnostic;
                state.LimitOneAppt = !!action.payload.LimitOneAppt;
            }
        },
        setFontColor :(
            state,
            action
        ) => {
            if (action.payload) {
                state.fontColor = action.payload
            }
        }
    }
})
export const { setEmbedState, setEcpConfiguration, setFontColor } = embedSlice.actions
export default embedSlice.reducer