import React, { createContext, useState, useEffect } from "react";

// theme
import ThemeProvider from "./theme";
// components
import ScrollToTop from "./components/scroll-to-top";
import ThemeRoutes from "./routes";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { setSessionState } from "./features/session/sessionSlice";
import { isInjectedNonIframe } from "./hooks/useIsInjected";
export interface AppContextType {
  formData: any,
  setFormData: any
}

export const AppContext = createContext<AppContextType | null>(null)
function App() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    pNumber: '',
    email: '',
    day: '',
    month: '',
    year: '',
    address1: '',
    address2: '',
    city:'',
    state:'',
    zip: '',
    country:'',
    mobilePhone:'',
    referredBy:'',
    displayVisionInsurance: '',
    displayMedicalInsurance: '',
    visionInsuranceCarrier: '',
    visionInsuranceOthersName: '',  
    visionInsuranceCarrierServiceNum: '',
    visionInsuranceGroupNumber: '',
    visionInsuranceRelationToPatient: '',
    visionInsuranceSubscriberDOB: '',
    visionInsuranceSubscriberID: '',
    visionInsuranceSubscriberName: '',
    visionDay: '',
    visionMonth: '',
    visionYear: '',
    medicalInsuranceCarrier: '',
    medicalInsuranceOthersName: '', 
    medicalInsuranceCarrierServiceNum: '',
    medicalInsuranceGroupNumber: '',
    medicalInsuranceRelationToPatient: '',
    medicalInsuranceSubscriberDOB: '',
    medicalInsuranceSubscriberID: '',
    medicalInsuranceSubscriberName: '',
    medicalDay: '',
    medicalMonth: '',
    medicalYear: '',
  })
  const value = { formData, setFormData }
  const dispatch = useAppDispatch();
  const state = useAppSelector(stat => stat)

	useEffect(() => {
		const ck = state.embed.Stylings ?? `${process.env.REACT_APP_DEFAULT_STYLINGS as string}`;
		const root = document.documentElement;

    root?.style.setProperty(`--fpc-blue`, `#${ck}`);
    if(!isInjectedNonIframe()) {
      root?.style.setProperty('scrollbar-gutter', 'auto');
    }
	}, []); 
  
  return (
    <ThemeProvider>
      <AppContext.Provider value={value}>
        <ScrollToTop />
        <ThemeRoutes />
      </AppContext.Provider>
    </ThemeProvider>
  );
}

export default App;
