import HashReturnObject from '../models/HashReturnObject';
import { getBaseAPI } from "../environments/Environments";
import { mapSessionData, SessionModel } from '../models/Session';
import MultipleAppointmentDetails, { MultipleAppointmentDetailsData , mapMultipleAppointmentDetailsData} from '../models/MultipleAppointments';
import { apiFetch } from './ServiceHelper';
import ErrorService from "./ErrorService";

export default class ExistingAppointmentService {
    static existingAppointment = (session: SessionModel): Promise<HashReturnObject<MultipleAppointmentDetails>> => {
        return new Promise( async (resolve, reject) => {
                const url = await getBaseAPI() + '/api/v1/Patient/retrieveScheduledAppointments';
                const appointmentDetailsData = mapSessionData(session);
                const payload = JSON.stringify(appointmentDetailsData);
                apiFetch(url, {
                    method: 'POST',
                    body: payload,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then((result) => Promise.resolve(result))
                .then((hashReturnObject: HashReturnObject<MultipleAppointmentDetailsData>) => {
                    if (hashReturnObject.hasOwnProperty('error')) {
                        ErrorService.showErrorAlert('Oops! Something went wrong. Please try again later. If the issue persists, please contact our support team for assistance.', hashReturnObject);
                    }
                    const multipleAppointment: MultipleAppointmentDetails[] = [];
                    hashReturnObject.data.forEach((data: MultipleAppointmentDetailsData) => {
                        multipleAppointment.push(mapMultipleAppointmentDetailsData(data));
                    });
        
        
                    //for testing **********
                    //if (locations.length > 1) locations = [locations[0]];
                    //for testing **********
                    
                    const responseObject: HashReturnObject<MultipleAppointmentDetails> = {
                        data: multipleAppointment,
                        hash: hashReturnObject.hash,
                        sessionId: hashReturnObject.sessionId
                    }
                    resolve(responseObject);
                })
                .catch((e) => {
                    console.error('request error', e);
                    reject(e);
                });
        });
    }
}