import { error } from "console";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import * as yup from "yup";

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);

// include future validation error details from API
export enum ValidationErrors {
    PhoneNumber = "Home is invalid",
}

enum ErrorMessages {
    DateOfBirthMustBePast = "DOB must be in the past",
    FirstNameMaxLength = "First Name must be at most 25 characters",
    LastNameMaxLength = "Last Name must be at most 25 characters",
    EmailMaxLength = "Email Address must be at most 100 characters",
    InvalidFirstName = "Invalid First Name",
    InvalidLastName = "Invalid Last Name",
    InvalidPhoneNumber = "Invalid Phone Number",
    InvalidEmail = "Invalid Email",
    InvalidEmailFormat = "Invalid Email Format",
    InvalidDay = "Invalid Day",
    InvalidMonth = "Invalid Month",
    InvalidYear = "Invalid Year",
    InvalidDateOfBirth = "Invalid Date Of Birth",
    RequiredFirstName = "First Name*",
    RequiredLastName = "Last Name*",
    RequiredPhoneNumber = "Phone Number*",
    RequiredMobileNumber="Mobile Phone*",
    RequiredPhoneHome="Phone (Home)*",
    RequiredEmail = "Email*",
    RequiredDay = "Day*",
    RequiredMonth = "Month*",
    RequiredYear = "Year*",
    YearNotInRange = "Year must be after 1900",
    Identical="Invalid Phone Number",
    InvalidZip="Invalid Zip",
    InvalidSSN="Invalid SSN",
    OnlyNonSpecialChar="Special characters are not allowed",
    RequiredState="State*",
    RequiredAddress1="Address1*",
    RequiredAddress2="Address2*",
    RequiredCity="City*",
    RequiredReferredBy="Referred By*",
    RequiredGender="Gender*",
    RequiredSSN="Last 4 SSN Digits*",
    RequiredLanguage="Language*",
    RequiredZip="Zip*",
    PhoneMinimumLength="Invalid Phone Number",
    PhoneMaxLength="Invalid Phone Number"
}

const REGEX_VALID_PHONE_NUMBER = /^[2-9]\d{9}$/;
const REGEX_VALID_EMAILOLD =
    /^(([^<>()[\]\\.,;:\s@"&]+(\.[^<>()[\]\\.,;:\s@&]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const REGEX_VALID_EMAIL=/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;    
const REGEX_ALLOWED_CHARACTERS_APOSTROPHE = /^[A-Za-z-' ]*$/;





const isIdentical=(value:any)=>{

    let pattern= new RegExp("\\b([0-9])\\1{9}\\b");
    if (value==null)
    {
        return false;
    }
 

    if(pattern.test(value)==true)
    {
        return false;
    }
    else
    {
        return true;
    }


}



const validateFormat = (value: any, { originalValue }: any) =>
    originalValue?.length === 2 &&
    (value <= 9 ? originalValue.startsWith("0") : !!value);

const validateDateOfBirth = (
    _value: any,
    { parent: { month, day, year }, createError }: any
) => {
    const dateOfBirth =
        month &&
        day &&
        year &&
        dayjs(
            `${year}-${month.toString().padStart(2, "0")}-${day
                .toString()
                .padStart(2, "0")}`,
            "YYYY-MM-DD",
            true
        );

    if (dateOfBirth) {
        if (dateOfBirth.isSameOrAfter(dayjs(), "day")) {
            return createError({
                path: "dateOfBirth",
                message: ErrorMessages.DateOfBirthMustBePast,
            });
        } else if (!dateOfBirth.isValid()) {
            return createError({
                path: "dateOfBirth",
                message: ErrorMessages.InvalidDateOfBirth,
            });
        }
    }

    return true;
};

export const validationSchema = (validationErrors: string[]) =>
    yup.object().shape({
        firstName: yup
            .string()
            .required(ErrorMessages.RequiredFirstName)
            .matches(
                REGEX_ALLOWED_CHARACTERS_APOSTROPHE,
                ErrorMessages.InvalidFirstName
            )
            .max(25, ErrorMessages.FirstNameMaxLength),
        lastName: yup
            .string()
            .required(ErrorMessages.RequiredLastName)
            .matches(
                REGEX_ALLOWED_CHARACTERS_APOSTROPHE,
                ErrorMessages.InvalidLastName
            )
            .max(25, ErrorMessages.LastNameMaxLength),
        pNumber: yup
            .string()
            .required(ErrorMessages.RequiredPhoneNumber)
            .matches(REGEX_VALID_PHONE_NUMBER,ErrorMessages.InvalidPhoneNumber)
            .test("is_Valid",ErrorMessages.Identical,isIdentical),
            // .matches(REGEX_VALID_PHONE_NUMBER, ErrorMessages.InvalidPhoneNumber)
            // .test(
            //     "has-no-error",
            //     ErrorMessages.InvalidPhoneNumber,
            //     () => !validationErrors.includes(ValidationErrors.PhoneNumber)
            // ),
        email: yup
            .string()
            .required(ErrorMessages.RequiredEmail)
            .email(ErrorMessages.InvalidEmail)
            .matches(REGEX_VALID_EMAIL, ErrorMessages.InvalidEmailFormat)
            .max(100, ErrorMessages.EmailMaxLength),
        month: yup
            .number()
            .transform((value, originalValue) =>
                originalValue == "" ? undefined : value
            )
            .required(ErrorMessages.RequiredMonth)
            .typeError(ErrorMessages.InvalidMonth)
            .min(1, ErrorMessages.InvalidMonth)
            .max(12, ErrorMessages.InvalidMonth)
            .test(
                "is-valid-format",
                ErrorMessages.InvalidMonth,
                validateFormat
            ),
        day: yup
            .number()
            .transform((value, originalValue) =>
                originalValue == "" ? undefined : value
            )
            .required(ErrorMessages.RequiredDay)
            .typeError(ErrorMessages.InvalidDay)
            .min(1, ErrorMessages.InvalidDay)
            .when("month", ([month], schema) =>
                schema.max(
                    [4, 6, 9, 11].includes(month) ? 30 : month === 2 ? 29 : 31,
                    ErrorMessages.InvalidDay
                )
            )
            .test("is-valid-format", ErrorMessages.InvalidDay, validateFormat),
        year: yup
            .number()
            .transform((value, originalValue) =>
                originalValue == "" ? undefined : value
            )
            .required(ErrorMessages.RequiredYear)
            .typeError(ErrorMessages.InvalidYear)
            .min(1900, ErrorMessages.YearNotInRange),
        dateOfBirth: yup.string().nullable().test(validateDateOfBirth),
      
    });
    
    
