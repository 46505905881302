import { getBaseAPI } from "../environments/Environments";
import { SessionModel, mapSessionData } from "../models/Session";
import { EcpConfigurationAction } from "../models/EcpPatientConfiguration";
import { setEcpConfiguration } from "../features/embed/embedSlice";
import { setSessionState } from "../features/session/sessionSlice";
import { initGTM } from "../utils/GTM";
import { initInternalGA } from "../utils/GA";
import { GetCoverKeyInformation } from "./InitServices";
import { initHotJarConfig } from "../utils/HotJar";
import { getAppParam } from "../models/InjectedParams";
import { apiFetch, bookingApiGet, bookingApiPost, configApiPost } from "./ServiceHelper";

export const SessionService = (
	logKey: string,
	coverKey: string,
	source: string,
	state: { counter?: any; provider?: any; session: any; appointmentData?: any; hash?: any; embed?: any; },
	dispatch : any
): Promise<SessionModel> => {
	const queryParams = source !== '' ? {
		coverKey: coverKey,
		source: source
	} : {
		coverKey: coverKey
	}
    function merge(embed: any, hash: any, session: any): SessionModel {
        const answer: any = {};
        for (const key in session) {
            if (session[key]) {
                answer[key] = session[key];
            } else if (hash[key]) {
                answer[key] = hash[key];
            } else if (embed[key]) {
                answer[key] = embed[key];
            }
        }
        return answer;
    }	
	
	const endsWithNumber = (str: string) => {
		const x = parseInt(str.slice(-1));
		return isNaN(x) ? false : true;
	};

	const validateGTM = (id: string) => {
		const regexp = /(GTM|UA|YT|MO)-/i;
		return regexp.test(id);
	};


	return new Promise(async (resolve, reject) => {

		// Generate Session
		let sessionModel: SessionModel = {
			LogKey: null,
			CoverKey: null,
			LocKey: null,
			GroupKey: null,
			AcctKey: null,
			AddKey: null,
			TaskKey: null,
			ApptKey: null,
			ClientIP: null,
			CreatedOn: String(new Date()),
			ApptDateTime: null,
			CurrentPage: 0,
			V4Scenario: null,	
			TrackingID: null,		
		}
		await bookingApiGet('Patient/generateSession', queryParams)
		.then(res => {
		  if (res.data) {
			var session = res.data
			 sessionModel = {
				LogKey: session.sessionID,
				CoverKey: session.coverKey,
				LocKey: null,
				GroupKey: null,
				AcctKey: null,
				AddKey: null,
				TaskKey: null,
				ApptKey: null,
				ClientIP: session.clientIP,
				CreatedOn: String(new Date()),
				ApptDateTime: null,
				CurrentPage: 0,
				V4Scenario: null,
				TrackingID:null,
			};
		  }
		}).catch((ex) => {
			console.log('Session Service error');
			reject(ex);
		})


		// Cover information
		const url_string = window.location.href;
		const urlParamOrig = new URL(url_string);
		
		const urlParam = new URLSearchParams();
		for (const [name, value] of urlParamOrig.searchParams) {
			urlParam.append(name.toLowerCase(), value);
		}

			var locKey : any = urlParam.has("lockey") ? urlParam.get("lockey") : 0
			const	[coverName, coverAlias, mobileLogo, desktopLogo, frontLine] = await GetCoverKeyInformation(locKey, coverKey)
			const ecpObj: EcpConfigurationAction = {
				CoverKey: getAppParam("coverkey", urlParamOrig) ?? coverKey,
				LocKey: getAppParam("lockey", urlParamOrig) ?? "",
				GroupKey: getAppParam("groupid", urlParamOrig) ?? "",
				AcctKey: getAppParam("acctkey", urlParamOrig) ?? "",
				TaskKey: getAppParam("taskkey", urlParamOrig) ?? "",
				ReferredBy: getAppParam("referredby", urlParamOrig) ?? "",
				Stylings: getAppParam("stylings", urlParamOrig) ?? "",
				TrackingID: getAppParam("tid", urlParamOrig) ?? "",
				Embed: getAppParam("embed", urlParamOrig) ?? "false",
				Mode: getAppParam("mode", urlParamOrig) ?? "",
				Source: getAppParam("source", urlParamOrig) ?? "",
				SkipDemographics:
					state.embed.SkipDemographics !== undefined &&
						state.embed.SkipDemographics !== null
						? state.embed.SkipDemographics
						: false,
				SkipPatientInsurance:
					state.embed.SkipPatientInsurance !== undefined &&
						state.embed.SkipPatientInsurance !== null
						? state.embed.SkipPatientInsurance
						: false,
				SkipCustomQuestions:
					state.embed.SkipCustomQuestions !== undefined &&
						state.embed.SkipCustomQuestions !== null
						? state.embed.SkipCustomQuestions
						: false,
				SkipPatientInformation:
					state.embed.SkipPatientInformation !== undefined &&
						state.embed.SkipPatientInformation !== null
						? state.embed.SkipPatientInformation
						: false,	
						
				ProviderAgnostic: state.embed.ProviderAgnostic,
				LimitOneAppt: state.embed.LimitOneAppt
			}    

			dispatch(setEcpConfiguration(ecpObj))		

            // Check other Parameters 
			var updatedSession = {...sessionModel}
			if (ecpObj.GroupKey !== "")
				updatedSession = {...updatedSession, GroupKey : ecpObj.GroupKey}   			
			if (ecpObj.LocKey !== "")
				updatedSession = {...updatedSession, LocKey : Number(ecpObj.LocKey)}
			if (ecpObj.AcctKey !== "")
				updatedSession = {...updatedSession, AcctKey : ecpObj.AcctKey}
			if (ecpObj.TaskKey !== "")
				updatedSession = {...updatedSession, TaskKey : ecpObj.TaskKey}      
			if (ecpObj.TrackingID !== "")
				updatedSession = {...updatedSession, TrackingID : ecpObj.TrackingID}                      
			if (ecpObj.Mode !== "")
				updatedSession = {...updatedSession, Mode : ecpObj.Mode}
				
			updatedSession = {...updatedSession,  OrgName : coverName , DisplayOrgAlias: coverAlias , MobileLogo : mobileLogo , DesktopLogo : desktopLogo, FrontLine: frontLine} 
			// Update Session State
			dispatch(setSessionState(updatedSession))	
			// Tracking Service
			
            //Initialize Internal Google Analytics
			initInternalGA("");


			//Initialize HotJar
			let HJCoverKey=urlParam.get("coverkey")===null ? 0:ecpObj.CoverKey;
			async function ValidateHJViaAPI(){
				
				await configApiPost('HotJar/ForHotJarTracking', undefined, { CoverKey: HJCoverKey }, false)
				.then(res => {
				var response = res.data;
				initHotJarConfig(response);

					
				})
				.catch((e) => {
					console.log('HotJar error');
			
				});	

			}
				
			// console.log("CoverKEY: " + ecpObj.CoverKey);
			// console.log("URL Param:" + urlParam.get("coverkey"));
			// console.log("HJCoverKey:" + HJCoverKey);

			if(HJCoverKey===0)
			{
				initHotJarConfig(true);
				if(ecpObj.CoverKey!=null)
				{
					ValidateHJViaAPI();	
				}
			}
			else
			{
				
				
				ValidateHJViaAPI();	
			}
			

			
			
			if (ecpObj.TrackingID && ecpObj.TrackingID !== "0")
			{
				const bodyRequest = {
				...updatedSession,
				LogKey: updatedSession.LogKey === null ? 0 : updatedSession.LogKey,
				trackingID: updatedSession.TrackingID === null ? '0' : updatedSession.TrackingID
				}


				//delete bodyRequest.TrackingID
				var json = JSON.stringify(bodyRequest)

				await bookingApiPost('Tracking/GetTracking', bodyRequest)
				.then(res => {
				var response = res.data
				if (response)
				{
					//  Do the initialization here
					const { headerScript } = response;

					const extractedInstance = headerScript.substring(
						headerScript.indexOf("GTM-"), 
						headerScript.lastIndexOf("');</script>")
					);
					if (extractedInstance!=='')
					{
						initGTM(extractedInstance)
						// alert(extractedInstance + ' init gtm')
					}			
				}		
				})
				.catch((e) => {
					console.log('Session Service error');
				reject(e);
				});		

				
	        }
			
		resolve(updatedSession);
	})
}
export const saveSession = (
	session: SessionModel
): Promise<{ SessionId: string; Hash: string }> => {
	return new Promise(async (resolve, reject) => {
		const url = await getBaseAPI() + "/api/v1/Patient/saveSession";
		apiFetch(url, {
			method: "POST",
			body: JSON.stringify(mapSessionData(session)),
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((result) => Promise.resolve(result))
			.then((session) => {
				setTimeout(() => {
					resolve({
						SessionId: String(session.sessionId),
						Hash: String(session.hash),
					});
				}, 500);
			})
			.catch((e) => {
				console.log('Session Service error');
				reject(e);
			});
	});
}

export const retrieveSession = (logKey: string): Promise<SessionModel> => {
	const queryString = `logKey=${logKey}`;
	return new Promise(async (resolve, reject) => {
		const url =
			await getBaseAPI() + "/api/v1/Patient/retrieveSession?" + queryString;
		apiFetch(url, {
			method: "GET",
		})
			.then((result) => Promise.resolve(result))
			.then((session) => {
				const sessionModel: SessionModel = {
					LogKey: session.logKey,
					CoverKey: session.coverKey,
					LocKey: session.locKey,
					GroupKey: session.groupId,
					AcctKey: session.acctKey,
					AddKey: session.addKey,
					TaskKey: session.taskKey,
					ApptKey: session.apptKey,
					ClientIP: session.clientIP,
					ApptDateTime: session.apptDateTime,
					V4Scenario: session.v4Scenario,
					CreatedOn: null,
					CurrentPage: null,
				};
				resolve(sessionModel);
			})
			.catch((e) => {
				console.log('Session Service error');
				reject(e);
			});
	});
};
