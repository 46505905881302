import styles from './AppointmentReviewPanel.module.css';
import calendarIcon from '../../assets/calendar-icon.jpg';
import { css } from '../../utils/Util';

export type ApptReviewPanelMode = "modal" | "standard"

type AppointmentReviewPanelProps = {
  headerText: string | JSX.Element,
  displayDayDateTime: string,
  displayLocation: string,
  displayPatient: string,
  mode: ApptReviewPanelMode
}
export const AppointmentReviewPanel = ({ headerText, displayDayDateTime, displayLocation, displayPatient, mode }: AppointmentReviewPanelProps) => {
  const modeClass = mode === "modal" ? styles.modeModal : styles.modeStandard;
  return <div className={css(styles.root, styles.modeStandard)}>
    <div className={styles.headerLine}>
      <img src={calendarIcon} className={styles.calendarIcon} alt="" />
      <div className={styles.header}>
        <h3 className={styles.title}>
          Almost there!
        </h3>
        <p className={styles.headerText}>
          {headerText}
        </p>
      </div>
    </div>
    <div className={styles.mobileHeader}>
      {headerText}
    </div>
    <div className={styles.body}>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td className={styles.leftCell}>{mode === "modal" ? "Day/Date/Time" : "Time"}</td>
            <td className={styles.rightCell}>{displayDayDateTime}</td>
          </tr>
          <tr>
            <td className={styles.leftCell}>Location</td>
            <td className={styles.rightCell}>{displayLocation}</td>
          </tr>
          <tr>
            <td className={styles.leftCell}>Patient</td>
            <td className={styles.rightCell}>{displayPatient}</td>
          </tr>
        </tbody>            
      </table>
    </div>
  </div>
}
