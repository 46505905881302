import React, { FC } from 'react';
import ReactGA from 'react-ga4';

export const environment = {
    gaLocal: 'XX-XXXXXXXXX-X',
    gaProd: 'G-ZMDR3KMCLG',
    gaQA: 'XX-XXXXXXXXX-X',
    gaStage: 'XX-XXXXXXXXX-X',
    gaDev: 'XX-XXXXXXXXX-X',
};

const getGA = () => {
    const ga = environment.gaProd;
    // TO-DO: once that the GA instances for different environments are built
    // the create a logic here to instantiate corresponding GA ID for a certain environment
    // TEMP FIX: Return the Prod Instance

    return ga;
};

let globaleExtInstance = '';

const getTrackers = () => {
    // Internal GA for the webscheduler (required)
    // External GA for the ECP's (optional)
    // if - there is a GA Instance (UA-XXXXX) found in Tracking API 
    // then - register another GA Instance 
    // else - then only the internal will be returned

    const internalGA = getGA()
    const externalGA = globaleExtInstance

    // Required Default
    const trackerLabel = ['int']
    const trackers = [
        {
            trackingId: internalGA,
            gaOptions: {
              name: 'int',
              debug_mode: true,
            },
            gtagOptions: {
                debug_mode: true,
              },
        }
    ]

    // Optional
    // if(externalGA){
    //     trackerLabel.push('ext')
    //     trackers.push({
    //         trackingId: externalGA,
    //         gaOptions: {
    //           name: 'ext'
    //         }
    //     })
    // }

    return { 
        trackers: trackers,
        trackerLabel: trackerLabel
    }
}

export const initInternalGA = (gaInstance: string | void): void => {
    // Initialize GA here 
    globaleExtInstance = gaInstance ? gaInstance : ''
    const { trackers, trackerLabel } = getTrackers()

    ReactGA.initialize(trackers)
    PageView('Pageload')
};
  
export const Event = (isProviderPath: boolean, action: string, coverKey?: string|null|undefined, locKey?: string|null|undefined, acctKey?: string|null|undefined): void => {
    const { trackerLabel } = getTrackers()
    ReactGA.gtag("set", "user_properties", {
        CoverKey: coverKey ? coverKey : '',
        LocKey: locKey ? locKey : '',
        AcctKey: acctKey ? acctKey : '',
      });
    ReactGA.event({
        category: isProviderPath ? 'start_booking_provider' : 'start_booking_examtype',
        action: action,
    }, trackerLabel);
};

export const PageView = (_page: string , coverKey?: string|null|undefined, locKey?: string|null|undefined, acctKey?: string|null|undefined): void => {
    ReactGA.gtag("set", "user_properties", {
        CoverKey: coverKey ? coverKey : '',
        LocKey: locKey ? locKey : '',
        AcctKey: acctKey ? acctKey : '',
      });
    ReactGA.send({hitType: "pageview", page: _page});
};

export const AppendLabel = (coverKey: string|null|undefined, locKey: string|null|undefined, accKey: string|null|undefined): string => {
    const ck = coverKey ? `CoverKey:${coverKey}` : '';
    const lk = locKey ? `-LocKey:${locKey}` : '';
    const ak = accKey ? `-AcctKey:${accKey}` : '';

    return `${ck}${lk}${ak}`
}