import * as React from "react";
// @mui
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";

const StyledFooterRoot = styled("footer")(({ theme }) => ({
  width: "100%",
  mt: "auto",
  backgroundColor:
    theme.palette.mode === "light"
      ? theme.palette.grey[200]
      : theme.palette.grey[800],
  [theme.breakpoints.up("lg")]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function Footer() {
  return (
    <StyledFooterRoot>
      <Container maxWidth="sm">
        <Typography variant="body2" color="text.secondary">
          {"© Copyright "}
          {new Date().getFullYear()} All Rights Reserved. Powered by
          4PatientCare
        </Typography>
      </Container>
    </StyledFooterRoot>
  );
}
