import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Demographics, { DemographicsState } from "../../models/Demographics";

const initialState: DemographicsState = {
    demographics: {} as Demographics,
}
export const demographicsSlice = createSlice({
    name:'demographics',
    initialState,
    reducers: {
        updateProvider: (state,action)=> {
            try{
                if (action.payload) {
                    state.demographics = action.payload
                }
            }
            catch(e){
                console.log('Demographics error')
            }
       
        },


    }
})

export const {updateProvider} = demographicsSlice.actions
export default demographicsSlice.reducer