import { CSSProperties, ReactNode, MutableRefObject, useCallback } from "react";
// @mui
import { styled } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
//
import Header from "./header";
import Footer from "./footer";
import { useMediaClasses } from "../../theme";
import { isInjected, isInjectedNonIframe } from "../../hooks/useIsInjected";
import { isMinimalOrEmbed } from "../../hooks/useIsMinimalOrEmbed";
import { StateRoute } from "../../models/Page";
import useTheme from "@mui/material/styles/useTheme";
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
//const APP_BAR_DESKTOP = 92;

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: isInjectedNonIframe() ? "100%" : "100vh",
  overflow: "hidden",
  flexDirection: "column",
});

//const createMain = (isLandingPage: boolean) => {
  const Main = styled("div")(({ theme }) => {
    //const minimal = urlPath.includes("mode=minimal");
    //const shrinkTop = minimal && isLandingPage;
    return {
      flexGrow: 1,
      minHeight: "100%",
      //paddingTop: shrinkTop?'0':(minimal?'26px':APP_BAR_MOBILE + 52),
      //marginTop: shrinkTop?'-16px':'0',
      // paddingTop: '24px' ,
      //paddingBottom: theme.spacing(10),
      [theme.breakpoints.up("lg")]: {
        // paddingTop: APP_BAR_DESKTOP + 24,
        //paddingTop: minimal?'26px':APP_BAR_MOBILE + 52,
        marginTop: '0',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
  }});
//  return Main;
//}

// Header Refs for dynamic alignment/position.
export const mainHeaderRef: MutableRefObject<HTMLElement | null> = {
  current: null,
};
export const subHeaderRef: MutableRefObject<HTMLElement | null> = {
  current: null,
};

const createStateDependentStyles = (
): CSSProperties => {
  const mainHeaderHeight = mainHeaderRef.current?.clientHeight ?? 0;
  const subHeaderHeight = subHeaderRef.current?.clientHeight ?? 0;
  const offset = subHeaderHeight > 0 ? 8 : 26;
  const totalHeaderHeight = mainHeaderHeight + subHeaderHeight + offset;
  const injectedNonIframe = isInjected();
  const overflow = injectedNonIframe ? undefined : "auto";
  const paddingTop = totalHeaderHeight;

  return {
    paddingTop: paddingTop,
    overflow: overflow,
  };
};

// ----------------------------------------------------------------------
type MainLayoutProps = {
  route: StateRoute,
  children?: ReactNode
}
export default function MainLayout(props: MainLayoutProps) {
  const mediaClasses = useMediaClasses();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const createStyle = useCallback(createStateDependentStyles, [
    mainHeaderRef.current?.clientHeight,
    subHeaderRef.current?.clientHeight,
  ]);
  const mainStyle = createStyle();

  return (
    <StyledRoot className={mediaClasses}>
      <Header />
      <Main style={mainStyle}>
        {props.children}
      </Main>
      <Footer />
    </StyledRoot>
  );
}
