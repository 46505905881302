import { useContext, useEffect, useState } from "react";
import { Box, Button, Typography } from '@mui/material'
import "./AppointmentConfirmation.css";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { AppContext, AppContextType } from "../../App";
import FlagIcon from '@mui/icons-material/Flag';
import GoogleIcon from '@mui/icons-material/Google';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import CustomMessagesService from "../../services/CustomMessagesService";
import { setSessionState, updateCurrentPage } from "../../features/session/sessionSlice";
import { NavigatePage, PageName } from "../../utils/Util";
import { useNavigationForcedAlert } from "../hooks/useApptConfirmNav";
import { Event , PageView} from '../../utils/GA';
import useStateNavigation from "../hooks/useStateNavigation";
import { stateRoutes } from "../../models/Page";
import DOMPurify from "dompurify";
import { updatePatient } from '../../features/patient/patientSlice';

function AppointmentConfirmation() {

    const state = useAppSelector(state => state)
    const startTime = state?.availability.availability.AppointmentDateTime;
    const endTime = new Date(state?.availability?.availability.AppointmentDateTime?.getTime() + 30*60000).toISOString().replace(/-/g, "").replace(/:\s*/g, "");
    const { formData, setFormData } = useContext(AppContext) as AppContextType;
    const ics = require('ics');
       const patientFirstName = state.patient.FirstName;
    const patientLastName = state.patient.LastName;
    const patientEmail = state.patient.Email;
    const homeNumber = state.patient.Home;
    const [customMessage, setCustomMessage] = useState("")
    const dispatch = useAppDispatch();
    const [locationName, setLocationName] = useState("");

    //Display an alert if the user has tried to click the back button and was forced back to this page
    useNavigationForcedAlert();

    let dtFormat = new Intl.DateTimeFormat('en-US',{
        day:'2-digit',
        month:'short',
        year:'numeric',
        hour:'numeric',
        minute:'numeric'
    })

    useEffect(()  => {
        dispatch(updateCurrentPage({CurrentPage :  PageName.appointmentConfirmation}))  
        var test = state
		const getCustomMessages = async () => {
			let customEndingMessage = "";
			if (state.provider && state.provider !== null) {
				const response = await CustomMessagesService.retrieveEndingMessage({
					...state.session,
					AcctKey: state?.providers.provider.AcctKey,
				});

				customEndingMessage = response.endingMessage ?? ''
                    setCustomMessage(customEndingMessage);
			}
			else
            setCustomMessage(customEndingMessage);
		};  
        getCustomMessages();      
      }, []);

      useEffect(() => {
        PageView(
            `pageview_appointment_confirmation_screen`, state.session.CoverKey?.toString() ,state.session.LocKey?.toString(), state.session.AcctKey?.toString()
        )
    },[])

    const Navigate = useStateNavigation();

    const googleLink = `https://calendar.google.com/calendar/u/0/r/eventedit?dates=${startTime}/${endTime}&location=${state?.appointmentData.locations[0].Address}&text=Appointment+at+${state?.appointmentData.locations[0].Name}&details=${state?.providers?.provider.Speciality} Appointment with${state?.providers?.provider.Title ? state?.providers?.provider.Title : ''} ${state?.providers?.provider.FirstName} ${state?.providers?.provider.LastName} at ${state?.appointmentData.locations[0].Name}`;

    const onScheduleAnotherFamily = () => {
          //GA-16
            Event(
                true,
                'click_schedule_family_member_appointment_confirmation_screen',
                state.session.CoverKey?.toString() ,state.session.LocKey?.toString(), state.session.AcctKey?.toString()
            )
           const newVal = { ...state.patient, Home: '', Email: ''}
           dispatch(updatePatient(newVal));

        const updateSession = {...state.session, LastPage : true}
          dispatch(setSessionState(updateSession))
        //   const url =  getProperURL('/', state.embed)
        //   Navigate(url)
          //Navigate(stateRoutes.landingPage)
          NavigatePage(dispatch, "next", state, Navigate, stateRoutes.landingPage);
          //const url =  getProperURL('/', state.embed)
          //Navigate(url)
        //Navigate('/')
    }

    
    const onGoogleCallClick = () => {
         //GA-17
         Event(
            true,
            'click_add_to_calendar_appointment_confirmation_screen',
            state.session.CoverKey?.toString() ,state.session.LocKey?.toString(), state.session.AcctKey?.toString()
        )
        window.open(googleLink);
    }
    const onAppleCalClick = () => {
        ics.createEvent(Event, (error:any, value:any) => {
            if (error) {
                console.error(error);
            }

            const element = document.createElement('a');
            element.setAttribute('href', 'data:text/calendar;charset=utf-8,' + encodeURIComponent(value));
            element.setAttribute('download', "event.ics");

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        })
    }
  
    const handleClickBack = () => {
        Navigate(-1)
    }

    useEffect(() => {
        if (state.availability.getInitialLocation) 
            setLocationName(state.availability.getInitialLocation.Name) 
        }, [state.availability.getInitialLocation]);

    return (
        <div>
            <div className='confirmation-appointment-page'>
                <div className='wsui-header-texts'>
                    <Typography variant="h6"
                        fontWeight={"500"}
                        sx={{
                            fontSize: "18px",
                            fontWeight: "600",
                            textAlign: "center"
                        }} >
                        Your appointment has been scheduled!
                    </Typography>
                    <p className='appointment-details-subheader'>Please check your email for a confirmation with appointment details.</p>
                </div>
                <div className='wsui-box wsui-details-box'>

                 <div className='bg-Black' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(customMessage)}} />
                    <div className='confirmation-appointment-label'>
                        <div className='wsui-mainPage wsui-inner-box wsui-details-container'>
                            <div className="wsui-confirmation-grid-header">
                                <div className="appointment-icon-container">
                                    <p className="wsui-icon appointment-icon"><FlagIcon/></p>
                                </div>
                                <p className='appointment-details-header wsui-header-texts'>Appointment Details</p>
                            </div>
                            <div className="wsui-confirmation-grid-flex">
                                <p className='confirmationetials wsui-details-heading'>Time</p>
                                <p className='confirmation-detail-value'>{dtFormat.format(startTime)} </p>
                                <p className='confirmationetials wsui-details-heading'><b>Location</b></p>
                                <p className='confirmation-detail-value'> {state.availability.getInitialLocation.Address ?? " "}</p>
                                {state.embed.ProviderAgnostic  === 0 ?
                                <p className='confirmationetials wsui-details-heading wsui-head-title confirmation-height'><b>Doctor</b></p>
                                 :  <p className='confirmationetials wsui-details-heading wsui-head-title confirmation-height'><b>Visit Details</b></p> }   
                                <div className='confirmation-height confirmation-detail-value'>
                                    {state.embed.ProviderAgnostic === 0 ? <p>Physician:  {`${state?.providers.provider.FirstName} ${state?.providers.provider.LastName}`}</p> : null}
                                    <p>Appointment Type: {`${state?.appointmentData.appointmentType.Name}`}</p>
                                </div>
                                <p className='confirmationetials wsui-head-title confirmation-height wsui-details-heading'><b>Patient</b></p>
                                <div className='confirmation-height confirmation-detail-value'>
                                    <p>Name: {state.patient.FirstName + " " + state.patient.LastName}</p>
                                    <p className="confirmation-detail-value-email">Email: {state.patient.Email ?? ''}</p>
                                    <p>Phone: {state.patient.Home ?? ''}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Box className="wsui-footer-data wsui-footer-details" >
                <div className='wsui-footer-calender'>
                    <div
                        id="button-calendar-appt-confirm"
                        className="wsui-calendar-button button-calendar-appt-confirm"
                        onClick={onAppleCalClick}
                    >
                        <CalendarMonthIcon />
                    </div>
                    <div className='wsui-footer-icon'>
                        <div
                            id="button-google-calendar-appt-confirm"
                            className="wsui-calendar-button button-google-calendar-appt-confirm"
                            onClick={onGoogleCallClick}
                        >
                            <GoogleIcon />
                        </div>
                        <div><p>Add to Calendar</p></div>
                    </div>
                </div>
                <Button
                    id="button-sched-family-appt-confirm"
                    className="bg-white button-sched-family-appt-confirm"
                    variant="contained"
                    type="submit"
                    sx={{ borderRadius: 10 }}
                    onClick={onScheduleAnotherFamily}
                >
                    <span>
                        <PermIdentityIcon />
                    </span>
                    <div className="wsui-button-box">
                        Schedule a family member
                    </div>
                </Button>
            </Box>
        </div>
    )
}

export default AppointmentConfirmation