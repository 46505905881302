import React, { FC } from 'react';
import TagManager  from 'react-gtm-module';

export const environment = {
    gtmLocal: 'XX-XXXXXXXXX-X',
    gtmProd: 'GTM-T5W8LW8N',
    gtmQA: 'XX-XXXXXXXXX-X',
    gaStage: 'XX-XXXXXXXXX-X',
    gtmDev: 'XX-XXXXXXXXX-X',
};

const getGTM = () => {
    const gtm = environment.gtmProd;


    return gtm;
};


let globaleExtInstance = '';


export const initGTM = (gtmInstance: string | any): void => {
  
    //External tracking optional
    if(gtmInstance !== '')
    {
        let gtmId = gtmInstance
        const tagManagerArgsExternal = {
            gtmId: gtmId
        }

    TagManager.initialize(tagManagerArgsExternal)
    }  
};