import { useState, useEffect } from "react";
import { Select, MenuItem, SelectChangeEvent } from "@mui/material";
import "./selectStyle.css";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setFontColor } from "../../features/embed/embedSlice";


export interface INormalSelectProps<T> {
  id?: string;
  data: ReadonlyArray<T>;
  getOptionLabel: (option: T) => string;
  renderOptions?: (option: T) => React.ReactNode;
  onSelection?: (data: T) => void;
  validateOptionSelected: (option: T, value: T) => boolean;
  selected?: T | null;
  size?: "small" | "medium";
  width?: string | number;
  placeholder?: string;
}

const ColorSelect = <T extends {}>({
  id,
  data,
  getOptionLabel,
  renderOptions,
  onSelection,
  validateOptionSelected,
  selected,
  size,
  width,
  placeholder,
}: INormalSelectProps<T>) => {
  // const [fillcolor,setFillColor]=useState("white")
  const handleSelection = (item: T | any) => {
    onSelection && onSelection(item);
  };
  const handleIndexSelection = (evt: SelectChangeEvent) => {
    const index = Number.parseInt(evt.target.value);
    const newValueUndef =
      Number.isNaN(index) || index < 0 ? undefined : data[index];
    const newValue = newValueUndef === undefined ? null : newValueUndef;

    handleSelection(newValue);
  };
  const state = useAppSelector((stat) => stat);
  const dispatch = useAppDispatch();

  if (JSON.stringify(selected) === "{}") {
    selected = null;
  }

  const renderSelectValue = (value: string): string => {
    const item: any = selected; //data[Number.parseInt(value)];
    const rendered =
      item === undefined || item === null
        ? placeholder ?? ""
        : getOptionLabel(item);
    return rendered;
  };
  const renderSelectOption = (value: T): React.ReactNode => {
    return renderOptions ? renderOptions(value) : getOptionLabel(value);
  };

  const getSelectedIndex = (): number => {
    let selectedIndex: number = -1;
    if (selected !== null && selected !== undefined) {
      const sel = selected;
      selectedIndex = data.findIndex((item) =>
        validateOptionSelected(item, sel)
      );
    }
    return selectedIndex;
  };
  const hexTorgb = (hex: any) => {
    var c: any;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");

      return (
        "rgb(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ")"
      );
    }
  };

  const myFunction = (rgb: any) => {
    const match = rgb.match(/\d+/g);
    const r = parseInt(match[0]);
    const g = parseInt(match[1]);
    const b = parseInt(match[2]);
    const rgbCodes = [r, g, b];

    const brightness = Math.round(
      (rgbCodes[0] * 299 + rgbCodes[1] * 587 + rgbCodes[2] * 114) / 1000
    );
    const textColour = brightness > 125 ? "black" : "white";
     dispatch(setFontColor(textColour));
  };
  const selectedIndex = getSelectedIndex().toString();
  var bgColor = state.embed.Stylings;
  useEffect(() => {
    if (bgColor) {
      myFunction(hexTorgb("#" + bgColor));
    }
  }, [bgColor]);

  return (
    <Select
      id={id}
      className={id}
      value={selectedIndex}
      onChange={handleIndexSelection}
      size={size}
      fullWidth={true}
      displayEmpty={false}
      renderValue={renderSelectValue}
      MenuProps={{
        PaperProps: {
          sx: {
            borderRadius: "0px 0px 25px 25px",
            boxShadow: "0 0 12px 0 rgb(0 0 0 / 25%)",
            backgroundColor: "var(--fpc-blue)",
            color: state.embed.fontColor,
            transitionDuration: "0s !important",
          },
        },
      }}
      sx={{
        //backgroundColor:'var(--fpc-blue)',
        backgroundColor: bgColor == null ? "var(--fpc-blue)" : `#${bgColor}`,
        color: state.embed.fontColor,
        maxWidth: { width },
        "&.MuiInputBase-root ": {
          borderRadius: "25px",
          boxShadow: "0 0 12px 0 rgba(0, 0, 0, 0.25)",
        },
        "&.MuiInputBase-root:has(div[aria-expanded='true'])": {
          borderBottomRightRadius: "0px",
          borderBottomLeftRadius: "0px",
          borderBottomColor: "transparent !important",
          boxShadow: "0 0 5px 0 rgb(0 0 0 / 25%)",
          borderCollapse: "true",
        },
        "& fieldset": {
          borderColor: "transparent !important",
        },
        "& svg": {
          fill: state.embed.fontColor,
        },
      }}
    >
      {data.map((item, index) => (
        <MenuItem key={index + Math.random()} value={index}>
          {renderSelectOption(item)}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ColorSelect;
