import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import Session from "./components/Session";
import UrlHasher from "./components/UrlHasher";
import InitGA from "./components/InitGA";
import ReactContainerSizeProvider from "./theme/reactContainerSizeProvider";
import { reactContainerName } from "./utils/Constants";
import { getAllSettings } from "./environments/Environments";
import { initParamsForIframe } from "./models/InjectedParams";

getAllSettings();
initParamsForIframe();
const container = document.getElementById(reactContainerName)!;
const root = createRoot(container);

root.render(
  <ReactContainerSizeProvider>
    <Provider store={store}>
      <UrlHasher>
        <Session>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Session>
      </UrlHasher>
    </Provider>
  </ReactContainerSizeProvider>
);

// If you want to start measuring performan e in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
