const windowParamName = "4pc-webscheduler";

export type InjectedParams = {
  coverkey?: string,
  lockey?: string,
  taskkey?: string,
  path?: string,
  stylings?: string,
  source?: string,
  mode?: string,
  scripturl?: string, //undefined in iframe mode
  scripturlbase?: string, //undefined in iframe mode
  injected?: boolean
}

export const initParamsForIframe = () => {
  const url = new URL(window.location.href);
  const newParams = new URLSearchParams();
  for (const [name, value] of url.searchParams) {
    newParams.append(name.toLowerCase(), value);
  }

  const getParam = (key: keyof InjectedParams): string | undefined => {
    return newParams.get(key) ?? undefined;
  }

  const injectedParam = getParam("injected");
  if(injectedParam && injectedParam.toLowerCase().trim() === "true" && !((window as any)[windowParamName])) {
    const obj: InjectedParams = {
      coverkey: getParam("coverkey"),
      lockey: getParam("lockey"),
      taskkey: getParam("taskkey"),
      path: getParam("path"),
      stylings: getParam("stylings"),
      source: getParam("source"),
      mode: getParam("mode"),
      scripturl: getParam("scripturl"),
      scripturlbase: getParam("scripturlbase"),
      injected: true
    };
    (window as any)[windowParamName] = obj;
  }
}

let cachedInjectedParams: InjectedParams | undefined = undefined;
export const getInjectedParams = (): InjectedParams | undefined => {
  if(cachedInjectedParams) {
    return cachedInjectedParams;
  }

  //convert all fields to lower case
  const raw: any = (window as any)[windowParamName];
  let injectedParams: InjectedParams | undefined = raw;
  if(raw) {
    let lowered: any = {};
    for(const key in raw) {
      lowered[key.toLowerCase()] = raw[key];
    }
    injectedParams = lowered;
  }
  cachedInjectedParams = injectedParams;
  return injectedParams;
}

//prefer query string, then injected param
export const getAppParam = (key: string, optionalUrl?: URL): string | undefined => {
  let paramVal: string | undefined = undefined;
  const url = optionalUrl ? optionalUrl : new URL(window.location.href);
  const newParams = new URLSearchParams();
  for (const [name, value] of url.searchParams) {
    newParams.append(name.toLowerCase(), value);
  }
  const lowerKey = key.toLowerCase();
  const queryVal = newParams.get(lowerKey);
  if(queryVal !== null) {
    paramVal = queryVal;
  } else {
    const injectedVal = ((getInjectedParams() as any) ?? {})[lowerKey];
    if(injectedVal !== undefined && injectedVal !== null) {
      paramVal = `${injectedVal}`;
    }
  }
  return paramVal;
}
