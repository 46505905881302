import LocationDetails from "./LocationDetails";

export interface AppointmentTypeData {
    taskKey: number | undefined | null,
    name: string,
    locKey: number | undefined | null,
    acctKey: number | undefined | null
}
export interface AppointmentDataState {
    appointmentType: AppointmentType;
    appointmentTypes: AppointmentType[];
    appointmentTypesDistinct: AppointmentType[];
    appointmentTypesLocation: LocationDetails[];
    locations: LocationDetails[]
}

export default interface AppointmentType {
    TaskKey: number | undefined | null,
    Name: string,
    LocKey: number | undefined | null,
    AcctKey: number | undefined | null
}
export interface AppointmentTypesDistinct {
    TaskKey: number;
    Name:string;
    LocKey: number;
    AcctKey:number;
  }
export interface AppointmentAction {
    data: AppointmentType[];
}

export function mapAppointmentTypeData(api: AppointmentTypeData): AppointmentType {
    return {
        TaskKey: api.taskKey,
        Name: api.name,
        LocKey: api.locKey,
        AcctKey: api.acctKey
    }
}