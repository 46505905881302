import React from "react";
import { styled, Theme } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setProvider } from "../../features/provider/newProviderSlice";
import Providers from "../../models/Provider";
interface ICustomButtonTileProps {
  disabled?: boolean;
}

const CustomButtonTile = styled(Button)<
  ICustomButtonTileProps & Omit<ButtonProps, keyof ICustomButtonTileProps>
>(({ theme, disabled, ...props }) => ({
  width: "100%",
  minWidth: 50,
  height: 73,
  justifyContent: "flex-start",
  borderColor: disabled ? `${grey[400]} !important` : undefined,
  padding: "0",
  borderRadius: "8px",
  borderWidth: disabled ? "2px !important" : undefined,
  color: disabled
    ? `${grey[700]} !important`
    : theme.palette.primary.contrastText,
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    borderRadius: "8px",
    borderWidth: "3px",
  },
}));

const NumberOfAppointments = ({
  disabled,
  numberOfAvailability,
}: {
  disabled: boolean;
  numberOfAvailability: number;
}): JSX.Element => {
  return (
    <>{`${
      disabled
        ? "0 "
        : numberOfAvailability === 0
        ? "No"
        : numberOfAvailability
    } Appts`}</>
  );
};

interface IAppointmentSelectionTileProps {
  id?: string;
  disabled?: boolean;
  appointmentDate: number
  onAppointmentClick: ( providerId: string, date:number) => void;
  providerId: string
  data: Providers 
}

const NewAppointmentSelectionTile = ({
  id,
  disabled,
  appointmentDate,
  onAppointmentClick,
  providerId,
  data
}: IAppointmentSelectionTileProps) => {
  const dispatch = useAppDispatch();
    const state = { embed: useAppSelector(st => st.embed), availability: useAppSelector(st => st.availability) };
    const getDayandDate =()=> {
      const d = new Date(appointmentDate).toDateString().split(' ')
      const day = d.splice(0,1)
      const date = d.slice(0,2).join(' ')
      return {day , date}
    }
    if (state.embed.ProviderAgnostic === 1)
      providerId = 'FIRST_AVAILABLE'
    
    const dt = getDayandDate()
    let numberOfAvailability = 0
    try
    {
     // var t = state.availability?.availabilities[appointmentDate][providerId]
      if(typeof state.availability?.availabilities[appointmentDate] !== 'undefined') 
      {
          if(typeof state.availability?.availabilities[appointmentDate][providerId] !== 'undefined') 
             numberOfAvailability = state.availability?.availabilities[appointmentDate][providerId]?.length
      }
    }
    catch(ex)
    {
      numberOfAvailability = 0
    }
    disabled = numberOfAvailability== 0 ? true: false
  return (
    <CustomButtonTile
      id={id}
      className="button-apptdate"
      variant={
        disabled || false ? "outlined" : undefined
      }
      disabled={disabled}
      sx={{
        backgroundColor : !disabled ? "var(--fpc-blue)" : undefined,
        color : state.embed.fontColor,        
        textTransform: "none",
        boxShadow: disabled || false ? 0 : 3,
      }}
      onClick={() => {
        onAppointmentClick(providerId, appointmentDate);
        dispatch(setProvider(data))
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        padding={"0 0 0 5px"}
      >
        <Typography flexGrow={1} textTransform={"capitalize"} fontSize={"12px"} lineHeight={"16px"}>
          { dt.day }
        </Typography>
        <Typography flexGrow={1} fontSize={"12px"} lineHeight={"16px"}>{dt.date}</Typography>
        <Typography flexGrow={1} alignContent={"flex-end"} fontSize={"12px"} lineHeight={"16px"}>
          <NumberOfAppointments
            disabled={disabled ?? false}
            numberOfAvailability={numberOfAvailability}            
          />
        </Typography>
      </Box>
    </CustomButtonTile>
  );
};

export default NewAppointmentSelectionTile;
